"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromPresence = void 0;
const reducer_1 = require("../reducer");
const unsubscribeFromPresence = () => (dispatch, _, { firestoreSubscriptions }) => {
    if (firestoreSubscriptions.presenceListener) {
        firestoreSubscriptions.presenceListener();
    }
    dispatch((0, reducer_1.unsubscribeFromPresence)());
};
exports.unsubscribeFromPresence = unsubscribeFromPresence;
