"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInvitationDate = void 0;
const createInvitationDate = (date) => {
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};
exports.createInvitationDate = createInvitationDate;
