"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomStatus = void 0;
var RoomStatus;
(function (RoomStatus) {
    RoomStatus["SUBSCRIBING"] = "SUBSCRIBING";
    RoomStatus["SUBSCRIBED"] = "SUBSCRIBED";
    RoomStatus["UNSUBSCRIBED"] = "UNSUBSCRIBED";
    RoomStatus["HAS_ERROR"] = "HAS_ERROR";
})(RoomStatus = exports.RoomStatus || (exports.RoomStatus = {}));
