import React, { FC } from 'react';
import {
  AppStore,
  ChatRoomStatus,
  ProjectsReducer,
  ProjectsStatus,
  ProjectTab,
  selectors,
  UserRole,
} from '@xtrf/shared';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Message from '../../../../shared/message/message';

import { default as Chatroom } from './components/chatroom/chatroom';
import styles from './joboffers-tab.module.scss';
import { ProjectGroup } from './components/project-group/project-group';

type OwnProps = {
  projects: ProjectsReducer | null;
  activeTab: ProjectTab;
};

export type JobOffersTabProps = OwnProps;

const JobOffersTab: FC<JobOffersTabProps> = ({ projects, activeTab }) => {
  const { t } = useTranslation('pages');
  const chats = useSelector(selectors.getJobOfferChats);
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);
  const app = selectedWorkspace?.apps?.find(app => app.id === 'xtrf-joboffers');

  if (!app || !selectedWorkspace) return null;

  const showCorrectEmptyStateMessage = () => {
    if (selectedWorkspace?.userRole === UserRole.Manager) {
      if (activeTab === ProjectTab.Ongoing) {
        return 'tabToggle.joboffersTabOngoingManagerEmptyState';
      } else {
        return 'tabToggle.joboffersTabAllManagerEmptyState';
      }
    } else {
      if (activeTab === ProjectTab.Ongoing) {
        return 'tabToggle.joboffersTabOngoingVendorEmptyState';
      } else {
        return 'tabToggle.joboffersTabAllVendorEmptyState';
      }
    }
  };

  if (projects?.status === ProjectsStatus.HAS_ERROR) {
    return (
      <Message
        title={t('joboffers.errorWhileFetching')}
        testId="fetching-error-message"
        className={styles.error}
        type="error"
      />
    );
  }

  if (isEmpty(chats)) {
    return (
      <div className={styles.placeholder}>
        <p className={styles.emptyStateText}>{t(showCorrectEmptyStateMessage())}</p>
      </div>
    );
  }

  return (
    <div>
      {Object.keys(chats)
        .filter(c => c !== ChatRoomStatus.Inactive)
        .map(chatroomKey => (
          <div key={chatroomKey}>
            {selectedWorkspace.userRole === UserRole.Manager ? (
              <ProjectGroup
                key={chatroomKey}
                chatroomKey={chatroomKey}
                app={app}
                projectIdentifier={chats[chatroomKey][0].projectDisplayIdentifier}
                projectName={chats[chatroomKey][0].projectName}
              />
            ) : activeTab === ProjectTab.All ? (
              <ProjectGroup
                key={chatroomKey}
                chatroomKey={chatroomKey}
                app={app}
                projectIdentifier="Open"
              />
            ) : (
              chats[chatroomKey].map(chat => <Chatroom key={chat.id} app={app} chatRoom={chat} />)
            )}
          </div>
        ))}
      {activeTab === ProjectTab.All &&
        Object.keys(chats)
          .filter(c => c === ChatRoomStatus.Inactive)
          .map(chatroomKey => (
            <ProjectGroup
              key={chatroomKey}
              chatroomKey={chatroomKey}
              app={app}
              projectIdentifier="Closed"
            />
          ))}
    </div>
  );
};

export default JobOffersTab;
