"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOutReadMessages = void 0;
// Unread message received for one of the open chats should not increase unread messages' counter,
// and should be immediately mark as read.
const filterOutReadMessages = (unreadMessagesPerRoom, messages, activeChatRooms, messagesToMark = {}) => {
    const toMarkAsRead = {};
    const newUnread = Object.keys(unreadMessagesPerRoom).reduce((accumulator, chatRoomId) => {
        var _a, _b, _c;
        if (!activeChatRooms.includes(chatRoomId) &&
            (!messagesToMark[chatRoomId] || !messagesToMark[chatRoomId].length)) {
            accumulator[chatRoomId] = unreadMessagesPerRoom[chatRoomId];
            return accumulator;
        }
        const unread = unreadMessagesPerRoom[chatRoomId];
        const messagesForChat = (_b = (_a = messages[chatRoomId]) === null || _a === void 0 ? void 0 : _a.map(message => message.id)) !== null && _b !== void 0 ? _b : [];
        const messagesToMarkPerRoom = (_c = messagesToMark[chatRoomId]) !== null && _c !== void 0 ? _c : [];
        const withoutOpen = [];
        unread.forEach(messageId => {
            var _a;
            if (messagesToMarkPerRoom.includes(messageId)) {
                toMarkAsRead[chatRoomId] = [...((_a = toMarkAsRead[chatRoomId]) !== null && _a !== void 0 ? _a : []), messageId];
            }
            else if (!messagesForChat.includes(messageId)) {
                withoutOpen.push(messageId);
            }
        });
        accumulator[chatRoomId] = withoutOpen;
        return accumulator;
    }, {});
    return {
        unreadMessagesPerRoom: newUnread,
        toMarkAsRead,
    };
};
exports.filterOutReadMessages = filterOutReadMessages;
