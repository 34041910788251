import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './join-workspace.module.scss';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'providers/user.provider';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { HistoryState } from 'models/history-state.model';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, helpers, joinToWorkspaceActions } from '@xtrf/shared';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';
import { AppDispatch } from '../../app.component';
import Message from 'components/shared/message/message';

type Invitation = {
  workspace: string;
  workspaceId: string;
  roleInWorkspace: string;
  userExists: boolean;
  email?: string;
};

export const JoinWorkspace: React.FC = () => {
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { errorCode, joinToWorkspaceStatus } = useSelector(
    (store: AppStore) => store.joinToWorkspace
  );
  const user = useContext(UserContext);
  const dispatch: AppDispatch = useDispatch();
  const queryParamToken = new URLSearchParams(window.location.search).get('token');
  const { t } = useTranslation('pages');

  const history = useHistory<Partial<HistoryState>>();

  const joinWorkspace = async () => {
    if (!user || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const token = history.location.state?.token || queryParamToken;
      if (!token) {
        throw new Error(t('home.workspace.errorInfo'));
      }
      const res = await dispatch(joinToWorkspaceActions.handleInvitation(token));
      unwrapResult(res);
      history.push('/home', {});
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function loadInvitation(token: string) {
      setIsLoading(true);
      try {
        const resultAction = await dispatch(joinToWorkspaceActions.getInvitation(token));
        const invitation = unwrapResult(resultAction);
        setIsLoading(false);

        if (user) {
          setInvitation(invitation);
        } else if (invitation) {
          const { userExists } = invitation;

          const redirectUrl = userExists ? '/login' : 'register';
          history.push(redirectUrl, { ...invitation, token });
        }
      } catch (e) {
        setIsLoading(false);
      }
    }

    const locationStateToken =
      history.location.state && history.location.state.token
        ? history.location.state.token
        : undefined;

    const joinWorkspaceToken = queryParamToken || locationStateToken;

    if (!joinWorkspaceToken) {
      return;
    } else {
      loadInvitation(joinWorkspaceToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <Message
        hidden={!errorCode}
        type="error"
        title={t('home.workspace.errorInfo')}
        content={t(helpers.createInvitationErrorLabel(errorCode, joinToWorkspaceStatus))}
      />
      {isLoading && !invitation && (
        <div className={styles.loaderContainer}>
          <LoaderDotted />
        </div>
      )}
      <div>
        {invitation && !errorCode && (
          <>
            <p>{t('home.workspace.joinQuestion')}</p>
            <p className={styles.workspaceName}>{invitation.workspace}</p>
            <p className={styles.workspaceId}>Workspace ID: {invitation.workspaceId}</p>
            <p className={styles.roleInWorkspace}>Your role: {invitation.roleInWorkspace}</p>
            <Button
              size="big"
              className={styles.submitButton}
              onClick={joinWorkspace}
              loading={isLoading}
            >
              {t('home.workspace.joinWorkspace')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
