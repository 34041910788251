"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserStatusManually = void 0;
const setUserStatusManually = ({ database, userId, state }) => userId &&
    database()
        .ref(`/status/${userId}`)
        .set({
        state,
        lastChanged: database.ServerValue.TIMESTAMP,
    });
exports.setUserStatusManually = setUserStatusManually;
