import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore, workspacesActions, WorkspacesStatus } from '@xtrf/shared';

import { AdminPanel } from './admin-panel.page';

export const AdminPanelContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workspacesActions.getWorkspaces());
  }, [dispatch]);

  const workspaces = useSelector((state: AppStore) => state.workspaces.data);
  const workspacesStatus = useSelector((state: AppStore) => state.workspaces.status);

  return (
    <AdminPanel
      workspaces={workspaces}
      isLoading={workspacesStatus === WorkspacesStatus.FETCHING}
    />
  );
};
