"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VALIDATION_ERROR_LABELS = exports.DEFAULT_AVATAR_NAME = exports.DEFAULT_FILE_NAME = exports.MAX_FILE_SIZE = exports.EXCLUDED_EXTENSIONS = exports.FILE_CHARS_NAME_LIMIT = void 0;
exports.FILE_CHARS_NAME_LIMIT = 25;
exports.EXCLUDED_EXTENSIONS = ['exe', 'bin'];
// 1 GB in bytes
exports.MAX_FILE_SIZE = 1073741824;
exports.DEFAULT_FILE_NAME = 'attachment';
exports.DEFAULT_AVATAR_NAME = 'avatar';
exports.VALIDATION_ERROR_LABELS = {
    MAX_FILE_SIZE: 'common:validationErrors.maxSize',
    WRONG_EXTENSION: 'common:validationErrors.wrongExtension',
    CONNECTION_ERROR: 'common:validationErrors.connectionError',
    TIME_TO_DELETE_EXCEEDED: 'common:validationErrors.timeToDeleteExceeded',
    UNKNOWN_ERROR_WHEN_UPDATING_MESSAGE: 'common:validationErrors.unknownUpdatingError',
};
