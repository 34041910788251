import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styles from './home.module.scss';
import { WorkspaceSelectorContainer } from 'components/workspaces/workspace-selector/workspace-selector.container';
import { WorkspaceRoomsContainer } from 'components/rooms/workspace-rooms/workspace-rooms.container';
import ChatList from 'components/chat/chat-window-list/chat-window-list.container';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import { LeaveWorkspaceConfirm } from 'components/shared/leave-workspace-confirm/leave-workspace-confirm';
import LeaveWorkspaceConfirmProvider from 'providers/leave-workspace-confirm.provider';
import { isGlobalChat } from '../../shared';
import { useSelector } from 'react-redux';
import { AppStore, UserRole } from '@xtrf/shared';
import classNames from 'classnames';

const HomePage: React.FC = () => {
  const { location, push } = useHistory();
  const workspaces = useSelector((store: AppStore) => store.workspaces.data);
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);
  const [animation, setAnimation] = useState('');

  const hasSelectedWorkspaceAvailableJobOffers =
    selectedWorkspace &&
    selectedWorkspace.apps &&
    selectedWorkspace.apps.some(app => app.name === 'Job Offers');

  useEffect(() => {
    if (workspaces.length && selectedWorkspace === null) {
      setAnimation('slideIn');
    }
    if (workspaces.length && selectedWorkspace !== null) {
      setAnimation('slideOutIn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const redirect = sessionStorage.getItem('redirect');
    const workspaceId = sessionStorage.getItem('workspaceId');

    if (!redirect && !workspaceId) return;

    sessionStorage.removeItem('redirect');
    sessionStorage.removeItem('workspaceId');
    push(`/authorize?workspaceId=${workspaceId}&redirect=${redirect}`);
  }, [push]);

  return (
    <PagesContainer>
      <LeaveWorkspaceConfirmProvider>
        <div className={styles.mainContentColumn}>
          <WorkspaceSelectorContainer />
          <ChatList />
          {!isGlobalChat() && <LeaveWorkspaceConfirm />}
        </div>
        <div
          className={classNames(
            styles.sideBarColumn,
            animation === 'slideIn' && styles.slideIn,
            animation === 'slideOutIn' && styles.slideOutIn
          )}
          onAnimationEnd={() => setAnimation('')}
        >
          <WorkspaceRoomsContainer />
        </div>
      </LeaveWorkspaceConfirmProvider>

      {process.env.REACT_APP_DEMO_MODE === 'true' &&
        hasSelectedWorkspaceAvailableJobOffers &&
        selectedWorkspace?.userRole === UserRole.Manager && (
          <a
            className={styles.linkButton}
            rel="noopener noreferrer"
            target="_blank"
            href={`${process.env.REACT_APP_JOB_OFFERS_URL}/${selectedWorkspace.id}`}
          >
            <i className="external alternate icon"></i> Open job offers
          </a>
        )}
    </PagesContainer>
  );
};

export default HomePage;
