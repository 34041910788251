import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import styles from './workspace-settings.module.scss';
import { AppStore, helpers, LINKS, userActions, workspacesActions } from '@xtrf/shared';
import { LeaveWorkspaceConfirmContext } from '../../../providers/leave-workspace-confirm.provider';
import ToggleTab from '../../shared/toggle-tab/toggle-tab.component';
import classNames from 'classnames';
import { WorkspaceSettingsConfig } from '@xtrf/shared/lib/store/reducers/workspaces/types';
import AccountSettingsPanel from './panels/account-settings-panel/account-settings-panel';
import AppsSettingsPanel from './panels/apps-settings-panel/apps-settings-panel';
import WorkspaceSettingsPanel from './panels/workspace-settings-panel/workspace-settings-panel';
import { useDebounce } from 'use-debounce';

export enum WorkspaceSettingsType {
  Account = 'account',
  Workspace = 'workspace',
  Apps = 'apps',
}

type OwnProps = {
  sections: WorkspaceSettingsType[];
};
export type WorkspaceSettingsProps = OwnProps;

const WorkspaceSettings: React.FC<WorkspaceSettingsProps> = ({ sections }) => {
  const unreadMessages = useSelector((store: AppStore) => store.workspaces.unreadMessages);
  const { workspaceSettingsConfigSchema } = useSelector((store: AppStore) => store.workspaces);
  const { data: user } = useSelector((store: AppStore) => store.user);
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);
  const email = user?.email;
  const hasActiveNotifications = unreadMessages?.hasActiveNotifications;
  const { setConfirmLeaveWorkspaceVisible, setWorkspaceRole } = useContext(
    LeaveWorkspaceConfirmContext
  );
  const { t } = useTranslation(['pages', 'common']);

  const dispatch = useDispatch();
  const [workspaceSettingsVisible, setWorkspaceSettingsVisible] = useState(true);
  const [accountSettingsVisible, setAccountSettingsVisible] = useState(true);
  const [appsSettingsVisible, setAppsSettingsVisible] = useState(true);

  const [validationSchema, updateValidationSchema] = useState(
    helpers.getValidationSchemaForSettingsForm({ translate: t })
  );

  const [initialValues, setInitialValues] = useState(() => {
    if (
      sections.includes(WorkspaceSettingsType.Apps) &&
      workspaceSettingsConfigSchema &&
      workspaceSettingsConfigSchema.schema.properties
    ) {
      const apps = workspaceSettingsConfigSchema.schema.properties.apps;
      const config = workspaceSettingsConfigSchema.config;

      const additionalInitialValues = Object.keys(apps.properties).reduce((attrs, key) => {
        const defaultValues = Object.keys(apps.properties[key].properties).reduce(
          (attrs1, key1) => {
            const value =
              config[key] && config[key][key1] !== undefined
                ? config[key][key1]
                : apps.properties[key].properties[key1].default;

            return { ...attrs1, [key1]: value };
          },
          {}
        );

        return { ...attrs, [key]: defaultValues };
      }, {});

      return {
        ...{
          username: user?.username || `${user?.firstName} ${user?.lastName}`,
          newsletter: user?.newsletterActive || false,
          notifications: hasActiveNotifications || false,
        },
        ...{ apps: additionalInitialValues },
      };
    }

    return {
      username: user?.username || `${user?.firstName} ${user?.lastName}`,
      newsletter: user?.newsletterActive || false,
      notifications: hasActiveNotifications || false,
      apps: {},
    };
  });

  const [newestValues] = useDebounce(initialValues, 500);

  useEffect(() => {
    if (email) {
      dispatch(userActions.getUserDetails(email));
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (
      sections.includes(WorkspaceSettingsType.Apps) &&
      workspaceSettingsConfigSchema &&
      workspaceSettingsConfigSchema?.schema.properties
    ) {
      const apps = workspaceSettingsConfigSchema.schema.properties.apps;
      const config = workspaceSettingsConfigSchema.config;

      const additionalInitialValues = Object.keys(apps.properties).reduce((attrs, key) => {
        const defaultValues = Object.keys(apps.properties[key].properties).reduce(
          (attrs1, key1) => {
            const value =
              config[key] && config[key][key1] !== undefined
                ? config[key][key1]
                : apps.properties[key].properties[key1].default;

            return { ...attrs1, [key1]: value };
          },
          {}
        );

        return { ...attrs, [key]: defaultValues };
      }, {});

      setInitialValues({
        ...{
          username: user?.username || `${user?.firstName} ${user?.lastName}`,
          newsletter: user?.newsletterActive || false,
          notifications: hasActiveNotifications || false,
        },
        ...{ apps: additionalInitialValues },
      });
      updateValidationSchema(
        helpers.getValidationSchemaForSettingsForm({ translate: t, appsProperties: apps })
      );
    }
  }, [sections, workspaceSettingsConfigSchema, hasActiveNotifications, t, user]);

  const handleTermsClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(LINKS.TERMS);
  };

  const handleSignOut = () => {
    dispatch(userActions.logOutUser());
  };

  const handleRemovePhoto = () => {
    dispatch(userActions.removeUserAvatar());
  };

  const handleSubmit = async (values: {
    username: string;
    notifications: boolean;
    newsletter: boolean;
    apps: {
      [appName: string]: {
        [appConfigField: string]: string | boolean | number[];
      };
    };
  }) => {
    const changesToSave = [];
    if (
      sections.includes(WorkspaceSettingsType.Apps) &&
      selectedWorkspace &&
      !isEmpty(values.apps)
    ) {
      const { username, notifications, newsletter, ...rest } = values;
      changesToSave.push(
        await dispatch(
          workspacesActions.setWorkspaceSettingsConfigSchema(
            {
              workspaceId: selectedWorkspace.id,
              config: rest.apps,
            } as WorkspaceSettingsConfig,
            selectedWorkspace
          )
        )
      );
    }
    if (values.newsletter !== user?.newsletterActive) {
      changesToSave.push(
        await dispatch(userActions.updateUserData({ newsletterActive: values.newsletter }))
      );
    }
    if (user?.username !== values.username && user?.userId && user.email) {
      changesToSave.push(
        await dispatch(
          userActions.updateUsername({
            userId: user.userId,
            email: user.email,
            username: values.username.trim(),
          })
        )
      );
    }
    changesToSave.push(
      await dispatch(workspacesActions.toggleNotificationsPermission(values.notifications))
    );

    return Promise.all(changesToSave);
  };

  const accountSettingsTabAvailable = sections.includes(WorkspaceSettingsType.Account);
  const appsSettingsTabAvailable =
    sections.includes(WorkspaceSettingsType.Apps) &&
    workspaceSettingsConfigSchema?.schema.properties?.apps &&
    !isEmpty(workspaceSettingsConfigSchema?.schema.properties.apps.properties);

  const toggleSettingsTabContent = [
    {
      active: workspaceSettingsVisible,
      available: true,
      onClick: () => setWorkspaceSettingsVisible(prev => !prev),
      title: t('settings.workspacePreferences'),
      content: <WorkspaceSettingsPanel />,
      testId: 'workspace-settings-panel',
    },
    {
      active: appsSettingsVisible,
      available: appsSettingsTabAvailable,
      onClick: () => setAppsSettingsVisible(prev => !prev),
      title: t('settings.appsPreferences'),
      content: <AppsSettingsPanel />,
      testId: 'apps-settings-panel',
    },
    {
      active: accountSettingsVisible,
      available: accountSettingsTabAvailable,
      onClick: () => setAccountSettingsVisible(prev => !prev),
      title: t('settings.title'),
      content: (
        <AccountSettingsPanel
          onRemovePhotoClick={() => handleRemovePhoto()}
          onTermsClick={event => handleTermsClick(event)}
        />
      ),
      testId: 'account-settings-panel',
    },
  ];

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={newestValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ errors, values, handleSubmit, isSubmitting }) => {
          const disabled = isEqual(initialValues, values) || !isEmpty(errors);

          return (
            <>
              <Form className={styles.formContainer}>
                {toggleSettingsTabContent.map(
                  (tab, index) =>
                    !!tab.available && (
                      <ToggleTab
                        active={tab.active}
                        onClick={tab.onClick}
                        title={tab.title}
                        key={index}
                        testId={tab.testId}
                      >
                        {tab.content}
                      </ToggleTab>
                    )
                )}
              </Form>
              <div className={classNames(styles.wrapper, styles.flex)}>
                <Button
                  onClick={() => handleSubmit()}
                  type="submit"
                  loading={isSubmitting}
                  className={disabled ? styles.disabledButton : styles.blueButton}
                  disabled={disabled}
                  data-testid="submit-button"
                >
                  {t('buttons.saveChanges')}
                </Button>
                <Button
                  onClick={() => {
                    setConfirmLeaveWorkspaceVisible(true);
                    selectedWorkspace && setWorkspaceRole(selectedWorkspace.userRole);
                  }}
                  className={styles.button}
                  data-testid="leave-workspace-button"
                >
                  {t('buttons.leaveWorkspace')}
                </Button>
                {accountSettingsTabAvailable && (
                  <Button
                    name="signOut"
                    className={styles.button}
                    disabled={false}
                    onClick={() => handleSignOut()}
                    data-testid="signout-button"
                  >
                    {t('buttons.signOut')}
                  </Button>
                )}
                <p className={styles.infoAboutVersion} data-testid="version-info">
                  {t('common:applicationVersion')} {process.env.REACT_APP_VERSION}
                </p>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default WorkspaceSettings;
