"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteMessage = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const enums_1 = require("../../../enums");
const logEvent_1 = require("../../../../helpers/logEvent");
const deleteMessage = ({ chatRoomId, messageId, message, }) => (dispatch, getState, { auth, firestore, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.deleteMessageStarted)({ id: messageId }));
    try {
        const { projects: { workspaceId }, user: { data }, } = getState();
        const user = auth().currentUser;
        if (!user || !user.email || !workspaceId || !(data === null || data === void 0 ? void 0 : data.userId)) {
            throw new Error('user-is-not-logged');
        }
        if ('appId' in message || data.userId !== message.authorId) {
            throw new Error('Cannot remove message which has different author');
        }
        const ref = firestore()
            .collection(firebaseCollectionNames_1.CHAT_ROOMS_COLLECTION_ID)
            .doc(chatRoomId)
            .collection(firebaseCollectionNames_1.MESSAGES_COLLECTION_ID)
            .doc(messageId);
        yield ref.update({
            updatedAt: firestore.FieldValue.serverTimestamp(),
            type: enums_1.MessageType.Removed,
            content: '',
        });
        (0, logEvent_1.logEvent)(analytics, 'delete_message', { chatRoomId, workspaceId, authorId: data.userId });
        dispatch((0, reducer_1.deleteMessageSuccess)());
    }
    catch (e) {
        dispatch((0, reducer_1.deleteMessageFailed)());
    }
});
exports.deleteMessage = deleteMessage;
