import React from 'react';
import { Modal, Transition } from 'semantic-ui-react';
import styles from './transitioned-modal.module.scss';

export interface ResendInvitationModalProps {
  visible: boolean;
  toggleModal: () => void;
  testId: string;
  width?: number;
}

const TransitionedModal: React.FC<ResendInvitationModalProps> = ({
  visible,
  toggleModal,
  testId,
  children,
  width,
}) => {
  return (
    <Transition visible={visible} unmountOnHide animation="fade" duration={300}>
      <Modal
        open
        onClose={e => {
          document.body.classList.remove('modal-fade-in');
          toggleModal();
          e.stopPropagation();
        }}
        onMount={() => document.body.classList.add('modal-fade-in')}
        style={{ width: width ? width : 350 }}
        className={styles.modal}
        dimmer="inverted"
        data-testid={testId}
      >
        {children}
      </Modal>
    </Transition>
  );
};

export default TransitionedModal;
