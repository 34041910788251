import { User, WorkspaceUser } from '@xtrf/shared';

export type MembersProps = {
  workspaceUsers: WorkspaceUser[];
  isLoading: boolean;
  user: User | null;
  sortValue: MembersListSortValues | undefined;
  setSortValue: (sortValue: MembersListSortValues) => void;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
};

export enum UserNameSort {
  ASC = 'username',
  DESC = '-username',
}

export enum UserEmailSort {
  ASC = 'email',
  DESC = '-email',
}

export enum RoleSort {
  ASC = 'role',
  DESC = '-role',
}

export enum JoinedAtSort {
  ASC = 'joinedAt',
  DESC = '-joinedAt',
}

export enum ActiveSort {
  ASC = 'active',
  DESC = '-active',
}

export type MembersListSortValues =
  | UserNameSort
  | UserEmailSort
  | RoleSort
  | ActiveSort
  | JoinedAtSort;
