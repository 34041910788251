/* eslint-disable */
import React from 'react';
import { throttle } from 'lodash';
import { canUseDOM } from 'exenv';
import Portal from './Portal';

const listeners = {};

function fireListeners() {
  // @ts-ignore
  Object.keys(listeners).forEach(key => listeners[key]());
}

function getPageOffset() {
  return {
    x:
      window.pageXOffset !== undefined
        ? window.pageXOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollLeft,
    y:
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop,
  };
}

function initDOMListener() {
  document.body.addEventListener(
    'wheel',
    throttle(fireListeners, 100, {
      leading: true,
      trailing: true,
    })
  );
  window.addEventListener(
    'resize',
    throttle(fireListeners, 50, {
      leading: true,
      trailing: true,
    })
  );
}

if (canUseDOM) {
  if (document.body) {
    initDOMListener();
  } else {
    document.addEventListener('DOMContentLoaded', initDOMListener);
  }
}

let listenerIdCounter = 0;
function subscribe(fn: any) {
  listenerIdCounter += 1;
  const id = listenerIdCounter;
  // @ts-ignore
  listeners[id] = fn;
  // @ts-ignore
  return () => delete listeners[id];
}

type RelativePortalProps = {
  right?: number;
  left?: number;
  fullWidth?: boolean;
  top: number;
  children: any;
  onOutClick?: (e: any) => void;
  component?: string;
  zIndex?: number;
  isGlobalChat?: boolean;
};

type RelativePortalState = {
  right: number;
  left: number;
  top: number;
};

export default class RelativePortal extends React.Component<
  RelativePortalProps,
  RelativePortalState
> {
  handleScroll!: () => void;
  unsubscribe!: () => void;
  element!: null | Element;

  static defaultProps = {
    left: 0,
    top: 0,
    component: 'span',
    zIndex: 5,
  };

  state = {
    right: 0,
    left: 0,
    top: 0,
  };

  componentDidMount() {
    this.handleScroll = () => {
      if (this.element) {
        const rect = this.element.getBoundingClientRect();
        const pageOffset = getPageOffset();
        const top = pageOffset.y + rect.top;
        const right = document.documentElement.clientWidth - rect.right - pageOffset.x;
        const left = pageOffset.x + rect.left;

        if (top !== this.state.top || left !== this.state.left || right !== this.state.right) {
          this.setState({ left, top, right });
        }
      }
    };
    this.unsubscribe = subscribe(this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { component: Comp, top, left = 0, right, fullWidth, zIndex, ...props } = this.props;

    const fromLeftOrRight =
      right !== undefined ? { right: this.state.right + right } : { left: this.state.left + left };

    const horizontalPosition =
      fullWidth && right
        ? // @ts-ignore
          { right: this.state.right + right, left: this.state.left + left }
        : fromLeftOrRight;

    return (
      // @ts-ignore
      <Comp
        ref={(element: Element | null) => {
          this.element = element;
        }}
      >
        <Portal {...props}>
          <div
            style={{
              position: 'absolute',
              top: this.state.top + top,
              zIndex,
              ...horizontalPosition,
            }}
          >
            {this.props.children}
          </div>
        </Portal>
      </Comp>
    );
  }
}
/* eslint-enable */
