"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logOutUser = void 0;
const unsubscribeFromUnreadMessagesPerWorkspace_1 = require("./../../workspaces/actions/unsubscribeFromUnreadMessagesPerWorkspace");
const setUserStatusManually_1 = require("./../../../userPresence/setUserStatusManually");
const reducer_1 = require("../reducer");
const actions_1 = require("../../projects/actions");
const enums_1 = require("../../../enums");
const unsubscribeFromUserDetails_1 = require("./unsubscribeFromUserDetails");
const types_1 = require("../types");
const reducer_2 = require("../../chatList/reducer");
const logOutUser = () => (dispatch, getState, { auth, database }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { user } = getState();
        if (user.status === types_1.UserStatus.LOGGING_OUT) {
            return;
        }
        dispatch((0, reducer_1.logOutStarted)());
        dispatch((0, reducer_2.emptyChatList)());
        if (user === null || user === void 0 ? void 0 : user.data) {
            const { uid } = user.data;
            if (uid) {
                yield (0, setUserStatusManually_1.setUserStatusManually)({
                    database,
                    userId: user.data.userId,
                    state: enums_1.UserPresenceStatus.Offline,
                });
            }
        }
        yield auth().signOut();
        dispatch((0, actions_1.unsubscribeFromProjects)());
        dispatch((0, unsubscribeFromUnreadMessagesPerWorkspace_1.unsubscribeFromUnreadMessagesPerWorkspace)());
        dispatch((0, unsubscribeFromUserDetails_1.unsubscribeFromUserDetails)());
        dispatch((0, reducer_1.logOutSuccess)());
    }
    catch (e) {
        const { code } = e;
        dispatch((0, reducer_1.logOutFailed)(code));
    }
});
exports.logOutUser = logOutUser;
