import React, { FC, SVGProps } from 'react';

const DeleteIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      fill="#6e6564"
      {...props}
    >
      <path d="M9.91 10.09a.34.34 0 00-.24-.09H9c-.18-.01-.32.13-.33.31v6.02c-.01.18.13.32.31.33H9.67c.18.01.32-.13.33-.31v-6.02a.34.34 0 00-.09-.24zm2.67 0a.338.338 0 00-.25-.09h-.66c-.18-.01-.32.13-.33.31v6.02c-.01.18.13.32.31.33H12.34c.18.01.32-.13.33-.31v-6.02a.31.31 0 00-.1-.24h.01zm2.67 0A.338.338 0 0015 10h-.67c-.18-.01-.32.13-.33.31v6.02c-.01.18.13.32.31.33H15c.18.01.32-.13.33-.31v-6.02a.34.34 0 00-.09-.24h.01zm1.43 7.78c0 .14-.02.29-.07.42-.03.1-.08.2-.15.28-.03.04-.06.07-.11.09H7.67a.244.244 0 01-.11-.09.76.76 0 01-.15-.28c-.05-.13-.07-.28-.07-.42V8h9.33v9.87h.01zm-6.5-12.42c.04-.06.11-.1.18-.11h3.3c.07.01.14.05.18.11l.5 1.22H9.67l.51-1.22zM19.33 7c.01-.18-.13-.32-.31-.33h-3.24l-.73-1.74c-.11-.27-.31-.5-.56-.66-.24-.17-.53-.26-.82-.27h-3.34c-.29.01-.58.1-.82.27-.25.16-.45.39-.56.66l-.73 1.74H5c-.18-.01-.32.13-.33.31V7.67c-.01.18.13.32.31.33H6v9.91c-.01.53.16 1.05.49 1.47.27.38.71.61 1.18.62h8.66c.47-.01.91-.24 1.18-.63.33-.43.5-.96.49-1.5V8h1c.18.01.32-.13.33-.31V7z" />
    </svg>
  );
};

export default DeleteIcon;
