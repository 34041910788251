import React from 'react';
import {
  AppStore,
  ChatRoomsStatus,
  Project as ProjectModel,
  projectsActions,
  selectors,
  helpers,
} from '@xtrf/shared';
import { useTranslation } from 'react-i18next';
import { Accordion, Container, Loader, Transition, Popup } from 'semantic-ui-react';
import { ChatRoom } from '../chat-room/chat-room.component';
import { useDispatch, useSelector } from 'react-redux';
import { UnreadMessagesIndicator } from '../unread-messages-indicator/unread-messages-indicator.component';
import styles from './project.module.scss';

type ProjectProps = ProjectModel & {
  chatRoomsStatus: ChatRoomsStatus | null;
  index: number;
};

export const Project: React.FC<ProjectProps> = ({
  name,
  chatRooms,
  displayIdentifier,
  chatRoomsStatus,
  id,
  index,
}) => {
  const { t } = useTranslation('pages');
  const { unreadMessagesPerProject } = useSelector(selectors.getTotalUnreadMessagesForWorkspace);
  const collapsedProjects = useSelector((store: AppStore) => store.projects.collapsedProjects);
  const dispatch = useDispatch();

  const { projectDisplayId } = helpers.createProjectDisplayIdentifier(
    displayIdentifier || id,
    true
  );

  const renderChatRooms = () => {
    if (chatRoomsStatus === ChatRoomsStatus.FETCHING) {
      return (
        <Container textAlign="center">
          <Loader active inline size="small" content={t('projects.fetchingChatRooms')} />
        </Container>
      );
    }
    if (!chatRooms || !chatRooms.length) {
      return <Container textAlign="center">{t('projects.emptyChatRoomsList')}</Container>;
    }

    return chatRooms.map(room => (
      <ChatRoom
        key={room.id}
        chatRoom={room}
        status={room.status}
        projectName={name}
        projectDisplayId={displayIdentifier || id}
        vendorEmail={room.vendorEmail}
      />
    ));
  };

  const active = !collapsedProjects.find(el => el === id);

  const getName = (name: string) => {
    const noLanguagesKey = 'noLanguages';

    if (name === noLanguagesKey) {
      return `(${t(`projects.${noLanguagesKey}`)})`;
    }

    return name;
  };

  return (
    <div className={styles.projectItem}>
      <Accordion>
        <Accordion.Title
          className={styles.accordionTitle}
          active={active}
          onClick={() => dispatch(projectsActions.setCollapsedProjects({ id, active }))}
        >
          <div className={styles.projectHeader}>
            <Popup
              content={displayIdentifier || id}
              trigger={
                <div className={styles.id} data-testid="project-displayid">
                  {projectDisplayId}
                </div>
              }
              mouseEnterDelay={800}
              on="hover"
              className={'xtrf-chat-popup'}
              position="top center"
              data-testid="project-displayid-popup"
            />
            <Popup
              content={getName(name)}
              trigger={
                <div className={styles.name} data-testid="project-name">
                  {getName(name)}
                </div>
              }
              mouseEnterDelay={800}
              on="hover"
              className={'xtrf-chat-popup'}
              position="top center"
              data-testid="project-name-popup"
            />
            {!active && <UnreadMessagesIndicator count={unreadMessagesPerProject[id]} />}
          </div>
          <hr className={styles.separator} />
        </Accordion.Title>
        <Transition visible={active} animation="slide down" duration={300}>
          <Accordion.Content active={active} className={styles.accordionContent}>
            {renderChatRooms()}
          </Accordion.Content>
        </Transition>
      </Accordion>
    </div>
  );
};
