import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppStore, RequestStatus, workspacesActions } from '@xtrf/shared';

import { TestMode } from './test-mode';

export const TestModeContainer = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const workspaces = useSelector((state: AppStore) => state.workspaces.data);
  const adminSetWhitelistSettingsStatus = useSelector(
    (state: AppStore) => state.workspaces.adminSetWhitelistSettingsStatus
  );
  const dispatch = useDispatch();

  const workspace = workspaces.find(workspace => workspace.id === workspaceId);

  const [whitelistSettingsStatus, setWhitelistSettingsStatus] = useState(
    adminSetWhitelistSettingsStatus
  );
  const [workspaceTestModeAllowedDomains, setWorkspaceTestModeAllowedDomains] = useState(
    workspace?.config?.testModeAllowedDomains?.join(', ')
  );
  const [testModeChecked, setTestModeChecked] = useState(!!workspace?.config?.testMode);
  const [domainsInputValue, setDomainsInputValue] = useState<string | undefined>(
    workspaceTestModeAllowedDomains
  );
  const isDirtyDomainsInput = workspaceTestModeAllowedDomains !== domainsInputValue;

  const onSetWhitelistSettings = (testMode: boolean) => {
    dispatch(
      workspacesActions.adminSetWhitelistSettings({
        workspaceId,
        testMode,
        testModeAllowedDomains: domainsInputValue
          ? domainsInputValue.replace(/ /g, '').split(',')
          : [],
      })
    );
  };

  useEffect(() => {
    setWhitelistSettingsStatus(adminSetWhitelistSettingsStatus);
  }, [adminSetWhitelistSettingsStatus]);

  useEffect(() => {
    if (whitelistSettingsStatus === RequestStatus.SUCCESS) {
      setWorkspaceTestModeAllowedDomains(domainsInputValue);
      setWhitelistSettingsStatus(null);
    }
    if (whitelistSettingsStatus === RequestStatus.FAILED) {
      setWorkspaceTestModeAllowedDomains(workspace?.config?.testModeAllowedDomains?.join(', '));
      setTestModeChecked(!!workspace?.config?.testMode);
    }
  }, [whitelistSettingsStatus, domainsInputValue, workspace]);

  return (
    <TestMode
      testModeChecked={testModeChecked}
      setTestModeChecked={setTestModeChecked}
      domainsInputValue={domainsInputValue}
      setDomainsInputValue={setDomainsInputValue}
      onSetWhitelistSettings={onSetWhitelistSettings}
      isDirtyDomainsInput={isDirtyDomainsInput}
      isLoading={adminSetWhitelistSettingsStatus === RequestStatus.FETCHING}
    />
  );
};
