import React, { useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import styles from './loader-dotted.module.scss';
import loader from '../../../theme/xtrf/images/loader.gif';

export const LoaderDotted: React.FC<{ forceVisible?: boolean; testId?: string }> = ({
  forceVisible = false,
  testId = '',
}) => {
  const [visible, setVisible] = useState(false);
  const loaderSrc = process.env.REACT_APP_GLOBAL_CHAT_URL
    ? `${process.env.REACT_APP_GLOBAL_CHAT_URL}/loader.gif`
    : loader;

  useEffect(() => {
    const loaderDelay = setTimeout(() => {
      setVisible(true);
    }, 300);

    return () => clearTimeout(loaderDelay);
  }, []);

  return (
    <>
      {(visible || forceVisible) && (
        <div className={styles.loadingBox} data-testid={testId}>
          <Image src={loaderSrc} className={styles.loadingIndicator} />
        </div>
      )}
    </>
  );
};
