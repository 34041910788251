import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserContext } from 'providers/user.provider';

const ProtectedRoute = ({ component: Component, ...rest }: RouteProps) => {
  const user = useContext(UserContext);

  if (!Component) {
    throw new Error('Component is missing');
  }

  return (
    <Route
      {...rest}
      render={props => {
        let path = '/login';
        const { workspaceId, chatRoomId, role } = props.match.params;

        if (workspaceId && role) {
          path += `/${workspaceId}/${role}`;
          if (chatRoomId) {
            path += `/${chatRoomId}`;
          }
        }

        return user ? <Component {...props} /> : <Redirect to={path} />;
      }}
    />
  );
};
export default ProtectedRoute;
