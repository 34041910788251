"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setChatRoomAsInactive = void 0;
const reducer_1 = require("../reducer");
const setChatRoomAsInactive = (chatRoomId) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const { displayedChatList } = getState().chatList;
    const updatedList = displayedChatList.filter(chat => chat.id !== chatRoomId);
    const removedChat = displayedChatList.find(chat => chat.id === chatRoomId);
    if (!removedChat) {
        return;
    }
    const newChatList = updatedList.map(chat => {
        if (chat.order > (removedChat === null || removedChat === void 0 ? void 0 : removedChat.order)) {
            return Object.assign(Object.assign({}, chat), { order: chat.order - 1 });
        }
        return chat;
    });
    dispatch((0, reducer_1.newChatListCreated)(newChatList));
});
exports.setChatRoomAsInactive = setChatRoomAsInactive;
