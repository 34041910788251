import React, { createContext, useEffect, useState } from 'react';
import { initializeFirebase } from '../initialize-firebase';
import * as firebase from 'firebase/app';
import { useDebouncedCallback } from 'use-debounce';
import { AppStore, User, userActions } from '@xtrf/shared';
import { useDispatch, useSelector } from 'react-redux';
import AppLoader from '../shared/app-loader/app-loader';

export const UserContext = createContext<User | null>(null);

const UserProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [isUserResolved, setIsUserResolved] = useState(false);
  const user = useSelector((store: AppStore) => store.user);
  const userUid = user.data?.uid;

  const handleUserData = async (newUser: firebase.User | null) => {
    if (newUser && newUser.uid !== userUid) {
      dispatch(userActions.setLoggedUserData(newUser));
    }
    if (!isUserResolved) {
      setIsUserResolved(true);
    }
  };

  const [debouncedCallback] = useDebouncedCallback(handleUserData, 200);

  useEffect(() => {
    (async () => {
      (await initializeFirebase()).auth.onAuthStateChanged(debouncedCallback);
    })();
  }, [debouncedCallback, dispatch, isUserResolved, userUid]);

  if (!isUserResolved) {
    return <AppLoader />;
  }

  return <UserContext.Provider value={user.data}>{children}</UserContext.Provider>;
};

export default UserProvider;
