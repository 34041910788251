"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToJobs = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const subscribeToJobs = (selectedWorkspace, email, projectsFilter, queryFilters) => (dispatch, _, { firestoreSubscriptions, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch((0, reducer_1.subscribeToProjectsStarted)({ workspace: selectedWorkspace, queryFilters }));
        if (firestoreSubscriptions.projectsListener) {
            firestoreSubscriptions.projectsListener();
        }
        const ref = firestore()
            .collection(firebaseCollectionNames_1.MERGED_USER_JOBS)
            .doc(email)
            .collection(selectedWorkspace.id);
        firestoreSubscriptions.projectsListener = ref.where('status', 'in', projectsFilter).onSnapshot(snapshot => {
            const list = snapshot.docs.map(doc => doc.data());
            dispatch((0, reducer_1.subscribeToProjectsSuccess)(list));
        }, (error) => {
            dispatch((0, reducer_1.subscribeToProjectsFailed)(error.message));
        });
    }
    catch (e) {
        const { message } = e;
        dispatch((0, reducer_1.subscribeToProjectsFailed)(message));
    }
});
exports.subscribeToJobs = subscribeToJobs;
