"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectTab = void 0;
var ProjectTab;
(function (ProjectTab) {
    ProjectTab["Ongoing"] = "ongoing";
    ProjectTab["All"] = "all";
    ProjectTab["Search"] = "search";
    ProjectTab["Settings"] = "settings";
})(ProjectTab = exports.ProjectTab || (exports.ProjectTab = {}));
