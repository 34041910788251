import React, { FC, SVGProps } from 'react';

const OnlineIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
    </svg>
  );
};

export default OnlineIcon;
