"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInvitationErrorLabel = void 0;
const createInvitationErrorLabel = (errorCode, joinToWorkspaceStatus) => {
    switch (errorCode) {
        case 403:
            return 'home.workspace.joinInvitationForbiddenError';
        case 410:
            return 'home.workspace.joinInvitationExpiredError';
        default:
            return joinToWorkspaceStatus
                ? 'home.workspace.joinInvitationError'
                : 'home.workspace.loadInvitationError';
    }
};
exports.createInvitationErrorLabel = createInvitationErrorLabel;
