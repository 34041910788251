"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAdminPendingInvitations = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const helpers_1 = require("../../../../helpers");
const fetchAdminPendingInvitations = (workspaceId) => (dispatch, _, { firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.fetchAdminPendingInvitationStarted)());
    try {
        const baseRef = firestore()
            .collection(firebaseCollectionNames_1.INVITATIONS_COLLECTION_ID)
            .where('expired', '==', false)
            .where('workspaceId', '==', workspaceId);
        const data = (yield baseRef.get());
        const pendingInvitations = data.docs.map(helpers_1.parseToInvitation).flat();
        dispatch((0, reducer_1.fetchAdminPendingInvitationSuccess)(pendingInvitations));
    }
    catch (e) {
        dispatch((0, reducer_1.fetchAdminPendingInvitationFailed)());
    }
});
exports.fetchAdminPendingInvitations = fetchAdminPendingInvitations;
