"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.markAsRead = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const featureFlag_1 = require("../../../../helpers/featureFlag");
const markAsRead = (unreadMessagesPerRoom, workspaceId, unreadMessagesDoc) => (dispatch, getState, { firestore, auth }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (Object.keys(unreadMessagesPerRoom).length === 0) {
        return;
    }
    dispatch((0, reducer_1.markAsReadStarted)());
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { unreadMessages: unreadMessagesFromStore } = getState().workspaces;
        const { selectedWorkspace } = getState().projects;
        const unreadMessagesForSelectedWorkspace = (_b = (_a = unreadMessagesDoc === null || unreadMessagesDoc === void 0 ? void 0 : unreadMessagesDoc.unreadMessages) !== null && _a !== void 0 ? _a : unreadMessagesFromStore === null || unreadMessagesFromStore === void 0 ? void 0 : unreadMessagesFromStore.unreadMessages) !== null && _b !== void 0 ? _b : [];
        const unreadMessagesRef = firestore()
            .collection(firebaseCollectionNames_1.UNREAD_MESSAGES_COUNT_COLLECTION_ID)
            .doc(user.email)
            .collection(firebaseCollectionNames_1.WORKSPACE_SUBCOLLECTION_ID)
            .doc((0, featureFlag_1.featureFlag)(selectedWorkspace, 'manyRoles')
            ? `${workspaceId}-${selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole}`
            : workspaceId);
        const unreadMessagesPerWorkspaceRef = firestore()
            .collection(firebaseCollectionNames_1.UNREAD_MESSAGES_COUNT_COLLECTION_ID)
            .doc(user.email);
        const batch = firestore().batch();
        const totalUnreadMessagesForSelectedWorkspace = unreadMessagesForSelectedWorkspace.length;
        Object.entries(unreadMessagesPerRoom).forEach(([chatRoomId, messageIds]) => {
            const updatedUnreadMessageForWholeWorkspace = unreadMessagesForSelectedWorkspace
                ? unreadMessagesForSelectedWorkspace.filter(item => messageIds.includes(item.id))
                : [];
            const updatedCounter = totalUnreadMessagesForSelectedWorkspace
                ? totalUnreadMessagesForSelectedWorkspace - updatedUnreadMessageForWholeWorkspace.length
                : 0;
            if (updatedUnreadMessageForWholeWorkspace.length === 0) {
                return;
            }
            batch.update(unreadMessagesRef, {
                [`unreadMessagesPerRoom.${chatRoomId}`]: firestore.FieldValue.arrayRemove(...messageIds),
                unreadCounter: updatedCounter,
                unreadMessages: firestore.FieldValue.arrayRemove(...updatedUnreadMessageForWholeWorkspace),
            });
            batch.update(unreadMessagesPerWorkspaceRef, {
                [(0, featureFlag_1.featureFlag)(selectedWorkspace, 'manyRoles')
                    ? `${workspaceId}-${selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole}`
                    : workspaceId]: firestore.FieldValue.arrayRemove(...messageIds),
            });
        });
        yield batch.commit();
        dispatch((0, reducer_1.markAsReadSuccess)(unreadMessagesPerRoom));
    }
    catch (e) {
        dispatch((0, reducer_1.markAsReadFailed)());
    }
});
exports.markAsRead = markAsRead;
