"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCollapsedJobOffers = exports.subscribeToWorkspaceSuccess = exports.subscribeToWorkspaceStarted = exports.subscribeToWorkspaceFailed = exports.changeTab = exports.setCollapsedProjects = exports.clearProjects = exports.unsubscribeFromProjects = exports.subscribeToProjectsSuccess = exports.subscribeToProjectsFailed = exports.subscribeToProjectsStarted = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("./types");
const enums_1 = require("../../enums");
const initialState = {
    status: null,
    data: [],
    workspaceId: null,
    error: null,
    chatRoomsStatus: null,
    selectedWorkspace: null,
    collapsedProjects: [],
    activeTab: enums_1.ProjectTab.Ongoing,
    queryFilters: null,
    collapsedJobOffers: [],
};
const projectsSlice = (0, toolkit_1.createSlice)({
    name: types_1.PROJECTS_REDUCER,
    initialState,
    reducers: {
        subscribeToProjectsStarted(state, { payload }) {
            var _a;
            return Object.assign(Object.assign({}, state), { status: types_1.ProjectsStatus.SUBSCRIBING, error: null, workspaceId: payload.workspace.id, selectedWorkspace: payload.workspace.id === ((_a = state.selectedWorkspace) === null || _a === void 0 ? void 0 : _a.id)
                    ? Object.assign(Object.assign({}, state.selectedWorkspace), payload.workspace) : payload.workspace, queryFilters: payload.queryFilters });
        },
        subscribeToProjectsSuccess(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.ProjectsStatus.SUBSCRIBED, data: payload });
        },
        subscribeToProjectsFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.ProjectsStatus.HAS_ERROR, error: payload });
        },
        setCollapsedProjects(state, { payload }) {
            const collapsedProjects = !payload.active
                ? state.collapsedProjects.filter(e => e !== payload.id)
                : state.collapsedProjects.concat(payload.id);
            return Object.assign(Object.assign({}, state), { collapsedProjects });
        },
        unsubscribeFromProjects(state) {
            return Object.assign(Object.assign({}, initialState), { error: state.error, collapsedProjects: [...state.collapsedProjects] });
        },
        clearProjects() {
            return Object.assign({}, initialState);
        },
        changeTab(state, { payload }) {
            return Object.assign(Object.assign({}, state), { activeTab: payload });
        },
        subscribeToWorkspaceStarted(state) {
            return state;
        },
        subscribeToWorkspaceSuccess(state, { payload }) {
            return Object.assign(Object.assign({}, state), { selectedWorkspace: Object.assign(Object.assign({}, state.selectedWorkspace), payload) });
        },
        subscribeToWorkspaceFailed(state, { payload }) {
            state.error = payload;
        },
        setCollapsedJobOffers(state, { payload }) {
            const collapsedJobOffers = !payload.active
                ? state.collapsedJobOffers.filter(e => e !== payload.id)
                : state.collapsedJobOffers.concat(payload.id);
            return Object.assign(Object.assign({}, state), { collapsedJobOffers });
        },
    },
});
_a = projectsSlice.actions, exports.subscribeToProjectsStarted = _a.subscribeToProjectsStarted, exports.subscribeToProjectsFailed = _a.subscribeToProjectsFailed, exports.subscribeToProjectsSuccess = _a.subscribeToProjectsSuccess, exports.unsubscribeFromProjects = _a.unsubscribeFromProjects, exports.clearProjects = _a.clearProjects, exports.setCollapsedProjects = _a.setCollapsedProjects, exports.changeTab = _a.changeTab, exports.subscribeToWorkspaceFailed = _a.subscribeToWorkspaceFailed, exports.subscribeToWorkspaceStarted = _a.subscribeToWorkspaceStarted, exports.subscribeToWorkspaceSuccess = _a.subscribeToWorkspaceSuccess, exports.setCollapsedJobOffers = _a.setCollapsedJobOffers;
exports.default = projectsSlice.reducer;
