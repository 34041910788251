"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkspaces = void 0;
const reducer_1 = require("../reducer");
const subscribeToUnreadMessagesPerWorkspace_1 = require("./subscribeToUnreadMessagesPerWorkspace");
const types_1 = require("../types");
const user_1 = require("../../user");
const logEvent_1 = require("../../../../helpers/logEvent");
const getWorkspaces = () => (dispatch, getState, { api, auth, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    if (getState().workspaces.status === types_1.WorkspacesStatus.FETCHING) {
        return;
    }
    dispatch((0, reducer_1.getWorkspacesStarted)());
    try {
        const methods = yield api(auth);
        const response = yield methods.getWorkspaces();
        dispatch((0, reducer_1.getWorkspacesSuccess)(response.data || []));
        dispatch((0, subscribeToUnreadMessagesPerWorkspace_1.subscribeToUnreadMessagesPerWorkspace)());
        (0, logEvent_1.logEvent)(analytics, 'get_workspaces');
    }
    catch (e) {
        const { message } = e;
        if (message === 'user-is-not-logged') {
            dispatch(user_1.userActions.logOutUser());
        }
        dispatch((0, reducer_1.getWorkspacesFailed)(message));
    }
});
exports.getWorkspaces = getWorkspaces;
