"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadValidator = void 0;
const files_1 = require("../../../../constants/files");
const uploadValidator = (file) => {
    var _a;
    const fileExtension = (_a = file.name) === null || _a === void 0 ? void 0 : _a.substr(file.name.lastIndexOf('.') + 1);
    if (files_1.EXCLUDED_EXTENSIONS.includes(fileExtension)) {
        return {
            label: files_1.VALIDATION_ERROR_LABELS.WRONG_EXTENSION,
            interpolation: { ext: fileExtension },
        };
    }
    if (file.size > files_1.MAX_FILE_SIZE) {
        return {
            label: files_1.VALIDATION_ERROR_LABELS.MAX_FILE_SIZE,
            interpolation: { size: file.size },
        };
    }
    return false;
};
exports.uploadValidator = uploadValidator;
