"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToWorkspace = void 0;
const __1 = require("..");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const firebaseDocumentNames_1 = require("../../../constants/firebaseDocumentNames");
const models_1 = require("../../../models");
const chatList_1 = require("../../chatList");
const workspaces_1 = require("../../workspaces");
const reducer_1 = require("../reducer");
const subscribeToWorkspace = (workspace, isAnonymized) => (dispatch, getStatus, { firestoreSubscriptions, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user } = getStatus();
    try {
        dispatch((0, reducer_1.subscribeToWorkspaceStarted)());
        if (firestoreSubscriptions.workspaceListener) {
            firestoreSubscriptions.workspaceListener();
        }
        let ref;
        if (isAnonymized && workspace.userRole === models_1.UserRole.Vendor) {
            ref = firestore()
                .collection(firebaseCollectionNames_1.WORKSPACE_COLLECTION_ID)
                .doc(workspace.id)
                .collection(firebaseCollectionNames_1.WORKSPACE_ANONYMIZED_SUBCOLLECTION_ID)
                .doc(firebaseDocumentNames_1.ANONYMIZED_WORKSPACE);
        }
        else {
            ref = firestore()
                .collection(firebaseCollectionNames_1.WORKSPACE_COLLECTION_ID)
                .doc(workspace.id);
        }
        firestoreSubscriptions.workspaceListener = ref.onSnapshot(snapshot => {
            var _a, _b, _c, _d, _e, _f;
            const document = snapshot.data();
            if (!document) {
                throw new Error('workspace-document-not-exists');
            }
            if (document.assigned_users &&
                ((_a = document.assigned_users) === null || _a === void 0 ? void 0 : _a[`${(_b = user.data) === null || _b === void 0 ? void 0 : _b.userId}-${workspace.userRole}`]) &&
                ((_c = document.assigned_users) === null || _c === void 0 ? void 0 : _c[`${(_d = user.data) === null || _d === void 0 ? void 0 : _d.userId}-${workspace.userRole}`].status) &&
                ((_e = document.assigned_users) === null || _e === void 0 ? void 0 : _e[`${(_f = user.data) === null || _f === void 0 ? void 0 : _f.userId}-${workspace.userRole}`].status) ===
                    'removed') {
                dispatch(workspaces_1.workspacesActions.getWorkspaces());
                dispatch(chatList_1.chatListActions.emptyChatList());
                dispatch(__1.projectsActions.unsubscribeFromProjects());
            }
            dispatch((0, reducer_1.subscribeToWorkspaceSuccess)(document));
            dispatch(workspaces_1.workspacesActions.getWorkspaceSettingsConfigSchema(workspace));
        }, (error) => {
            dispatch((0, reducer_1.subscribeToWorkspaceFailed)(error.message));
            dispatch(workspaces_1.workspacesActions.getWorkspaces());
            dispatch(chatList_1.chatListActions.emptyChatList());
            dispatch(__1.projectsActions.unsubscribeFromProjects());
        });
    }
    catch (e) {
        const { message } = e;
        dispatch((0, reducer_1.subscribeToWorkspaceFailed)(message));
    }
});
exports.subscribeToWorkspace = subscribeToWorkspace;
