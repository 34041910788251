import React, { FC } from 'react';
import classNames from 'classnames';
import { FileData, UploadFileStatus } from '@xtrf/shared';
import styles from './file-loader.module.scss';
import { Icon, Loader, Progress } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type FileItemProps = {
  files: {
    [uid: string]: FileData;
  };
  onUploadCancel: (filesToCancel: string[]) => void;
  backgroundColor?: string;
};

const FileLoader: FC<FileItemProps> = ({ files, onUploadCancel, backgroundColor }) => {
  const { t } = useTranslation('pages');

  const uploadingFiles = Object.values(files).filter(
    file => file.status === UploadFileStatus.Uploading
  );

  if (!uploadingFiles.length) {
    return null;
  }

  const getProgressOfUploading = () => {
    if (uploadingFiles.length === 1) {
      return uploadingFiles[0].progress;
    }
    const { sumOfBytesTransferred, totalBytes } = uploadingFiles.reduce(
      (prev, current) => ({
        sumOfBytesTransferred: prev.sumOfBytesTransferred + (current.bytesTransferred || 0),
        totalBytes: prev.totalBytes + current.size,
      }),
      {
        sumOfBytesTransferred: 0,
        totalBytes: 0,
      }
    );

    return Math.round((sumOfBytesTransferred / totalBytes) * 100);
  };

  const label = uploadingFiles.length === 1 ? 'files.uploadingFile' : 'files.uploadingFiles';

  return (
    <div className={styles.container}>
      <div className={classNames(styles.mainContent)} style={{ backgroundColor }}>
        <div className={styles.labelContainer}>
          <Loader inline active size="tiny" className={styles.loader} />
          <div className={styles.name}>{t(label, { count: uploadingFiles.length })}</div>
        </div>
        {onUploadCancel && (
          <Icon
            name="close"
            className={styles.cancelIcon}
            onClick={() => onUploadCancel(uploadingFiles.map(file => file.uid))}
          />
        )}
      </div>
      <Progress
        percent={getProgressOfUploading() || 0}
        attached="bottom"
        color="red"
        className={styles.progress}
      />
    </div>
  );
};

export default FileLoader;
