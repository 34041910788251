import React from 'react';
import { Message as SemanticMessage } from 'semantic-ui-react';
import styles from './message.module.scss';
import classNames from 'classnames';

export type MessageProps = {
  hidden?: boolean;
  title?: string;
  testId?: string;
  content?: string | null;
  className?: string;
  type: 'error' | 'info' | 'success';
};

const Message: React.FC<MessageProps> = ({ hidden, testId, title, content, className, type }) => {
  let messageStyle;
  let messageHeaderStyle;
  switch (type) {
    case 'error':
      messageStyle = styles.error;
      messageHeaderStyle = styles.errorHeader;
      break;
    case 'info':
      messageStyle = styles.info;
      messageHeaderStyle = styles.infoHeader;
      break;
    case 'success':
      messageStyle = styles.success;
      messageHeaderStyle = styles.successHeader;
      break;
  }

  return (
    <SemanticMessage
      hidden={hidden}
      data-testid={testId}
      className={classNames(messageStyle, className)}
    >
      <span className={messageHeaderStyle}>{title}</span>
      {'\u00A0'}
      <span className={styles.content}>{content}</span>
    </SemanticMessage>
  );
};

export default Message;
