import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './menu.module.scss';

export const Menu = () => {
  const { t } = useTranslation('pages');
  const { workspaceId, role } = useParams<{ workspaceId: string; role: string }>();

  return (
    <nav>
      <ul className={styles.list}>
        <li>
          <NavLink
            to={`/${workspaceId}/${role}/adminPanel/members`}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            {t('adminPanel.members.title')}
          </NavLink>
        </li>
        {/* UNCOMMENT WHEN START WORKING ON PRIVACY FEATURES */}
        {/* <li>
          <NavLink
            to={`/${workspaceId}/${role}/adminPanel/privacy`}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            {t('adminPanel.privacy.title')}
          </NavLink>
        </li> */}
        <span className={styles.separateLine}></span>
        <li>
          <NavLink
            to={`/${workspaceId}/${role}/adminPanel/apps`}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            {t('adminPanel.apps.title')}
          </NavLink>
        </li>
        <span className={styles.separateLine}></span>
        <li>
          <NavLink
            to={`/${workspaceId}/${role}/adminPanel/testMode`}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            {t('adminPanel.testMode.title')}
          </NavLink>
        </li>
        {/* UNCOMMENT WHEN START WORKING ON RESET WORKSPACE FEATURES */}
        {/* <li>
          <NavLink
            to={`/${workspaceId}/${role}/adminPanel/resetWorkspace`}
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            {t('adminPanel.resetWorkspace.title')}
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
};
