"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChatRoom = void 0;
const reselect_1 = require("reselect");
const chatRoomType_1 = require("../../enums/chatRoomType");
const getProjects = (store) => store.projects.data;
const getApps = (store) => { var _a; return (_a = store.projects.selectedWorkspace) === null || _a === void 0 ? void 0 : _a.apps; };
const getAppChats = (store) => store.appChats.data;
const getProps = (_, props) => props;
exports.getChatRoom = (0, reselect_1.createSelector)([getProjects, getApps, getAppChats, getProps], (projects, apps, appChats, { displayedChatRoom: selectedChat }) => {
    if (!('projectId' in selectedChat)) {
        if (selectedChat.type === chatRoomType_1.ChatRoomType.AppChatRoom) {
            const appChat = apps === null || apps === void 0 ? void 0 : apps.find(app => app.id === selectedChat.appId);
            return appChat ? appChat.chatRoom : null;
        }
        else {
            const appChat = appChats.find(chat => chat.id === selectedChat.id);
            return appChat ? appChat : null;
        }
    }
    if (!projects || !projects.length) {
        return null;
    }
    const project = projects.find(project => project.id === selectedChat.projectId);
    if (!project || !project.chatRooms) {
        return null;
    }
    const chatRoom = project.chatRooms.find(room => room.id === selectedChat.id);
    if (!chatRoom) {
        return null;
    }
    return chatRoom;
});
