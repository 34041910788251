"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleMinimizationChatRoom = void 0;
const reducer_1 = require("../reducer");
const toggleMinimizationChatRoom = (chatRoomId) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const { displayedChatList } = getState().chatList;
    const updatedList = displayedChatList.map(chat => chat.id === chatRoomId ? Object.assign(Object.assign({}, chat), { minimized: !chat.minimized }) : chat);
    dispatch((0, reducer_1.newChatListCreated)(updatedList));
});
exports.toggleMinimizationChatRoom = toggleMinimizationChatRoom;
