import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AppStore,
  CHAT_TYPE,
  chatListActions,
  helpers,
  projectsActions,
  ProjectsStatus,
  ProjectTab,
  Workspace,
  workspacesActions,
  WorkspacesStatus,
} from '@xtrf/shared';
import { useHistory, useParams } from 'react-router';
import { HomePageParams } from '../components/routing.component';
import { useNumberOfChatRoomWindows } from './useNumberOfChatRoomWindows';
import { ChatRoomType } from '@xtrf/shared/lib/store/enums/chatRoomType';

export const useRouteHandler = (workspaces: Workspace[], selectedWorkspace: Workspace | null) => {
  const workspacesStatus = useSelector((store: AppStore) => store.workspaces.status, shallowEqual);
  const projects = useSelector((store: AppStore) => store.projects, shallowEqual);
  const { workspaceId, chatRoomId, role } = useParams<HomePageParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const numberOfChatRoomWindows = useNumberOfChatRoomWindows();
  const selectedTab = useSelector((store: AppStore) => store.projects.activeTab);
  const { displayedChatList } = useSelector((state: AppStore) => state.chatList, shallowEqual);

  React.useEffect(() => {
    const [workspace] = workspaces;
    if (workspaces.length === 1 && !workspaceId && !workspace.config?.disabled) {
      history.push(`/${workspace.id}/${workspace.userRole}`);
    }
  }, [dispatch, workspaces, history, workspaceId]);

  useEffect(() => {
    const persistedSelectedWorkspace = projects.selectedWorkspace;
    let pathToNewWorkspace = null;
    const allProjects = selectedTab === ProjectTab.All;

    if (persistedSelectedWorkspace) {
      pathToNewWorkspace = helpers.createWorkspacePath(
        persistedSelectedWorkspace.id,
        persistedSelectedWorkspace.userRole
      );
    }
    pathToNewWorkspace =
      workspaceId && role ? helpers.createWorkspacePath(workspaceId, role) : pathToNewWorkspace;

    if (workspacesStatus !== WorkspacesStatus.FETCHED || !pathToNewWorkspace) {
      return;
    }

    if (projects.error) {
      history.push('/');
      dispatch(projectsActions.clearProjects());

      return;
    }

    if (selectedWorkspace && projects.status === null) {
      dispatch(projectsActions.subscribeToProjects(pathToNewWorkspace, allProjects));
    }

    if (!selectedWorkspace) {
      dispatch(projectsActions.subscribeToProjects(pathToNewWorkspace, allProjects));

      return;
    }

    if (
      selectedWorkspace &&
      helpers.createWorkspacePath(selectedWorkspace.id, selectedWorkspace.userRole) !==
        pathToNewWorkspace
    ) {
      dispatch(chatListActions.emptyChatList());
      dispatch(projectsActions.subscribeToProjects(pathToNewWorkspace, allProjects));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, chatRoomId, role, selectedWorkspace, workspacesStatus, projects.error]);

  useEffect(() => {
    if (
      chatRoomId &&
      projects.data.length &&
      projects.status === ProjectsStatus.SUBSCRIBED &&
      selectedWorkspace?.id
    ) {
      const [chatRoom] = projects.data
        .map(project => project.chatRooms?.find(chatRoom => chatRoom.id === chatRoomId))
        .filter(room => room);

      if (chatRoom) {
        const { displayIdentifier, id, name: projectName } = projects.data.find(project =>
          project.chatRooms?.find(chatRoom => chatRoom.id === chatRoomId)
        )!;

        const isAllProjectMembersRoom = chatRoom.name === CHAT_TYPE.ALL_PROJECT_MEMBERS;
        const projectDisplayId = displayIdentifier || id;

        dispatch(
          chatListActions.addChatRoom(
            {
              ...chatRoom,
              projectName,
              projectDisplayId,
              isAllProjectMembersRoom,
              type: ChatRoomType.ProjectChatRoom,
            },
            numberOfChatRoomWindows
          )
        );
      }
      history.replace(
        `/${helpers.createWorkspacePath(selectedWorkspace.id, selectedWorkspace.userRole)}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId, projects.status, selectedWorkspace, dispatch]);

  useEffect(() => {
    if (selectedWorkspace) {
      displayedChatList.forEach(chat => {
        if ('projectId' in chat && chat.type === ChatRoomType.ProjectChatRoom) {
          if (projects.data.map(project => project.id).includes(chat.projectId)) {
            return;
          }
        }

        if ('appId' in chat && chat.type === ChatRoomType.AppChatRoom) {
          if (
            selectedWorkspace?.apps &&
            selectedWorkspace?.apps.map(app => app.id).includes(chat.appId)
          ) {
            return;
          }
        }

        if (chat.type === ChatRoomType.CustomAppChatRoom) {
          return;
        }

        dispatch(workspacesActions.setChatRoomAsInactive(chat.id));
        dispatch(chatListActions.setChatRoomAsInactive(chat.id));
      });
    }
  }, [dispatch, selectedWorkspace, displayedChatList, projects.data]);
};
