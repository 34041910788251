"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageAuthorLabel = void 0;
const userStatusInWorkspace_1 = require("../store/enums/userStatusInWorkspace");
const getMessageAuthorLabel = ({ authorId, userInWorkspace, removedVendorId, isAnonymized, incomingMessage, userName, t, }) => {
    if (!userInWorkspace) {
        return t('chatRoom.defaultUser');
    }
    if (userInWorkspace.status === userStatusInWorkspace_1.UserStatusInWorkspace.Removed || authorId === removedVendorId) {
        return `${t('chatRoom.deleted')} ${authorId.replace(/\D/g, '').slice(0, 6)}`;
    }
    const { anonymizedName, firstName, lastName, username: authorUserName } = userInWorkspace;
    const authorName = authorUserName || `${firstName !== null && firstName !== void 0 ? firstName : ''} ${lastName !== null && lastName !== void 0 ? lastName : ''}`.trim();
    if (isAnonymized && anonymizedName) {
        if (!incomingMessage) {
            return `${userName} (${anonymizedName})`;
        }
        return authorName ? `${authorName} (${anonymizedName})` : anonymizedName;
    }
    return authorName;
};
exports.getMessageAuthorLabel = getMessageAuthorLabel;
