"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromRoom = void 0;
const reducer_1 = require("../reducer");
const unsubscribeFromRoom = (roomId) => (dispatch, _, { firestoreSubscriptions }) => {
    var _a, _b, _c, _d;
    if (typeof ((_a = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _a === void 0 ? void 0 : _a.ref) === 'function') {
        ((_b = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _b === void 0 ? void 0 : _b.ref)();
        if (typeof ((_c = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _c === void 0 ? void 0 : _c.updateRef) === 'function') {
            ((_d = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _d === void 0 ? void 0 : _d.updateRef)();
        }
        delete firestoreSubscriptions.chatRoomsListener[roomId];
    }
    dispatch((0, reducer_1.unsubscribeFromRoom)(roomId));
};
exports.unsubscribeFromRoom = unsubscribeFromRoom;
