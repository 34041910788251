"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserStatus = void 0;
const enums_1 = require("../enums");
const setUserStatus = (database, userId) => {
    const userStatusDatabaseRef = database().ref(`/status/${userId}`);
    const isOfflineForDatabase = {
        state: enums_1.UserPresenceStatus.Offline,
        lastChanged: database.ServerValue.TIMESTAMP,
    };
    const isOnlineForDatabase = {
        state: enums_1.UserPresenceStatus.Online,
        lastChanged: database.ServerValue.TIMESTAMP,
    };
    return database()
        .ref('.info/connected')
        .on('value', function (snapshot) {
        if (snapshot.val() == false) {
            return;
        }
        userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(function () {
            userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
};
exports.setUserStatus = setUserStatus;
