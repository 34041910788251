import React, { useContext, useEffect } from 'react';
import styles from './leave-workspace-confirm.module.scss';
import { Confirm, Button, TransitionablePortal } from 'semantic-ui-react';
import { LeaveWorkspaceConfirmContext } from '../../../providers/leave-workspace-confirm.provider';
import { LeavingWorkspaceStatus, AppStore, workspacesActions } from '@xtrf/shared';
import { useSelector, useDispatch } from 'react-redux';
import { LoaderDotted } from '../loader-dotted/loader-dotted';
import { useTranslation } from 'react-i18next';

export const LeaveWorkspaceConfirm: React.FC = () => {
  const {
    confirmLeaveWorkspaceVisible,
    setConfirmLeaveWorkspaceVisible,
    workspaceId,
    workspaceRole,
  } = useContext(LeaveWorkspaceConfirmContext);

  const leavingWorkspaceStatus = useSelector(
    (store: AppStore) => store.workspaces.leavingWorkspaceStatus
  );
  const selectedWorkspaceId = useSelector((store: AppStore) => store.projects.workspaceId);
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');

  useEffect(() => {
    if (leavingWorkspaceStatus === null) {
      setConfirmLeaveWorkspaceVisible(false);
    }
  }, [leavingWorkspaceStatus, setConfirmLeaveWorkspaceVisible]);

  const handleLeaveWorkspace = () => {
    const id = workspaceId || selectedWorkspaceId;
    if (id && workspaceRole) {
      dispatch(workspacesActions.leaveWorkspace(id, workspaceRole));
    }
  };

  return (
    <TransitionablePortal
      open={confirmLeaveWorkspaceVisible}
      transition={{ animation: 'fade', duration: 300 }}
      onOpen={() => setTimeout(() => document.body.classList.add('confirm-fade-in'), 0)}
    >
      <Confirm
        size="small"
        open
        header={t('workspaces.leaveWorkspaceHeader')}
        content={
          leavingWorkspaceStatus === LeavingWorkspaceStatus.HAS_ERROR ? (
            t('workspaces.error')
          ) : leavingWorkspaceStatus === LeavingWorkspaceStatus.PROCESSING ? (
            <div className={styles.loaderContainer}>
              <LoaderDotted />
            </div>
          ) : (
            t('workspaces.leaveWorkspace')
          )
        }
        cancelButton={
          <Button
            disabled={leavingWorkspaceStatus === LeavingWorkspaceStatus.PROCESSING}
            data-testid="cancel-leave-workspace-button"
          >
            {t('buttons.cancel')}
          </Button>
        }
        confirmButton={
          <Button
            disabled={leavingWorkspaceStatus === LeavingWorkspaceStatus.PROCESSING}
            data-testid="leave-workspace-button"
          >
            {t('buttons.leave')}
          </Button>
        }
        onCancel={() => {
          document.body.classList.remove('confirm-fade-in');
          setConfirmLeaveWorkspaceVisible(false);
        }}
        onConfirm={() => {
          document.body.classList.remove('confirm-fade-in');
          handleLeaveWorkspace();
        }}
        className={styles.confirm}
      />
    </TransitionablePortal>
  );
};
