import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, RequestStatus, workspacesActions } from '@xtrf/shared';

import { RemoveWorkspaceUserAction } from './remove-workspace-user-action';
import { RemoveWorkspaceUserActionContainerProps } from './remove-workspace-user-action.types';

export const RemoveWorkspaceUserActionContainer = ({
  workspaceId,
  userId,
  role,
}: RemoveWorkspaceUserActionContainerProps) => {
  const dispatch = useDispatch();
  const removeWorkspaceUserStatus = useSelector(
    (state: AppStore) => state.workspaces.removeWorkspaceUserStatus
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLoading = removeWorkspaceUserStatus === RequestStatus.FETCHING;
  const isSuccess = removeWorkspaceUserStatus === RequestStatus.SUCCESS;

  const onSubmit = () => {
    dispatch(workspacesActions.removeWorkspaceUser({ workspaceId, userId, role }));
  };

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  return (
    <RemoveWorkspaceUserAction
      onSubmit={onSubmit}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      onModalOpen={() => setIsModalOpen(true)}
      onModalClose={() => setIsModalOpen(false)}
    />
  );
};
