import React, { FC, SVGProps } from 'react';

const AttachmentIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
      <path d="M15 36C8.92 36 4 31.07 4 25s4.92-11 11-11h21c4.42 0 8 3.58 8 8s-3.58 8-8 8H19c-2.76 0-5-2.24-5-5s2.24-5 5-5h15v3H19a2 2 0 100 4h17c2.76 0 5-2.24 5-5s-2.24-5-5-5H15c-4.42 0-8 3.58-8 8s3.58 8 8 8h19v3H15z" />
    </svg>
  );
};

AttachmentIcon.defaultProps = {
  fill: 'rgb(94, 94, 94)',
  transform: 'rotate(-90)',
};

export default AttachmentIcon;
