"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProjectDisplayIdentifier = void 0;
const PROJECT_DISPLAY_ID_MAX_LENGTH = 22;
const PROJECT_DISPLAY_ID_PART = 11;
const PROJECT_NAME_MAX_LENGTH = 15;
const PROJECT_DISPLAY_ID_MAX_LENGTH_NARROW = 20;
const PROJECT_DISPLAY_ID_PART_NARROW = 10;
const createProjectDisplayIdentifier = (projectId, narrow) => {
    let projectDisplayId = projectId;
    const projectDisplayIdMaxLength = narrow
        ? PROJECT_DISPLAY_ID_MAX_LENGTH_NARROW
        : PROJECT_DISPLAY_ID_MAX_LENGTH;
    const projectDisplayIdPartWidth = narrow
        ? PROJECT_DISPLAY_ID_PART_NARROW
        : PROJECT_DISPLAY_ID_PART;
    if (projectDisplayId.length > projectDisplayIdMaxLength) {
        projectDisplayId =
            projectDisplayId.substr(0, projectDisplayIdPartWidth) +
                '...' +
                projectDisplayId.substr(projectDisplayId.length - projectDisplayIdPartWidth, projectDisplayIdPartWidth);
    }
    const projectIdIsLong = projectDisplayId.length > PROJECT_NAME_MAX_LENGTH ? true : false;
    return { projectDisplayId, projectIdIsLong };
};
exports.createProjectDisplayIdentifier = createProjectDisplayIdentifier;
