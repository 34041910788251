import React, { FC } from 'react';
import styles from './chat-window-list.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { AppStore } from '@xtrf/shared';
import { ChatRoomType } from '@xtrf/shared/lib/store/enums/chatRoomType';
import AppChatWindowContainer from '../app-chat-window/app-chat-window.container';
import ProjectChatWindowContainer from '../project-chat-window/project-chat-window.container';

const ChatList: FC = () => {
  const { displayedChatList } = useSelector((state: AppStore) => state.chatList, shallowEqual);

  return (
    <div className={styles.wrapper}>
      {displayedChatList.map(chat =>
        chat.type === ChatRoomType.ProjectChatRoom ? (
          <ProjectChatWindowContainer key={chat.id} displayedChatRoom={chat} />
        ) : (
          <AppChatWindowContainer displayedChatRoom={chat} key={chat.id} />
        )
      )}
    </div>
  );
};

export default ChatList;
