import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import { UserIcon } from 'shared/icons';

import { HeaderProps } from './header.types';
import styles from './header.module.scss';

export const Header = ({ workspaces, user, onLogout }: HeaderProps) => {
  const { t } = useTranslation('pages');
  const { workspaceId } = useParams<{ workspaceId: string }>();

  const workspace = workspaces.find(workspace => workspace.id === workspaceId);

  return (
    <header className={styles.header}>
      <h2 className={styles.title}>{workspace?.name}</h2>

      <div className={styles.userWrapper}>
        <div className={styles.userAvatar}>
          <UserIcon />
        </div>

        <Dropdown
          direction="left"
          icon={null}
          trigger={
            <div className={styles.userEmailWrapper}>
              <span className={styles.userEmailText}>{user?.email}</span>
              <i className="caret down icon"></i>
            </div>
          }
        >
          <Dropdown.Menu className={styles.dropdownMenu}>
            <Dropdown.Item key={1} onClick={onLogout}>
              {t('buttons.signOut')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
};
