import { createStore } from '@xtrf/shared';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/storage';
import persistStorage from 'redux-persist/lib/storage';

export const createReduxStore = (withPersist = false, isGlobalChat = false) => {
  return createStore(
    {
      firestore: firebase.firestore,
      auth: firebase.auth,
      analytics: firebase.analytics,
      functions: firebase.functions,
      apiUrl: process.env.REACT_APP_API_URL || '',
      database: firebase.database,
      storage: firebase.storage,
    },
    withPersist ? persistStorage : undefined,
    isGlobalChat
  );
};
