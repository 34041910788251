"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalUnreadMessagesForWorkspace = void 0;
const reselect_1 = require("reselect");
const getUnreadMessages = (store) => store.workspaces.unreadMessages;
const getProjects = (store) => store.projects.data;
const getWorkspaceApps = (store) => { var _a; return (_a = store.projects.selectedWorkspace) === null || _a === void 0 ? void 0 : _a.apps; };
const getChatRoomsPerProject = (projects) => projects.map(project => project.chatRooms).filter(project => project);
exports.getTotalUnreadMessagesForWorkspace = (0, reselect_1.createSelector)([getUnreadMessages, getProjects, getWorkspaceApps], (unreadMessages, projects, apps) => {
    var _a;
    const total = unreadMessages
        ? Object.keys(unreadMessages.perChatRoom).reduce((prev, current) => prev + unreadMessages.perChatRoom[current].length, 0)
        : 0;
    if (!unreadMessages || !((_a = Object.keys(unreadMessages === null || unreadMessages === void 0 ? void 0 : unreadMessages.perChatRoom)) === null || _a === void 0 ? void 0 : _a.length) || !projects.length) {
        return {
            total,
            unreadMessagesPerProject: {},
            unreadMessagesPerApp: {},
        };
    }
    const chatPerProjects = {};
    getChatRoomsPerProject(projects).forEach(project => {
        project === null || project === void 0 ? void 0 : project.forEach(chatRoom => {
            var _a;
            chatPerProjects[chatRoom.projectId] = [
                ...((_a = chatPerProjects[chatRoom.projectId]) !== null && _a !== void 0 ? _a : []),
                chatRoom.id,
            ];
        });
    });
    const unreadMessagesPerProject = {};
    const unreadMessagesPerApp = {};
    Object.entries(chatPerProjects).forEach(([key, value]) => {
        unreadMessagesPerProject[key] = value
            .map(chatId => { var _a; return ((_a = unreadMessages.perChatRoom[chatId]) !== null && _a !== void 0 ? _a : []).length; })
            .reduce((current, prev) => current + prev, 0);
    });
    if (apps) {
        apps.forEach(app => {
            var _a, _b;
            unreadMessagesPerApp[app.id] = (_b = (_a = unreadMessages.perChatRoom[app.chatRoom.id]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
        });
    }
    return { total, unreadMessagesPerProject, unreadMessagesPerApp };
});
exports.default = exports.getTotalUnreadMessagesForWorkspace;
