"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCollapsedProjects = exports.clearAppChats = exports.unsubscribeFromProjects = exports.subscribeToAppChatsSuccess = exports.subscribeToAppChatsFailed = exports.subscribeToAppChatsStarted = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("./types");
const initialState = {
    status: null,
    data: [],
    workspaceId: null,
    error: null,
    selectedWorkspace: null,
    collapsedProjects: [],
    queryFilters: null,
};
const appChatsSlice = (0, toolkit_1.createSlice)({
    name: types_1.APP_CHATS_REDUCER,
    initialState,
    reducers: {
        subscribeToAppChatsStarted(state, { payload }) {
            var _a;
            return Object.assign(Object.assign({}, state), { status: types_1.SubscriptionStatus.SUBSCRIBING, error: null, workspaceId: payload.workspace.id, selectedWorkspace: payload.workspace.id === ((_a = state.selectedWorkspace) === null || _a === void 0 ? void 0 : _a.id)
                    ? Object.assign(Object.assign({}, state.selectedWorkspace), payload.workspace) : payload.workspace, queryFilters: payload.queryFilters });
        },
        subscribeToAppChatsSuccess(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.SubscriptionStatus.SUBSCRIBED, data: payload });
        },
        subscribeToAppChatsFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.SubscriptionStatus.HAS_ERROR, error: payload, data: [] });
        },
        setCollapsedProjects(state, { payload }) {
            const collapsedProjects = !payload.active
                ? state.collapsedProjects.filter(e => e !== payload.id)
                : state.collapsedProjects.concat(payload.id);
            return Object.assign(Object.assign({}, state), { collapsedProjects });
        },
        unsubscribeFromProjects(state) {
            return Object.assign(Object.assign({}, initialState), { error: state.error, collapsedProjects: [...state.collapsedProjects] });
        },
        clearAppChats() {
            return Object.assign({}, initialState);
        },
    },
});
_a = appChatsSlice.actions, exports.subscribeToAppChatsStarted = _a.subscribeToAppChatsStarted, exports.subscribeToAppChatsFailed = _a.subscribeToAppChatsFailed, exports.subscribeToAppChatsSuccess = _a.subscribeToAppChatsSuccess, exports.unsubscribeFromProjects = _a.unsubscribeFromProjects, exports.clearAppChats = _a.clearAppChats, exports.setCollapsedProjects = _a.setCollapsedProjects;
exports.default = appChatsSlice.reducer;
