"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToUnreadMessagesPerWorkspace = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const filterOutReadMessagesPerWorkspace_1 = require("../../../logic/filterOutReadMessagesPerWorkspace");
const subscribeToUnreadMessagesPerWorkspace = () => (dispatch, getState, { firestoreSubscriptions, firestore, auth }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.subscribeToUnreadMessagesPerWorkspaceStarted)());
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        if (firestoreSubscriptions.unreadMessagesPerWorkspaceListener) {
            firestoreSubscriptions.unreadMessagesPerWorkspaceListener();
        }
        firestoreSubscriptions.unreadMessagesPerWorkspaceListener = firestore()
            .collection(firebaseCollectionNames_1.UNREAD_MESSAGES_COUNT_COLLECTION_ID)
            .doc(user.email)
            .onSnapshot(snapshot => {
            var _a;
            const unreadMessages = snapshot.data();
            const { rooms: { data: messages, messagesToMark }, workspaces, } = getState();
            const workspaceId = (_a = workspaces.unreadMessages) === null || _a === void 0 ? void 0 : _a.workspaceId;
            const filteredUnreadMessagesPerWorkspace = (0, filterOutReadMessagesPerWorkspace_1.filterOutReadMessagesPerWorkspace)({
                unreadMessages,
                roomsData: messages,
                messagesToMark,
                workspaceId,
            });
            dispatch((0, reducer_1.subscribeToUnreadMessagesPerWorkspaceSuccess)(filteredUnreadMessagesPerWorkspace));
        }, () => {
            dispatch((0, reducer_1.subscribeToUnreadMessagesPerWorkspaceFailed)());
        });
    }
    catch (e) {
        dispatch((0, reducer_1.subscribeToUnreadMessagesPerWorkspaceFailed)());
    }
});
exports.subscribeToUnreadMessagesPerWorkspace = subscribeToUnreadMessagesPerWorkspace;
