import hotkeys, { KeyHandler } from 'hotkeys-js';
import { useCallback, useEffect } from 'react';

export type Options = {
  filter?: typeof hotkeys.filter;
  splitKey?: string;
  scope?: string;
  keyup?: boolean;
  keydown?: boolean;
};

export function useHotkeys(keys: string, callback: KeyHandler, options?: Options): void;
export function useHotkeys(keys: string, callback: KeyHandler, deps?: any[]): void;
export function useHotkeys(
  keys: string,
  callback: KeyHandler,
  options?: Options,
  deps?: any[]
): void;
export function useHotkeys(
  keys: string,
  callback: KeyHandler,
  options?: any[] | Options,
  deps?: any[]
): void {
  if (options instanceof Array) {
    deps = options;
    options = undefined;
  }

  const { filter } = options || {};

  const memoisedCallback = useCallback(callback, deps || []);

  useEffect(() => {
    hotkeys.filter = () => true;
    if (filter) hotkeys.filter = filter;

    hotkeys(keys, (options as Options) || {}, memoisedCallback);

    return () => hotkeys.unbind(keys, memoisedCallback);
  }, [filter, keys, memoisedCallback, options]);
}
