import React from 'react';
import PageHeader from 'components/shared/page-header/page-header.component';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HistoryState } from 'models/history-state.model';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import Segment from '../../components/shared/segment/segment-component';
import NoAccountInfo from '../../components/shared/no-account-info/no-account-info.component';
import NewPasswordForm from './components/new-password-form';
import styles from './account-handling.module.scss';
import { AuthOperation, helpers } from '@xtrf/shared';
import { Link } from 'react-router-dom';
import Message from 'components/shared/message/message';

const AccountHandling: React.FC<RouteComponentProps<{}, {}, HistoryState>> = () => {
  const { t } = useTranslation(['pages', 'common']);
  const history = useHistory();
  const mode = helpers.getUrlParams(window.location.href, 'mode') as AuthOperation;
  const actionCode = helpers.getUrlParams(window.location.href, 'oobCode');

  const renderContent = () => {
    if (mode === AuthOperation.PasswordReset && actionCode) {
      return <NewPasswordForm history={history} actionCode={actionCode} />;
    }

    return (
      <>
        <Message
          type="error"
          testId="set-password-error-message"
          title={t('common:validationErrors.title')}
          content={t('common:validationErrors.unhandledMode')}
        />
        <div className={styles.link} data-testid="set-password-signin-link">
          <Link to="/login">{t('login.signIn')}</Link>
        </div>
      </>
    );
  };

  return (
    <PagesContainer>
      <Grid textAlign="center" style={{ height: `100vh` }} verticalAlign="middle">
        <Grid.Column style={{ width: 450 }}>
          <Segment>
            <PageHeader />
            <div className={styles.wrapper}>
              {renderContent()}
              <NoAccountInfo />
            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </PagesContainer>
  );
};

export default AccountHandling;
