import React from 'react';
import { Grid } from 'semantic-ui-react';
import PageHeader from 'components/shared/page-header/page-header.component';
import { JoinWorkspace } from 'components/workspaces/join-workspace/join-workspace.component';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import Segment from '../components/shared/segment/segment-component';

const JoinWorkspacePage: React.FC = () => {
  return (
    <PagesContainer>
      <Grid textAlign="center" style={{ height: `100vh` }} verticalAlign="middle">
        <Grid.Column style={{ width: 450 }}>
          <Segment>
            <PageHeader />
            <JoinWorkspace />
          </Segment>
        </Grid.Column>
      </Grid>
    </PagesContainer>
  );
};

export default JoinWorkspacePage;
