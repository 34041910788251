import React, { useState, memo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  Header,
  Button,
  Icon,
  Dropdown,
  Confirm,
  Checkbox,
  TransitionablePortal,
  Modal as SemanticModal,
} from 'semantic-ui-react';
import Input from '../../../components/shared/input/input-component';
import FormLabel from '../../../components/shared/form-label/form-label.component';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from './account-settings-modal.module.scss';
import { isGlobalChat } from 'shared';
import CloseIcon from 'shared/icons/close-icon';
import {
  userActions,
  AppStore,
  RequestStatus,
  DeleteAccountStatus,
  LINKS,
  helpers,
  filesActions,
} from '@xtrf/shared';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';
import { initializeFirebase } from '../../../initialize-firebase';
import TransitionedModal from 'components/shared/transitioned-modal/transitioned-modal.component';
import AvatarUpload from 'components/shared/avatar/avatar-upload.component';
import { SetAvatar } from 'components/shared/set-avatar/set-avatar';
import CropAvatarError from 'components/shared/set-avatar/set-avatar-error';

export interface AccountSettingsModalProps {
  visible: boolean;
  toggleModal: () => void;
}

const AccountSettingsModal: React.FC<AccountSettingsModalProps> = ({ visible, toggleModal }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation(['pages', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { setting: settingAvatar, error: setAvatarError } =
    useSelector((store: AppStore) => store.files.settingAvatar) || {};

  useEffect(() => {
    if (setAvatarError) {
      dispatch(filesActions.resetAvatarSetting());
    }
  }, [setAvatarError, dispatch]);

  const { detailsStatus, usernameStatus, data: user, deleteAccountStatus } = useSelector(
    (store: AppStore) => store.user
  );

  const handleTermsClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(LINKS.TERMS);
  };

  const handleRemovePhoto = () => {
    dispatch(userActions.removeUserAvatar());
  };

  const handleSignOut = async () => {
    const { messaging } = await initializeFirebase();
    if (messaging) {
      try {
        const token = await messaging.getToken();
        if (token) {
          await dispatch(userActions.removeFcmToken({ token }));
        }
      } catch {
        // Messaging: The notification permission was not granted and blocked instead.
      }
    }
    dispatch(userActions.logOutUser());
    history.push('/login');
  };

  const handleDelete = async () => {
    await dispatch(userActions.deleteAccount());
    history.push('/login');
  };

  const handleSubmit = (values: { username: string; newsletter: boolean }) => {
    if (values.newsletter !== user?.newsletterActive) {
      dispatch(userActions.updateUserData({ newsletterActive: values.newsletter }));
    }
    if (user?.username !== values.username && user?.userId && user.email) {
      dispatch(
        userActions.updateUsername({
          userId: user.userId,
          email: user.email,
          username: values.username.trim(),
        })
      );
    }
  };

  const initialValues = {
    username: user?.username || `${user?.firstName} ${user?.lastName}`,
    newsletter: user?.newsletterActive || false,
  };
  const initials = helpers.getInitials(initialValues.username);
  const avatarBackgroundColor = helpers.getAvatarBackgroundColor(initials);

  return (
    <TransitionedModal visible={visible} testId="account-modal" toggleModal={toggleModal}>
      <Header className={styles.header}>
        <CloseIcon
          onClick={() => {
            document.body.classList.remove('modal-fade-in');
            toggleModal();
          }}
          className={styles.closeIcon}
          data-testid="close-icon"
        />
        <Dropdown
          className={styles.dropdown}
          icon={<Icon name="ellipsis vertical" className={styles.optionsIcon} />}
          data-testid="account-modal-dropdown"
        >
          <Dropdown.Menu className={styles.dropdownList}>
            <Dropdown.Item
              text={t('buttons.deleteAccount')}
              onClick={() => setConfirmOpen(true)}
              data-testid="dropdown-delete-button"
            />
          </Dropdown.Menu>
        </Dropdown>

        <TransitionablePortal
          open={confirmOpen}
          transition={{ animation: 'fade', duration: 300 }}
          onOpen={() => setTimeout(() => document.body.classList.add('confirm-fade-in'), 0)}
        >
          <Confirm
            size="small"
            open
            header={t('settings.deleteAccountHeader')}
            content={
              deleteAccountStatus === DeleteAccountStatus.HAS_ERROR ? (
                t('workspaces.error')
              ) : deleteAccountStatus === DeleteAccountStatus.PROCESSING ? (
                <div className={styles.loaderContainer}>{!isGlobalChat() && <LoaderDotted />}</div>
              ) : (
                t('settings.deleteAccount')
              )
            }
            cancelButton={
              <Button
                disabled={deleteAccountStatus === DeleteAccountStatus.PROCESSING}
                data-testid="cancel-delete-account-button"
              >
                {t('buttons.cancel')}
              </Button>
            }
            confirmButton={
              <Button
                disabled={deleteAccountStatus === DeleteAccountStatus.PROCESSING}
                data-testid="delete-account-button"
              >
                {t('buttons.delete')}
              </Button>
            }
            onCancel={() => {
              document.body.classList.remove('confirm-fade-in');
              setConfirmOpen(false);
            }}
            onConfirm={() => {
              document.body.classList.remove('confirm-fade-in');
              handleDelete();
            }}
            className={styles.confirm}
          />
        </TransitionablePortal>

        <div className={styles.headerTitle} data-testid="modal-title">
          {t('settings.title')}
        </div>
        <div className={styles.userEmail} data-testid="modal-user-email">
          {user?.email}
        </div>
      </Header>
      <SemanticModal.Content>
        {settingAvatar && <SetAvatar />}
        {setAvatarError && <CropAvatarError errorMessage={setAvatarError} />}
        {!settingAvatar && !setAvatarError && (
          <>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={helpers.getValidationSchemaForSettingsForm({ translate: t })}
              enableReinitialize
            >
              {({ errors, values, setFieldValue }) => {
                const disabled = isEqual(initialValues, values) || !!errors.username;

                return (
                  <Form>
                    <FormLabel
                      label="settings.profile"
                      dataTestId="account-settings-profile-label"
                    />
                    <div
                      className={styles.userWrapper}
                      data-testid="account-settings-profile-details"
                    >
                      <AvatarUpload
                        initials={initials}
                        backgroundColor={avatarBackgroundColor}
                        big
                      />
                      <Field
                        name="username"
                        autoComplete="off"
                        component={Input}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                    {user?.avatarUrl && (
                      <div className={styles.removeAvatarWrapper}>
                        <span className={styles.link} onClick={handleRemovePhoto}>
                          {t('settings.removeAvatar')}
                        </span>
                      </div>
                    )}
                    <FormLabel
                      label="settings.notifications"
                      dataTestId="account-settings-notifications-label"
                    />
                    <Field
                      name="newsletter"
                      component={Checkbox}
                      label={t('settings.newsletters')}
                      checked={values.newsletter}
                      onClick={() => setFieldValue('newsletter', !values.newsletter)}
                      data-testid="account-settings-newsletter-checkbox"
                    />
                    <FormLabel
                      label="settings.agreements"
                      dataTestId="account-settings-agreements-label"
                    />
                    <Field
                      component={Checkbox}
                      label={
                        <label className={styles.termsAndConditions}>
                          {t('register.agree')}
                          <span className={styles.link} onClick={handleTermsClick}>
                            {t('register.terms')}
                          </span>
                        </label>
                      }
                      disabled
                      checked
                      data-testid="account-settings-terms-checkbox"
                    />
                    <Button
                      type="submit"
                      loading={
                        detailsStatus === RequestStatus.FETCHING ||
                        usernameStatus === RequestStatus.FETCHING
                      }
                      className={disabled ? styles.disabledButton : styles.blueButton}
                      disabled={disabled}
                      data-testid="account-settings-submit-button"
                    >
                      {t('buttons.saveChanges')}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
            <Button
              loading={false}
              className={styles.signOutButton}
              disabled={false}
              onClick={handleSignOut}
              data-testid="signout-button"
            >
              {t('buttons.signOut')}
            </Button>
            <p className={styles.infoAboutVersion} data-testid="version-info">
              {t('common:applicationVersion')} {process.env.REACT_APP_VERSION}
            </p>
          </>
        )}
      </SemanticModal.Content>
    </TransitionedModal>
  );
};

export default memo(AccountSettingsModal, helpers.propsAreEqual);
