"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitials = void 0;
const getInitials = (name) => name
    .split(' ')
    .map(name => name.charAt(0))
    .slice(0, 2)
    .map(char => char.toLocaleUpperCase())
    .join('');
exports.getInitials = getInitials;
