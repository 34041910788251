"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNewFcmToken = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
exports.addNewFcmToken = (0, toolkit_1.createAsyncThunk)(`${reducer_1.USER_REDUCER}/addNewFcmToken`, ({ token, fieldName = 'fcmTokens' }, { extra: { firestore, auth } }) => __awaiter(void 0, void 0, void 0, function* () {
    const user = auth().currentUser;
    if (!user || !user.email) {
        throw new Error('user-is-not-logged');
    }
    const ref = firestore()
        .collection(firebaseCollectionNames_1.USERS_COLLECTION_ID)
        .where('email', '==', user.email);
    const querySnapshot = yield ref.get();
    if (!querySnapshot.size) {
        throw new Error('document-not-exists');
    }
    const { id } = querySnapshot.docs[0];
    const userRef = firestore()
        .collection(firebaseCollectionNames_1.USERS_COLLECTION_ID)
        .doc(id);
    yield userRef.update({
        [fieldName]: firestore.FieldValue.arrayUnion(token),
    });
}));
