import { UserRole } from '@xtrf/shared';
import React, { useState, createContext, Dispatch, SetStateAction, useCallback } from 'react';

type LeaveWorkspaceConfirmContextType = {
  confirmLeaveWorkspaceVisible: boolean;
  setConfirmLeaveWorkspaceVisible: (show: boolean) => void;
  workspaceId: string;
  setWorkspaceId: Dispatch<SetStateAction<string>>;
  workspaceRole: UserRole | undefined;
  setWorkspaceRole: Dispatch<SetStateAction<UserRole | undefined>>;
};

export const LeaveWorkspaceConfirmContext = createContext<LeaveWorkspaceConfirmContextType>(
  {} as LeaveWorkspaceConfirmContextType
);

const LeaveWorkspaceConfirmProvider: React.FC = ({ children }) => {
  const [confirmLeaveWorkspaceVisible, setConfirmLeaveWorkspaceVisible] = useState(false);
  const [workspaceId, setWorkspaceId] = useState('');
  const [workspaceRole, setWorkspaceRole] = useState<UserRole | undefined>(undefined);
  const value = {
    confirmLeaveWorkspaceVisible,
    setConfirmLeaveWorkspaceVisible: useCallback(
      (show: boolean) => {
        setConfirmLeaveWorkspaceVisible(show);
        show || setWorkspaceId('');
      },
      [setConfirmLeaveWorkspaceVisible, setWorkspaceId]
    ),
    workspaceId,
    setWorkspaceId,
    setWorkspaceRole,
    workspaceRole,
  };

  return (
    <LeaveWorkspaceConfirmContext.Provider value={value}>
      {children}
    </LeaveWorkspaceConfirmContext.Provider>
  );
};

export default LeaveWorkspaceConfirmProvider;
