import React from 'react';
import classNames from 'classnames';

import styles from './radio-button.module.scss';

export type RadioButtonProps = {
  checked: boolean;
  id: string;
  disabled?: boolean;
  onElementChecked?: (id: string) => void;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  id,
  children,
  disabled,
  onElementChecked,
}) => {
  return (
    <div
      className={classNames(
        styles.radioButton,
        checked && styles.radioButtonSelected,
        disabled && styles.radioButtonDisabled
      )}
      onClick={() => !disabled && onElementChecked && onElementChecked(id)}
    >
      <div className={checked ? styles.outerCircleActive : styles.outerCircle}>
        {checked && <div className={styles.innerCircle} />}
      </div>
      {children}
    </div>
  );
};
