"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFileStatus = void 0;
var UploadFileStatus;
(function (UploadFileStatus) {
    UploadFileStatus["Uploading"] = "uploading";
    UploadFileStatus["Success"] = "success";
    UploadFileStatus["Error"] = "error";
    UploadFileStatus["Canceled"] = "canceled";
})(UploadFileStatus = exports.UploadFileStatus || (exports.UploadFileStatus = {}));
