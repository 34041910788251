"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.leaveWorkspace = void 0;
const reducer_1 = require("../reducer");
const getWorkspaces_1 = require("./getWorkspaces");
const actions_1 = require("../../projects/actions");
const chatList_1 = require("../../chatList");
const logEvent_1 = require("../../../../helpers/logEvent");
const leaveWorkspace = (workspaceId, role) => (dispatch, getState, { api, auth, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.leaveWorkspaceStarted)());
    try {
        const { projects: { selectedWorkspace }, workspaces, } = getState();
        dispatch(chatList_1.chatListActions.emptyChatList());
        const workspace = workspaces.data.find(workspace => workspace.id === workspaceId && workspace.userRole === role);
        const methods = yield api(auth);
        yield methods.leaveWorkspace(workspaceId, workspace || selectedWorkspace);
        if ((selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.id) === workspaceId) {
            dispatch((0, actions_1.unsubscribeFromProjects)());
        }
        dispatch((0, getWorkspaces_1.getWorkspaces)());
        dispatch((0, reducer_1.leaveWorkspaceSuccess)());
        (0, logEvent_1.logEvent)(analytics, 'leave_workspace', { workspaceId });
    }
    catch (e) {
        dispatch((0, reducer_1.leaveWorkspaceFailed)());
    }
});
exports.leaveWorkspace = leaveWorkspace;
