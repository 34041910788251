import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '@xtrf/shared';
import classNames from 'classnames';

import { PageContainer } from 'pages/adminPanel/components/pageContainer/page-container';
import { Button } from 'pages/adminPanel/components/button/button';

import styles from './apps.module.scss';

export const Apps = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const { t } = useTranslation('pages');

  const workspaces = useSelector((state: AppStore) => state.workspaces.data);

  const workspace = workspaces.find(workspace => workspace.id === workspaceId);

  return (
    <PageContainer title={t('adminPanel.apps.title')}>
      {!workspace || !workspace.apps || !workspace.apps.length ? (
        <p className={styles.nothingToShowText}>{t('adminPanel.apps.nothingToShow')}</p>
      ) : (
        workspace.apps.map(app => (
          <div className={styles.appWrapper} key={app.id}>
            <div className={styles.appTitleWrapper}>
              <img
                src={`data:image/svg+xml;utf8,${app.icon}`}
                alt="app-icon"
                className={styles.icon}
              />

              <span className={styles.appTitle}>{app.name}</span>
            </div>
            <span>{t(`adminPanel.apps.${app.id}`)}</span>

            <div className={styles.buttonsWrapper}>
              {app.id === 'xtrf-joboffers' && (
                <Button
                  size="large"
                  className={styles.settingsButton}
                  href={`${process.env.REACT_APP_JOB_OFFERS_URL}/${workspaceId}/prospecting-strategies`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('adminPanel.apps.jobOffersButton')}
                </Button>
              )}

              {app.chatRoom.status === 'active' ? (
                <span className={classNames(styles.activeText, styles.activeTextON)}>ON</span>
              ) : (
                <span className={classNames(styles.activeText, styles.activeTextOFF)}>OFF</span>
              )}
            </div>
          </div>
        ))
      )}
    </PageContainer>
  );
};
