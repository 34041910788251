import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Modal, Dropdown } from 'semantic-ui-react';

import TransitionedModal from 'components/shared/transitioned-modal/transitioned-modal.component';
import { Button } from '../button/button';

import { RemoveWorkspaceUserActionProps } from './remove-workspace-user-action.types';
import styles from './remove-workspace-user-action.module.scss';

export const RemoveWorkspaceUserAction = ({
  onSubmit,
  isLoading,
  isModalOpen,
  onModalOpen,
  onModalClose,
}: RemoveWorkspaceUserActionProps) => {
  const { t } = useTranslation('pages');

  return (
    <>
      <Dropdown.Item onClick={onModalOpen} className={styles.dropdownItemDanger}>
        {t('adminPanel.members.removeFromWorkspaceButton')}
      </Dropdown.Item>

      <TransitionedModal
        visible={isModalOpen}
        toggleModal={onModalClose}
        testId="remove-workspace-user-modal"
        width={600}
      >
        <Modal.Content className={styles.modalContent}>
          <Header className={styles.modalHeader}>
            {t('adminPanel.members.removeFromWorkspaceModal.title')}
          </Header>

          {t('adminPanel.members.removeFromWorkspaceModal.text')}

          <div className={styles.buttonsWrapper}>
            <Button size="large" primary onClick={onSubmit} loading={isLoading}>
              {t('adminPanel.members.removeFromWorkspaceModal.confirmButton')}
            </Button>

            <Button size="large" onClick={onModalClose}>
              {t('buttons.cancel')}
            </Button>
          </div>
        </Modal.Content>
      </TransitionedModal>
    </>
  );
};
