import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import styles from './currency-input.module.scss';

type Props<T> = {
  disabledCurrencyDropdown?: boolean;
  onChange: ({ value, currency }: { value: number; currency: T }) => void;
  options: T[];
  value?: string | number;
};

export const CurrencyInput = <T extends string>({ options, onChange, value }: Props<T>) => {
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    onChange({
      value: Number(inputValue),
      currency: options[0],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, onChange]);

  const currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  return (
    <>
      <div className={styles.currency}>
        Currency: <span>{options[0]}</span>
      </div>

      <div className={styles.currencyInputWrapper}>
        <Input
          type="number"
          min={0}
          value={inputValue}
          className={styles.currencyInput}
          input={props => (
            <MaskedInput
              mask={currencyMask}
              {...((props as unknown) as Record<string, unknown>)}
              value={inputValue}
              placeholder="0"
              onChange={e => setInputValue(e.currentTarget.value)}
            />
          )}
        />
      </div>
    </>
  );
};
