import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styles from './chat-message-error-info.module.scss';

type ChatMessageErrorInfoProps = {
  errorMessage?: {
    label: string;
    interpolation?: {
      [key: string]: string | number;
    };
  };
  onCancel?: () => void;
};

const ChatMessageErrorInfo: React.FC<ChatMessageErrorInfoProps> = ({ errorMessage, onCancel }) => {
  const { t } = useTranslation('common');
  if (!errorMessage) return null;

  return (
    <div className={styles.errorMessage}>
      {t(errorMessage.label, errorMessage.interpolation || {})}
      {onCancel && <Icon name="close" className={styles.deleteIcon} onClick={onCancel} />}
    </div>
  );
};

export default ChatMessageErrorInfo;
