"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNewPassword = void 0;
const logInUser_1 = require("./logInUser");
const updateUserData_1 = require("./updateUserData");
const setNewPassword = (newPassword, code, callback) => (dispatch, getState, { auth }) => __awaiter(void 0, void 0, void 0, function* () {
    const { data: { email }, } = yield auth().checkActionCode(code);
    yield auth().confirmPasswordReset(code, newPassword);
    // eslint-disable-next-line
    yield dispatch((0, logInUser_1.logInUser)({ email: email, password: newPassword }));
    // update updatedAt field which cause refreshing token for all logged session
    yield dispatch((0, updateUserData_1.updateUserData)({}));
    if (callback) {
        callback();
    }
});
exports.setNewPassword = setNewPassword;
