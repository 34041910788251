import React from 'react';
import PageHeader from 'components/shared/page-header/page-header.component';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps, useHistory } from 'react-router';
import { HistoryState } from 'models/history-state.model';
import ResetPasswordForm from 'components/auth/reset-password-form/reset-password-form';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import Segment from '../components/shared/segment/segment-component';

const ResetPasswordPage: React.FC<RouteComponentProps<{}, {}, HistoryState>> = () => {
  const history = useHistory();

  return (
    <PagesContainer>
      <Grid textAlign="center" style={{ height: `100vh` }} verticalAlign="middle">
        <Grid.Column style={{ width: 450 }}>
          <Segment>
            <PageHeader />
            <ResetPasswordForm history={history} />
          </Segment>
        </Grid.Column>
      </Grid>
    </PagesContainer>
  );
};

export default ResetPasswordPage;
