"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionStatus = exports.APP_CHATS_REDUCER = void 0;
exports.APP_CHATS_REDUCER = 'AppChats';
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["SUBSCRIBING"] = "SUBSCRIBING";
    SubscriptionStatus["SUBSCRIBED"] = "SUBSCRIBED";
    SubscriptionStatus["HAS_ERROR"] = "HAS_ERROR";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
