"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeActiveWorkspaceSettingsSection = void 0;
const reducer_1 = require("../reducer");
const changeActiveWorkspaceSettingsSection = (section) => (dispatch, getState) => {
    const { activeWorkspaceSettingsSection } = getState().workspaces;
    if (activeWorkspaceSettingsSection === section) {
        return;
    }
    dispatch((0, reducer_1.setActiveWorkspaceSettingsSection)(section));
};
exports.changeActiveWorkspaceSettingsSection = changeActiveWorkspaceSettingsSection;
