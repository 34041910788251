"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromUnreadMessages = void 0;
const reducer_1 = require("../reducer");
const unsubscribeFromUnreadMessages = () => (dispatch, _, { firestoreSubscriptions }) => {
    if (firestoreSubscriptions.unreadMessagesListener) {
        firestoreSubscriptions.unreadMessagesListener();
    }
    dispatch((0, reducer_1.unsubscribeFromUnreadMessages)());
};
exports.unsubscribeFromUnreadMessages = unsubscribeFromUnreadMessages;
