"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendNewLocalMessage = void 0;
const reducer_1 = require("../reducer");
const uuid_1 = require("uuid");
const enums_1 = require("../../../enums");
const sendNewLocalMessage = ({ chatRoomId, message, }) => (dispatch, getState, { auth, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { projects: { workspaceId }, user: { data }, } = getState();
        const user = auth().currentUser;
        if (!user || !user.email || !workspaceId || !(data === null || data === void 0 ? void 0 : data.userId)) {
            throw new Error('user-is-not-logged');
        }
        const timestamp = firestore.Timestamp.fromDate(new Date())
            .toDate()
            .toISOString();
        const newMessage = {
            authorId: data === null || data === void 0 ? void 0 : data.userId,
            content: message,
            createdAt: timestamp,
            updatedAt: timestamp,
            type: enums_1.MessageType.File,
            id: (0, uuid_1.v4)(),
        };
        dispatch((0, reducer_1.addNewLocalMessage)({ messages: [newMessage], roomId: chatRoomId }));
    }
    catch (e) {
        return;
    }
});
exports.sendNewLocalMessage = sendNewLocalMessage;
