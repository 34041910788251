import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './avatar.module.scss';

export type AvatarProps = {
  initials: string | React.ReactNode;
  backgroundColor: string;
  avatarUrl?: string;
  big?: boolean;
  withBorder?: boolean;
};

const Avatar: FC<AvatarProps> = ({
  initials,
  big,
  backgroundColor,
  avatarUrl,
  withBorder = false,
}) => {
  const style = {
    backgroundColor,
    ...(avatarUrl && { backgroundImage: `url(${avatarUrl})` }),
  };

  return (
    <div
      className={classNames(styles.wrapper, big && styles.wrapperBig, withBorder && styles.border)}
      style={style}
    >
      {!avatarUrl && initials}
    </div>
  );
};

export default Avatar;
