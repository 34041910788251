"use strict";
/**
 * The type of operation that generated the action code. This could be:
 * <ul>
 * <li>`EMAIL_SIGNIN`: email sign in code generated via
 *     {@link firebase.auth.Auth.sendSignInLinkToEmail}.</li>
 * <li>`PASSWORD_RESET`: password reset code generated via
 *     {@link firebase.auth.Auth.sendPasswordResetEmail}.</li>
 * <li>`RECOVER_EMAIL`: email change revocation code generated via
 *     {@link firebase.User.updateEmail}.</li>
 * <li>`REVERT_SECOND_FACTOR_ADDITION`: revert second factor addition
 *     code generated via
 *     {@link firebase.User.MultiFactorUser.enroll}.</li>
 * <li>`VERIFY_AND_CHANGE_EMAIL`: verify and change email code generated
 *     via {@link firebase.User.verifyBeforeUpdateEmail}.</li>
 * <li>`VERIFY_EMAIL`: email verification code generated via
 *     {@link firebase.User.sendEmailVerification}.</li>
 * </ul>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthOperation = void 0;
var AuthOperation;
(function (AuthOperation) {
    AuthOperation["PasswordReset"] = "resetPassword";
    AuthOperation["RecoverEmail"] = "recoverEmail";
    AuthOperation["EmailSignIn"] = "emailSignin";
    AuthOperation["RevertSecondFactorAddition"] = "revertSecondFactorAddition";
    AuthOperation["VerifyAndChangeEmail"] = "verifyAndChangeEmail";
    AuthOperation["VerifyEmail"] = "verifyEmail";
})(AuthOperation = exports.AuthOperation || (exports.AuthOperation = {}));
