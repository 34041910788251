import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations, LANGUAGE } from '@xtrf/shared';

i18n.use(initReactI18next).init({
  resources: translations,
  lng: LANGUAGE,
  ns: ['pages', 'common'],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
