"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserPresence = void 0;
const reducer_1 = require("../reducer");
const setUserStatusManually_1 = require("../../../userPresence/setUserStatusManually");
const setUserStatus_1 = require("../../../userPresence/setUserStatus");
const setUserPresence = (state) => (dispatch, getState, { database }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { user } = getState();
        if (user === null || user === void 0 ? void 0 : user.data) {
            const { userId } = user.data;
            if (!userId) {
                return;
            }
            if (state) {
                yield (0, setUserStatusManually_1.setUserStatusManually)({ database, userId, state });
                return;
            }
            yield (0, setUserStatus_1.setUserStatus)(database, userId);
        }
    }
    catch (e) {
        const { code } = e;
        dispatch((0, reducer_1.logInFailed)(code));
    }
});
exports.setUserPresence = setUserPresence;
