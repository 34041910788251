"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatToggled = exports.chatClosed = exports.chatOpened = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("./types");
const initialState = {
    status: types_1.GlobalChatStatus.OPENED,
};
const GLOBAL_CHAT = 'GlobalChat';
const globalChatSlice = (0, toolkit_1.createSlice)({
    name: GLOBAL_CHAT,
    initialState,
    reducers: {
        chatOpened(state) {
            return Object.assign(Object.assign({}, state), { status: types_1.GlobalChatStatus.OPENED });
        },
        chatClosed(state) {
            return Object.assign(Object.assign({}, state), { status: types_1.GlobalChatStatus.CLOSED });
        },
        chatToggled(state) {
            return Object.assign(Object.assign({}, state), { status: state.status === types_1.GlobalChatStatus.OPENED
                    ? types_1.GlobalChatStatus.CLOSED
                    : types_1.GlobalChatStatus.OPENED });
        },
    },
});
_a = globalChatSlice.actions, exports.chatOpened = _a.chatOpened, exports.chatClosed = _a.chatClosed, exports.chatToggled = _a.chatToggled;
exports.default = globalChatSlice.reducer;
