"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJobOfferChats = void 0;
const reselect_1 = require("reselect");
const constants_1 = require("../../../constants");
const helpers_1 = require("../../../helpers");
const enums_1 = require("../../enums");
const models_1 = require("../../models");
const getAppChats_1 = require("./getAppChats");
const getSelectedWorkspace = (store) => store.projects.selectedWorkspace;
const getProjects = (store) => store.projects.data;
exports.getJobOfferChats = (0, reselect_1.createSelector)([getAppChats_1.getAppChats, getProjects, getSelectedWorkspace], (chats, projects, selectedWorkspace) => {
    const groupedChatRooms = chats
        .filter(chat => chat.appId === constants_1.CHAT_APPS['xtrf-joboffers'].id)
        .map(c => {
        var _a, _b;
        return (c = Object.assign(Object.assign({}, c), { projectDisplayIdentifier: `${(_a = projects.find(p => p.id === c.projectId)) === null || _a === void 0 ? void 0 : _a.displayIdentifier}`, projectName: `${(_b = projects.find(p => p.id === c.projectId)) === null || _b === void 0 ? void 0 : _b.name}` }));
    })
        .sort((a, b) => (0, helpers_1.naturalSorter)(a.name, b.name))
        .reduce((groupedChatRooms, chatRoom) => {
        return chatRoom.status === enums_1.ChatRoomStatus.Active
            ? Object.assign(Object.assign({}, groupedChatRooms), { active: [...(groupedChatRooms.active || []), chatRoom] }) : Object.assign(Object.assign({}, groupedChatRooms), { inactive: [...(groupedChatRooms.inactive || []), chatRoom] });
    }, {});
    if ((selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole) === models_1.UserRole.Vendor) {
        return groupedChatRooms;
    }
    else {
        const groupedByProjectsActiveChatRooms = (groupedChatRooms.active || []).reduce((groupedChatRooms, chatRoom) => {
            const groupingKey = chatRoom.projectDisplayIdentifier || '?';
            return Object.assign(Object.assign({}, groupedChatRooms), { [groupingKey]: [...(groupedChatRooms[groupingKey] || []), chatRoom] });
        }, {});
        const { active } = groupedChatRooms, rest = __rest(groupedChatRooms, ["active"]);
        return Object.assign(Object.assign({}, rest), groupedByProjectsActiveChatRooms);
    }
});
