"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setChatRoomAsActive = void 0;
const markAsRead_1 = require("../../rooms/actions/markAsRead");
const reducer_1 = require("../reducer");
const setChatRoomAsActive = (chatRoomId) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const status = getState().rooms.roomsStatus[chatRoomId];
    if (!(status === null || status === void 0 ? void 0 : status.fetchingFirstPage)) {
        const messages = getState().rooms.data[chatRoomId];
        const { workspaceId, selectedWorkspace } = getState().projects;
        if (workspaceId && messages) {
            dispatch((0, markAsRead_1.markAsRead)({ [chatRoomId]: messages.map(message => message.id) }, workspaceId));
        }
    }
    dispatch((0, reducer_1.setChatRoomActive)(chatRoomId));
});
exports.setChatRoomAsActive = setChatRoomAsActive;
