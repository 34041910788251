import React from 'react';
import { UploadIcon } from 'shared/icons';
import styles from './drag-and-drop-icon.module.scss';
import classNames from 'classnames';

type UploadIconProps = {
  preliminary: boolean | null;
};

export const DragAndDropIcon: React.FC<UploadIconProps> = ({ preliminary }) => {
  return (
    <div className={classNames(styles.iconWrapper, preliminary && styles.preliminaryWrapper)}>
      <UploadIcon className={classNames(styles.icon, preliminary && styles.preliminaryIcon)} />
    </div>
  );
};
