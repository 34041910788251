import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  AppStore,
  roomsActions,
  selectors,
  workspacesActions,
  chatListActions,
  DisplayedAppChat,
  AppChatRoom,
} from '@xtrf/shared';
import { connect, useDispatch, useSelector, shallowEqual, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash';
import { Fade } from 'components/shared/fade/fade';
import AppChatWindow from './app-chat-window.component';

type OwnProps = {
  displayedChatRoom: DisplayedAppChat;
};

const mapStateToProps = (store: AppStore, { displayedChatRoom }: OwnProps) => {
  return {
    roomData: selectors.getRoomMessages(store, { roomId: displayedChatRoom.id, inverted: false }),
    roomInfo: store.rooms.roomsStatus[displayedChatRoom.id] || null,
    user: store.user.data,
    chatRoom: selectors.getChatRoom(store, { displayedChatRoom }) as AppChatRoom,
    selectedWorkspace: store.projects.selectedWorkspace,
  };
};

const mapDispatch = {
  subscribeToRoom: roomsActions.subscribeToRoom,
  unsubscribeFromRoom: roomsActions.unsubscribeFromRoom,
  sendNewMessage: roomsActions.sendNewMessage,
  getNextPage: roomsActions.getNextPage,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ChatWindowContainerProps = OwnProps & PropsFromRedux;

const AppChatWindowContainer = ({
  subscribeToRoom,
  unsubscribeFromRoom,
  roomData,
  roomInfo,
  sendNewMessage,
  user,
  getNextPage,
  selectedWorkspace,
  displayedChatRoom,
  chatRoom,
}: ChatWindowContainerProps) => {
  const dispatch = useDispatch();
  const [chatRoomOpen, setChatRoomOpen] = useState(false);
  const { displayedChatList } = useSelector((state: AppStore) => state.chatList, shallowEqual);

  const minimize = useCallback(
    (value: boolean) => {
      if (value) {
        dispatch(workspacesActions.setChatRoomAsInactive(displayedChatRoom.id));
      } else {
        dispatch(workspacesActions.setChatRoomAsActive(displayedChatRoom.id));
      }
      dispatch(chatListActions.toggleMinimizationChatRoom(displayedChatRoom.id));
    },
    [dispatch, displayedChatRoom]
  );

  const setChatRoomAsInactive = useCallback(() => {
    dispatch(workspacesActions.setChatRoomAsInactive(displayedChatRoom.id));
    dispatch(chatListActions.setChatRoomAsInactive(displayedChatRoom.id));
  }, [displayedChatRoom, dispatch]);

  const workspaceName = selectedWorkspace?.name;
  const workspaceRole = selectedWorkspace?.userRole;

  // const chatRoomName = "name" in chatRoom ? chatRoom.name : chatRoom.isAllProjectMembersRoom
  //   ? CHAT_TYPE.ALL_PROJECT_MEMBERS
  //   : CHAT_TYPE.PROJECT_MANAGERS

  const sendNewMessageHandler = useCallback(
    (message: string) => {
      if (!workspaceName || !workspaceRole || !chatRoom) {
        return;
      }
      sendNewMessage({
        chatRoomId: displayedChatRoom.id,
        message,
        authorRole: workspaceRole,
        workspaceName,
        chatRoomName: chatRoom?.name,
      });
    },
    [workspaceName, workspaceRole, sendNewMessage, displayedChatRoom.id, chatRoom]
  );

  useEffect(() => {
    if (chatRoom) {
      subscribeToRoom(displayedChatRoom.id, !!chatRoom.useRecipients);
    }

    return () => {
      unsubscribeFromRoom(displayedChatRoom.id);
    };
  }, [displayedChatRoom.id, chatRoom, user, unsubscribeFromRoom, subscribeToRoom]);

  useEffect(() => {
    if (displayedChatList.map(chat => chat.id).includes(displayedChatRoom.id)) {
      setChatRoomOpen(true);
    }
  }, [displayedChatList, displayedChatRoom.id]);

  if (!selectedWorkspace || !chatRoom) {
    return null;
  }

  const handleClose = () => setChatRoomOpen(false);

  return (
    <Fade show={chatRoomOpen} onEnd={setChatRoomAsInactive}>
      <AppChatWindow
        roomData={roomData}
        roomInfo={roomInfo}
        sendNewMessage={sendNewMessageHandler}
        user={user}
        chatRoom={chatRoom}
        displayedChatRoom={displayedChatRoom}
        getPreviousMessages={getNextPage}
        setIsClosed={handleClose}
        setIsMinimalized={minimize}
        key={displayedChatRoom.id}
      />
    </Fade>
  );
};

function propsAreEqual(prevProps: ChatWindowContainerProps, nextProps: ChatWindowContainerProps) {
  return isEqual(prevProps, nextProps);
}

export default connector(memo(AppChatWindowContainer, propsAreEqual));
