"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendNewMessage = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const enums_1 = require("../../../enums");
const logEvent_1 = require("../../../../helpers/logEvent");
const sendNewMessage = ({ chatRoomId, message, messageType = enums_1.MessageType.Text, authorRole, workspaceName, chatRoomName, }) => (dispatch, getState, { auth, firestore, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.sendNewMessageStarted)());
    try {
        const { projects: { workspaceId }, user: { data }, } = getState();
        const user = auth().currentUser;
        if (!user || !user.email || !workspaceId || !(data === null || data === void 0 ? void 0 : data.userId)) {
            throw new Error('user-is-not-logged');
        }
        const ref = firestore()
            .collection(firebaseCollectionNames_1.CHAT_ROOMS_COLLECTION_ID)
            .doc(chatRoomId)
            .collection(firebaseCollectionNames_1.MESSAGES_COLLECTION_ID);
        yield ref.add({
            recipients: ['all'],
            workspaceId,
            authorId: data.userId,
            content: message,
            createdAt: firestore.FieldValue.serverTimestamp(),
            updatedAt: firestore.FieldValue.serverTimestamp(),
            type: messageType,
            authorRole,
            workspaceName,
            chatRoomName,
        });
        dispatch((0, reducer_1.sendNewMessageSuccess)());
        (0, logEvent_1.logEvent)(analytics, 'send_new_message', {
            chatRoomId,
            workspaceId,
            authorId: data.userId,
            type: messageType,
        });
    }
    catch (e) {
        const { message } = e;
        dispatch((0, reducer_1.sendNewMessageFailed)(message));
    }
});
exports.sendNewMessage = sendNewMessage;
