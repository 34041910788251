"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMessageVisibilityProps = void 0;
const messageVisibility_1 = require("../store/enums/messageVisibility");
const createMessageVisibilityProps = ({ recipients = ['all'] }) => {
    let visibilityNote;
    const messageVisibility = recipients.includes('all')
        ? messageVisibility_1.MessageVisibility.All
        : recipients.includes('Manager') || recipients.includes('Vendor')
            ? messageVisibility_1.MessageVisibility.Group
            : messageVisibility_1.MessageVisibility.User;
    const hasLimitedVisibility = messageVisibility !== messageVisibility_1.MessageVisibility.All;
    if (recipients.includes('Manager')) {
        visibilityNote = '(message visible to all managers)';
    }
    else if (recipients.includes('Vendor')) {
        visibilityNote = '(message visible to all vendors)';
    }
    else if (!recipients.includes('all')) {
        visibilityNote = '(message visible only to you)';
    }
    return { messageVisibility, visibilityNote, hasLimitedVisibility };
};
exports.createMessageVisibilityProps = createMessageVisibilityProps;
