import React, { FC } from 'react';
import styles from './multiselect.module.scss';
import { Checkbox, Dropdown, Icon, Segment } from 'semantic-ui-react';
import { get } from 'lodash';
import { useField } from 'formik';
import classNames from 'classnames';

type Option = {
  value: number | string;
  label: string;
  key: string;
};

type OwnProps = {
  options: Option[];
  label: string;
  disabled: boolean;
  field: {
    name: string;
  };
  form: {
    setFieldValue: (n: string, v: number[] | string[]) => {};
    values: object;
    setFieldTouched: (n: string) => {};
  };
};
export type MultiselectProps = OwnProps;
const Multiselect: FC<MultiselectProps> = ({
  options,
  label,
  disabled,
  field: { name },
  form: { setFieldValue, values, setFieldTouched },
}) => {
  // https://github.com/formium/formik/issues/343
  const [field, meta] = useField(name);
  const showValidationError = meta.touched && meta.error;

  return (
    <>
      <label className={styles.formFieldLabel}>{label}</label>
      <div className={styles.formFieldContainer}>
        <Segment.Inline
          className={classNames(
            styles.multiselectField,
            showValidationError && styles.multiselectFieldError,
            disabled && styles.disabled
          )}
        >
          <span className={styles.multiselectFieldSelectionLabel}>
            {field.value && field.value.length > 0
              ? `(${field.value.length}) ` +
                options
                  .filter(opt => field.value.includes(opt.value))
                  .map(opt => opt.label)
                  .join(', ')
              : 'None selected'}
          </span>
          <Dropdown
            disabled={disabled}
            icon={<Icon name="chevron down" className={styles.dropdownIcon} />}
            item
            multiple
            className={styles.multiselectFieldDropdown}
            onBlur={() => {
              setFieldTouched(name);
            }}
          >
            <Dropdown.Menu className={styles.multiselectFieldDropdownMenu}>
              {options.map(({ value, label, key }) => (
                <Dropdown.Item
                  id={styles.option}
                  key={key}
                  className={styles.multiselectFieldDropdownMenuItem}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Checkbox
                    checked={get(values, name) && get(values, name).includes(value)}
                    className={styles.multiselectFieldDropdownCheckbox}
                    label={label}
                    onClick={e => e.stopPropagation()}
                    onChange={(e, { checked }) => {
                      if (checked) {
                        setFieldValue(name, [...get(values, name), value]);
                      } else {
                        setFieldValue(
                          name,
                          get(values, name).filter((el: number) => el !== value)
                        );
                      }
                    }}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Segment.Inline>
        {showValidationError && (
          <div className={styles.multiselectFieldErrorLabel}>{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default Multiselect;
