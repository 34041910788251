import React, { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { AppStore, helpers, roomsActions, workspacesActions } from '@xtrf/shared';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ResendInvitationStatus } from '@xtrf/shared/lib/store/reducers/workspaces/types';

import { ResendInvitationPropsContainer } from './resend-invitation-action.types';
import { ResendInvitationAction } from './resend-invitation-action';

export const ResendInvitationActionContainer = ({
  workspaceUser,
}: ResendInvitationPropsContainer) => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const dispatch = useDispatch();
  const workspaces = useSelector((state: AppStore) => state.workspaces.data);
  const invitationData = useSelector((store: AppStore) => store.rooms.invitationData);
  const resendInvitationStatus = useSelector(
    (store: AppStore) => store.workspaces.resendInvitationStatus
  );
  const { adminPendingInvitations } = useSelector((store: AppStore) => store.workspaces);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const workspace = workspaces.find(workspace => workspace.id === workspaceId);

  const invitation = adminPendingInvitations.find(inv => inv.email === workspaceUser.email);

  const invitationDate = invitation?.createdAt
    ? helpers.createInvitationDate(parseISO(invitation.createdAt))
    : null;

  const invitationUrl =
    invitation?.url ??
    `${process.env.REACT_APP_SPA_URL ?? ''}/join-workspace?token=${invitation?.token}`;

  const isResendInvitationSuccess = resendInvitationStatus === ResendInvitationStatus.SENT;

  const onModalOpen = () => {
    setIsModalOpen(true);
    dispatch(
      roomsActions.setInvitationData({
        id: workspaceUser.userId,
        name: workspaceUser.username,
        email: workspaceUser.email,
        invitationUrl,
        invitationDate,
      })
    );
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    dispatch(roomsActions.invitationDataReset());
  };

  const onSubmit = () => {
    if (!invitation) return;

    dispatch(workspacesActions.adminResendInvitation(invitation.id));
  };

  useEffect(() => {
    if (isResendInvitationSuccess) {
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1800);
    }
  }, [isResendInvitationSuccess]);

  return (
    <ResendInvitationAction
      workspace={workspace}
      invitationData={invitationData}
      isModalOpen={isModalOpen}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      onSubmit={onSubmit}
    />
  );
};
