import React, { FC, memo, useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { CurrencyInput } from '../../../../shared/currency-input/currency-input';
import { parseISO } from 'date-fns';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import styles from './negotiateJobOfferForm.module.scss';
import FormLabel from '../../../../shared/form-label/form-label.component';
import { helpers } from '@xtrf/shared';
import { DateTimePicker } from 'components/shared/date-time-picker/DateTimePicker';

export type NegotiateJobOfferFormProps = {
  deadline: string;
  amount: string;
  currency: string;
  options: string;
  onSendMessage: (message: string) => void;
  mintime: string;
};

const NegotiateJobOfferForm: FC<NegotiateJobOfferFormProps> = ({
  deadline,
  currency,
  amount,
  onSendMessage,
  mintime,
}) => {
  const { handleSubmit, setFieldValue, values, submitForm } = useFormik({
    initialValues: {
      deadline: parseISO(deadline),
      fee: {
        currency,
        value: amount,
      },
    },
    onSubmit: values => {
      onSendMessage(
        `**Deadline**: ${values.deadline.toISOString()} \\\n**Fee**: ${values.fee.value} ${
          values.fee.currency
        }`
      );
    },
  });

  const handleChangeFeeValue = useCallback(
    (fee: { value: number; currency: string }) => {
      setFieldValue('fee', fee);
    },
    [setFieldValue]
  );

  const [deadlineDate, setDeadlineDate] = useState(values.deadline);
  const [deadlineTime, setDeadlineTime] = useState(values.deadline);
  const [isInputDateError, setIsInputDateError] = useState(false);
  const [isInputTimeError, setIsInputTimeError] = useState(false);

  const changeDeadlineDate = (value: Date) => {
    if (value) {
      setDeadlineDate(value);
      setIsInputDateError(false);

      const isTimeBeforeMinTime = value.getTime() < new Date(mintime).getTime() - 60000;
      if (isTimeBeforeMinTime) {
        setIsInputTimeError(true);

        return;
      }

      setIsInputTimeError(false);
      setFieldValue('deadline', value);
    }
  };

  const changeDeadlineTime = (value: Date) => {
    if (value) {
      const timeHours = value.getHours();
      const timeMinutes = value.getMinutes();
      const timeSeconds = 0;

      setDeadlineTime(value);
      deadlineDate.setHours(timeHours, timeMinutes, timeSeconds);

      const isTimeBeforeMinTime = deadlineDate.getTime() < new Date(mintime).getTime() - 60000;
      if (isTimeBeforeMinTime) {
        setIsInputTimeError(true);

        return;
      }

      setIsInputTimeError(false);
      setFieldValue('deadline', deadlineDate);
    }
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formFieldWrapper}>
          <FormLabel label="Deadline" dataTestId="deadline" />

          <DateTimePicker
            onDateChange={changeDeadlineDate}
            onTimeChange={changeDeadlineTime}
            selected={values.deadline}
            dateSelected={deadlineDate}
            timeSelected={deadlineTime}
            setDeadlineDate={setDeadlineDate}
            minTime={mintime}
            isInputDateError={isInputDateError}
            setIsInputDateError={setIsInputDateError}
            isInputTimeError={isInputTimeError}
          />
        </div>
        <div className={styles.formFieldWrapper}>
          <FormLabel label="Fee" dataTestId="fee" />
          <CurrencyInput
            value={values.fee.value}
            onChange={handleChangeFeeValue}
            options={[currency]}
          />
        </div>
      </form>
      <div>
        <Button
          size="small"
          className={classNames('buttonSuccess', 'message-btn', styles.sendMessageButton)}
          onClick={submitForm}
          disabled={isInputDateError || isInputTimeError}
        >
          Continue
        </Button>
        <Button
          size="small"
          className={classNames('message-btn')}
          onClick={() => {
            onSendMessage('Cancel negotiation');
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default memo(NegotiateJobOfferForm, helpers.propsAreEqual);
