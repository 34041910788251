import React from 'react';
import { OnlineIndicator } from '../online-indicator/online-indicator.component';
import styles from './user-status-icon.module.scss';
import { AllMembersIcon, PendingIcon } from '../../../shared/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type UserStatusIconProps = {
  isExclamationMark?: boolean;
  isPendingInvitation: boolean;
  isAllProjectMembersRoom: boolean;
  isOnline: boolean;
};

const UserStatusIcon: React.FC<UserStatusIconProps> = ({
  isPendingInvitation,
  isAllProjectMembersRoom,
  isOnline,
  isExclamationMark,
}) => {
  if (isExclamationMark === true) {
    return (
      <ExclamationCircleOutlined
        className={styles.exclamationIcon}
        data-testid="exclamation-icon"
      />
    );
  }
  if (isPendingInvitation) {
    return <PendingIcon className={styles.pendingIcon} data-testid="pending-icon" />;
  }
  if (isAllProjectMembersRoom) {
    return <AllMembersIcon className={styles.allMembersIcon} data-testid="allmembers-icon" />;
  }

  return <OnlineIndicator isOnline={isOnline} />;
};

export default UserStatusIcon;
