import React, { useState, useEffect } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import styles from './login-form.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, userActions, UserStatus } from '@xtrf/shared';
import NoAccountInfo from 'components/shared/no-account-info/no-account-info.component';
import { PersonIcon, LockIcon } from '../../../shared/icons';
import { useParams } from 'react-router';
import { HomePageParams } from '../../routing.component';
import Message from 'components/shared/message/message';

interface LoginFormProps {
  joinWorkspaceToken?: string;
  email?: string;
}

const LoginForm: React.FC<LoginFormProps> = props => {
  const [email, setEmail] = useState(props.email || '');
  const [password, setPassword] = useState('');
  const user = useSelector((store: AppStore) => store.user);
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'common']);
  const history = useHistory();
  const { workspaceId, role, chatRoomId } = useParams<HomePageParams>();

  useEffect(() => {
    if (user.status === UserStatus.IS_LOGGED) {
      return history.push('/');
    }
    dispatch(userActions.resetError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const callback = () => {
      if (props.joinWorkspaceToken) {
        history.push('join-workspace', {
          token: props.joinWorkspaceToken,
        });
      } else {
        if (workspaceId && role && chatRoomId) {
          history.push(`/${workspaceId}/${role}/${chatRoomId}`, {});
        } else if (workspaceId && role) {
          history.push(`/${workspaceId}/${role}`, {});
        } else {
          history.push('/', {});
        }
      }
    };
    await dispatch(userActions.logInUser({ email, password }, callback));
  };

  const handleOnChange = (updater: (value: string) => void) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updater(e.target.value);

  return (
    <div className={styles.wrapper} data-testid="login-form">
      <Message
        hidden={user.error === null}
        testId="login-error-message"
        title={t('common:validationErrors.title')}
        content={t(`common:firebaseAuthErrors.${user.error}`)}
        type="error"
      />
      <Header as="h3" textAlign="left" className={styles.header}>
        {t('login.signInTo')}
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          fluid
          icon={<PersonIcon className={styles.icon} />}
          iconPosition="left"
          placeholder="E-mail address"
          className={styles.loginInput}
          value={email}
          disabled={!!props.joinWorkspaceToken}
          onChange={handleOnChange(setEmail)}
          data-testid="login-email-field"
        />
        <Form.Input
          fluid
          icon={<LockIcon className={styles.icon} />}
          iconPosition="left"
          placeholder="Password"
          type="password"
          className={styles.passwordInput}
          onChange={handleOnChange(setPassword)}
          data-testid="login-password-field"
        />

        <Button
          loading={user.status === UserStatus.LOGGING_IN}
          className={styles.submitButton}
          data-testid="login-submit-button"
        >
          {t('login.signIn')}
        </Button>

        <div className={styles.passwordReset} data-testid="reset-password-link">
          <Link to="/reset-password">{t('login.forgotPassword')}</Link>
        </div>
        <NoAccountInfo />
      </Form>
    </div>
  );
};

export default LoginForm;
