import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import FormLabel from '../../../../shared/form-label/form-label.component';
import { Checkbox } from 'semantic-ui-react';
import { AppStore, FormFieldProperties } from '@xtrf/shared';
import styles from './app-settings-panel.module.scss';
import Multiselect from './components/multiselect.component';
import {
  AppsProperties,
  ConfigPropertiesApp,
  FormFieldType,
} from '@xtrf/shared/lib/store/reducers/workspaces/types';
import { get, isEqual } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

type OwnProps = {};
export type AppsSettingsTabProps = OwnProps;

export type SectionProps = {
  apps: AppsProperties;
};

const AppsSettingsPanel: FC<AppsSettingsTabProps> = () => {
  const { t } = useTranslation(['pages', 'common']);
  const { values, setFieldValue } = useFormikContext<SectionProps>();
  const { activeWorkspaceSettingsSection } = useSelector((store: AppStore) => store.workspaces);
  const { workspaceSettingsConfigSchema } = useSelector((store: AppStore) => store.workspaces);

  const getFormElement = (
    appName: string,
    elementName: string,
    elementSchema: FormFieldProperties | undefined
  ) => {
    const name = `apps.${appName}.${elementName}`;
    if (elementSchema?.formControl === FormFieldType.checkbox) {
      return (
        <Field
          key={name}
          name={name}
          component={Checkbox}
          label={<label className={styles.checkboxLabel}>{elementSchema.description}</label>}
          checked={get(values, name)}
          onClick={() => setFieldValue(name, !get(values, name))}
          data-testid={get(values, name) + '-checkbox'}
        />
      );
    }
    if (elementSchema?.formControl === FormFieldType.multiselect) {
      const options = elementSchema.formOptions
        ? [...elementSchema.formOptions].map((el, index) => {
            return { ...el, key: index };
          })
        : [];

      return (
        <Field
          name={name}
          key={name}
          label={elementSchema.description}
          component={Multiselect}
          options={options}
          disabled={!get(values, `apps.${appName}.${elementSchema.formEnableIfChecked}`)}
        />
      );
    }
  };

  const getFormSection = (appName: string, appConfig: ConfigPropertiesApp | undefined) => {
    if (appConfig) {
      const formFieldsOrder = appConfig.formOrder ? [...appConfig.formOrder].map(String) : [];
      Object.keys(appConfig.properties).forEach(propKey => {
        if (!formFieldsOrder.includes(propKey)) {
          formFieldsOrder.push(propKey);
        }
      });

      return (
        <div
          key={appName}
          className={classNames(
            styles.formContainer,
            isEqual(activeWorkspaceSettingsSection, appName) && styles.formContainerHighlighted
          )}
          id={appName}
        >
          {appConfig.description && (
            <FormLabel label={t(appConfig.description)} dataTestId="app-config-description" />
          )}
          {formFieldsOrder.map(propertyName => {
            return (
              <div
                className={styles.formFieldWrapper}
                key={`apps.${appName}.${propertyName}`}
                id={`apps.${appName}.${propertyName}`}
              >
                {getFormElement(appName, propertyName, appConfig.properties[propertyName])}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return workspaceSettingsConfigSchema && workspaceSettingsConfigSchema.schema.properties ? (
    <React.Fragment>
      {Object.entries(workspaceSettingsConfigSchema.schema.properties.apps.properties).map(
        ([appName, appConfig], index) => {
          return getFormSection(appName, appConfig);
        }
      )}
    </React.Fragment>
  ) : null;
};

export default AppsSettingsPanel;
