"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createChatRoomName = void 0;
const enums_1 = require("../store/enums");
const constants_1 = require("../constants");
const createChatRoomName = ({ chatRoom, isPendingInvitation = false, t, withAdditionalInfo = true, }) => {
    if (isPendingInvitation && withAdditionalInfo) {
        return `${chatRoom.name} ${t('chatRoom.pendingInvitation')}`;
    }
    const isAllProjectMembersRoom = chatRoom.name === constants_1.CHAT_TYPE.ALL_PROJECT_MEMBERS;
    if (isAllProjectMembersRoom || chatRoom.name === constants_1.CHAT_TYPE.PROJECT_MANAGERS) {
        return t(`chatRoom.${chatRoom.name}`);
    }
    if (chatRoom.status === enums_1.ChatRoomStatus.Inactive && withAdditionalInfo) {
        if (chatRoom.name.startsWith(t('chatRoom.deleted'))) {
            return `${chatRoom.name}`;
        }
        return `${chatRoom.name} ${t('chatRoom.inactive')}`;
    }
    return chatRoom.name;
};
exports.createChatRoomName = createChatRoomName;
