import React, { SetStateAction, useState } from 'react';
import { Button, Form, Header, Icon } from 'semantic-ui-react';
import styles from './new-password-form.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, userActions } from '@xtrf/shared';
import { isGlobalChat } from '../../../shared';
import { History } from 'history';
import Message from 'components/shared/message/message';

interface ResetPasswordFormProps {
  toggleForm?: () => void;
  history?: History<unknown>;
  actionCode: string;
}

interface RegisterError {
  message: string;
  code?: string;
}

const NewPasswordForm: React.FC<ResetPasswordFormProps> = ({ toggleForm, history, actionCode }) => {
  const [password, setPassword] = useState('');
  const [showLoader, toggleLoader] = useState(false);
  const [showPassword, togglePassword] = useState(false);
  const [error, setError] = useState<RegisterError | null>(null);
  const user = useSelector((store: AppStore) => store.user);
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'common']);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    toggleLoader(true);
    const callback = () => {
      setTimeout(() => {
        toggleLoader(false);
        history?.push('/');
      }, 3000);
    };
    try {
      await dispatch(userActions.setNewPassword(password, actionCode, callback));
    } catch (e) {
      toggleLoader(false);
      setError(e as SetStateAction<RegisterError | null>);
    }
  };

  return (
    <div data-testid="new-password-form">
      <Message
        hidden={user.error === null && !error}
        type="error"
        testId="reset-password-error-message"
        title={t('common:validationErrors.resetPasswordTitle')}
        content={error?.message}
      />
      <Header as="h3" textAlign="left" className={styles.header}>
        {t('forgotPassword.setNewPassword')}
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          fluid
          icon={
            <Icon
              name={showPassword ? 'eye slash' : 'eye'}
              link
              onClick={() => togglePassword(prev => !prev)}
            />
          }
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          className={styles.passwordInput}
          onChange={e => setPassword(e.target.value)}
          data-testid="register-password-field"
        />
        <Button
          loading={showLoader}
          className={styles.submitButton}
          disabled={!password}
          data-testid="reset-password-submit-button"
        >
          {t('forgotPassword.confirm')}
        </Button>
        {isGlobalChat() && toggleForm ? (
          <div data-testid="reset-password-signin-link">
            <p onClick={toggleForm} className={styles.signIn}>
              {t('login.signIn')}
            </p>
          </div>
        ) : (
          <div className={styles.loginForm} data-testid="reset-password-signin-link">
            <Link to="/login">{t('login.signIn')}</Link>
          </div>
        )}
      </Form>
    </div>
  );
};

export default NewPasswordForm;
