"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserData = void 0;
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const reducer_1 = require("../reducer");
const updateUserData = (userData) => (dispatch, getState, { firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const { user } = getState();
        const email = (_a = user.data) === null || _a === void 0 ? void 0 : _a.email;
        if (!email) {
            throw new Error('email-doesnt-exist');
        }
        const ref = firestore()
            .collection(firebaseCollectionNames_1.USERS_COLLECTION_ID)
            .where('email', '==', email);
        const querySnapshot = yield ref.get();
        if (!querySnapshot.size) {
            throw new Error('document-not-exists');
        }
        const { id } = querySnapshot.docs[0];
        const userRef = firestore()
            .collection(firebaseCollectionNames_1.USERS_COLLECTION_ID)
            .doc(id);
        dispatch((0, reducer_1.updateUserDetailsStarted)());
        yield userRef.update(Object.assign(Object.assign({}, userData), { updatedAt: firestore.FieldValue.serverTimestamp() }));
        dispatch((0, reducer_1.updateUserDetailsSuccess)());
    }
    catch (e) {
        dispatch((0, reducer_1.updateUserDetailsFailed)());
    }
});
exports.updateUserData = updateUserData;
