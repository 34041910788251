"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAccountStatus = exports.UserStatus = void 0;
var UserStatus;
(function (UserStatus) {
    UserStatus["CREATING_NEW_ACCOUNT"] = "CREATING_NEW_ACCOUNT";
    UserStatus["LOGGING_IN"] = "LOGGING_IN";
    UserStatus["IS_LOGGED"] = "IS_LOGGED";
    UserStatus["IS_LOGGED_OUT"] = "IS_LOGGED_OUT";
    UserStatus["LOGGING_OUT"] = "LOGGING_OUT";
    UserStatus["HAS_ERROR"] = "HAS_ERROR";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var DeleteAccountStatus;
(function (DeleteAccountStatus) {
    DeleteAccountStatus["PROCESSING"] = "PROCESSING";
    DeleteAccountStatus["HAS_ERROR"] = "HAS_ERROR";
})(DeleteAccountStatus = exports.DeleteAccountStatus || (exports.DeleteAccountStatus = {}));
