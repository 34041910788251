import React, { memo, useState } from 'react';
import { Header } from 'semantic-ui-react';
import styles from './accounts-panel.module.scss';
import { useTranslation } from 'react-i18next';
import AccountSettingsModal from './modal/account-settings-modal';
import { useDispatch } from 'react-redux';
import { filesActions } from '@xtrf/shared';

export type AccountsPanelProps = {
  username?: string | null;
};

const AccountsPanel: React.FC<AccountsPanelProps> = ({ username }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const { t } = useTranslation('pages');

  return (
    <div className={styles.container}>
      <Header className={styles.header}>Accounts</Header>
      <div className={styles.username} data-testid="workspace-selector-username">
        {username}
      </div>
      <div
        onClick={() => setVisible(prevState => !prevState)}
        className={styles.button}
        data-testid="workspace-selector-settings-button"
      >
        {t('home.accountSettings')}
      </div>
      <AccountSettingsModal
        visible={visible}
        toggleModal={() => {
          setVisible(prevState => !prevState);
          setTimeout(() => {
            dispatch(filesActions.resetAvatarSetting());
          }, 500);
        }}
      />
    </div>
  );
};

export default memo(AccountsPanel);
