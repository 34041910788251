export const TERMS_ROUTE_NAME = 'terms';

export const HOTKEYS = {
  COMMAND_B: 'command+b',
  CTRL_B: 'ctrl+b',
  COMMAND_I: 'command+i',
  CTRL_I: 'ctrl+i',
  COMMAND_SHIFT_X: 'command+shift+x',
  CTRL_SHIFT_X: 'ctrl+shift+x',
  COMMAND_SHIFT_9: 'command+shift+9',
  CTRL_SHIFT_9: 'ctrl+shift+9',
  COMMAND_SHIFT_8: 'command+shift+8',
  CTRL_SHIFT_8: 'ctrl+shift+8',
  COMMAND_SHIFT_7: 'command+shift+7',
  CTRL_SHIFT_7: 'ctrl+shift+7',
  SHIFT_ENTER: 'shift+enter',
} as const;

export const QUESTION_MARK_KEY_CODE = 191;

export const OLD_PRODUCTION_HOSTNAME = 'xtrf-chat-prod.web.app';
export const NEW_PRODUCTION_URL = 'https://chat.xtrf.io';
