import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStore } from '@xtrf/shared';

import styles from './admin-workspace.module.scss';

export const AdminWorkspace = () => {
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);
  const workspaceId = selectedWorkspace?.id;
  const role = selectedWorkspace?.userRole;
  const { t } = useTranslation('pages');

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{t('adminPanel.chat.title')}</h3>
      <p className={styles.text}>{t('adminPanel.chat.infoText')}</p>

      <Link
        className={styles.linkButton}
        target="_blank"
        to={`/${workspaceId}/${role}/adminPanel/members`}
      >
        <i className="external alternate icon"></i> {t('adminPanel.chat.button')}
      </Link>
    </div>
  );
};
