import React from 'react';
import styles from './chat-room.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  AppStore,
  CHAT_TYPE,
  ProjectChatRoom as ChatRoomModel,
  selectors,
  chatListActions,
  ChatRoomStatus,
  helpers,
  roomsActions,
} from '@xtrf/shared';
import { useNumberOfChatRoomWindows } from '../../../hooks/useNumberOfChatRoomWindows';
import { UnreadMessagesIndicator } from '../unread-messages-indicator/unread-messages-indicator.component';
import UserStatusIcon from '../user-status-icon/user-status-icon';
import { parseISO } from 'date-fns';
import { ChatRoomType } from '@xtrf/shared/lib/store/enums/chatRoomType';

export type ChatRoomProps = {
  chatRoom: ChatRoomModel;
  projectName: string;
  projectDisplayId: string;
  vendorEmail?: string;
  status?: ChatRoomStatus;
};

export const ChatRoom: React.FC<ChatRoomProps> = ({
  chatRoom,
  projectName,
  projectDisplayId,
  vendorEmail,
  status,
}) => {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const numberOfChatRoomWindows = useNumberOfChatRoomWindows();
  const { unreadMessages, pendingInvitations, pendingInvitationsRich } = useSelector(
    (store: AppStore) => store.workspaces
  );
  const isAllProjectMembersRoom = chatRoom.name === CHAT_TYPE.ALL_PROJECT_MEMBERS;
  const isPendingInvitation =
    vendorEmail && pendingInvitations.some(email => email === vendorEmail);

  const isOnline = useSelector((store: AppStore) => selectors.getIsOnline(store, { chatRoom }));
  const openChatRooms = useSelector((store: AppStore) => store.chatList.displayedChatList);
  const isRoomOpen = !!openChatRooms.find(openChatRoom => openChatRoom.id === chatRoom.id);

  const invitation = pendingInvitationsRich.find(inv => inv.email === chatRoom.vendorEmail);

  const invitationDate = invitation?.createdAt
    ? helpers.createInvitationDate(parseISO(invitation.createdAt))
    : null;

  const invitationUrl =
    invitation?.url ??
    `${process.env.REACT_APP_SPA_URL ?? ''}/join-workspace?token=${invitation?.token}`;

  const openResendInvitation = (e: React.MouseEvent) => {
    if (invitation?.id && chatRoom.vendorEmail) {
      dispatch(
        roomsActions.setInvitationData({
          id: invitation.id,
          name: chatRoom.name,
          email: chatRoom.vendorEmail,
          invitationUrl,
          invitationDate,
          sent: invitation.sent,
        })
      );
    }
    e.stopPropagation();
  };

  const chatRoomName = helpers.createChatRoomName({ chatRoom, t, withAdditionalInfo: true });

  return (
    <div
      className={styles.container}
      onClick={() =>
        dispatch(
          chatListActions.addChatRoom(
            {
              projectName,
              projectDisplayId,
              isAllProjectMembersRoom,
              projectId: chatRoom.projectId,
              id: chatRoom.id,
              type: ChatRoomType.ProjectChatRoom,
            },
            numberOfChatRoomWindows
          )
        )
      }
    >
      <div className={styles.header}>
        <UserStatusIcon
          isExclamationMark={
            invitation?.sent === undefined || invitation?.sent === true ? false : true
          }
          isAllProjectMembersRoom={isAllProjectMembersRoom}
          isOnline={status === ChatRoomStatus.Inactive ? false : isOnline}
          isPendingInvitation={status === ChatRoomStatus.Inactive ? false : !!isPendingInvitation}
        />
        <div
          className={classNames(
            styles.name,
            isAllProjectMembersRoom && styles.allMembersRoom,
            isRoomOpen && styles.chatRoomOpen
          )}
        >
          {chatRoomName}
          {status !== ChatRoomStatus.Inactive && !!isPendingInvitation && (
            <>
              {' '}
              ({invitation?.sent ? t('chatRoom.pendingInvitation') + ', ' : ''}
              <span
                className={classNames(styles.link, isRoomOpen && styles.chatRoomOpen)}
                onClick={openResendInvitation}
              >
                {invitation?.sent ? t('chatRoom.resendInvitation') : t('chatRoom.notInvited')}
              </span>
              )
            </>
          )}
        </div>
        <UnreadMessagesIndicator count={unreadMessages?.perChatRoom[chatRoom.id]?.length || 0} />
      </div>
    </div>
  );
};
