"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoggedUserData = void 0;
const reducer_1 = require("../reducer");
const getUserDetails_1 = require("./getUserDetails");
const types_1 = require("../types");
const setLoggedUserData = ({ uid, displayName, email, }) => (dispatch, getState, { analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    const { status } = getState().user;
    if (status === types_1.UserStatus.LOGGING_IN) {
        return;
    }
    dispatch((0, reducer_1.logInSuccess)({
        uid,
        email,
        username: displayName,
    }));
    if (email) {
        dispatch((0, getUserDetails_1.getUserDetails)(email));
    }
    analytics().setUserId(uid);
});
exports.setLoggedUserData = setLoggedUserData;
