"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleInvitation = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("../types");
const user_1 = require("../../user");
const enums_1 = require("../../../enums");
const logEvent_1 = require("../../../../helpers/logEvent");
exports.handleInvitation = (0, toolkit_1.createAsyncThunk)(`${types_1.JOIN_TO_WORKSPACE}/handleInvitation`, (token, { dispatch, rejectWithValue, extra: { api, auth, analytics } }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const apiMethods = yield api(auth);
        yield apiMethods.joinToWorkspace(token);
        dispatch(user_1.userActions.setUserPresence(enums_1.UserPresenceStatus.Online));
        (0, logEvent_1.logEvent)(analytics, 'join_to_workspace');
    }
    catch (e) {
        const { response } = e;
        return rejectWithValue(response.status);
    }
}));
