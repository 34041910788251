"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleNotificationsPermission = void 0;
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const reducer_1 = require("../reducer");
const featureFlag_1 = require("../../../../helpers/featureFlag");
const toggleNotificationsPermission = (status) => (dispatch, getState, { auth, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    const user = auth().currentUser;
    dispatch((0, reducer_1.updateUnreadMessagesDocStarted)());
    if (!user || !user.email) {
        throw new Error('user-is-not-logged');
    }
    const { selectedWorkspace } = getState().projects;
    if (!(selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.id)) {
        throw new Error('workspace-is-not-selected');
    }
    const ref = firestore()
        .collection(firebaseCollectionNames_1.UNREAD_MESSAGES_COUNT_COLLECTION_ID)
        .doc(user.email)
        .collection(firebaseCollectionNames_1.WORKSPACE_SUBCOLLECTION_ID)
        .doc((0, featureFlag_1.featureFlag)(selectedWorkspace, 'manyRoles')
        ? `${selectedWorkspace.id}-${selectedWorkspace.userRole}`
        : selectedWorkspace.id);
    try {
        yield ref.update({
            hasActiveNotifications: status,
        });
        dispatch((0, reducer_1.updateUnreadMessagesDocSuccess)());
    }
    catch (_a) {
        dispatch((0, reducer_1.updateUnreadMessagesDocFailed)());
    }
});
exports.toggleNotificationsPermission = toggleNotificationsPermission;
