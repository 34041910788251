import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/performance';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_WEB_PUSH_KEY,
} = process.env;

export const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export async function initializeFirebase() {
  const isProd = process.env.NODE_ENV === 'production';
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    if (REACT_APP_FIREBASE_WEB_PUSH_KEY && isProd && firebase.messaging.isSupported()) {
      firebase.messaging().usePublicVapidKey(REACT_APP_FIREBASE_WEB_PUSH_KEY);
    }

    const isSupported = await firebase.analytics.isSupported();

    firebase.analytics && isSupported && firebase.analytics();

    try {
      await firebase.firestore().enablePersistence({ synchronizeTabs: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to enable persistence', e);
    }
  }

  return {
    auth: firebase.auth(),
    firestore: firebase.firestore(),
    messaging: isProd && firebase.messaging.isSupported() ? firebase.messaging() : null,
    performance: firebase.performance(),
  };
}
