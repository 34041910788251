import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from '../pages/login/login.page';
import ProtectedRoute from 'utils/protected-route/protected-route.component';
import RegisterPage from 'pages/register.page';
import HomePage from '../pages/home/home.page';
import JoinWorkspacePage from '../pages/join-workspace.page';
import TermsPage from 'pages/terms.page';
import ResetPasswordPage from 'pages/reset-password.page';
import AuthorizeJobOffers from 'pages/authorize-job-offers.page';
import { UserRole } from '@xtrf/shared';
import AccountHandling from '../pages/account/account-handling.page';
import { AdminPanelContainer } from 'pages/adminPanel/admin-panel.container';

export type HomePageParams = {
  workspaceId?: string;
  chatRoomId?: string;
  role?: UserRole;
};

const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/account-handling" component={AccountHandling} />
        <Route path="/login/:workspaceId?/:role?/:chatRoomId?" component={LoginPage} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/join-workspace" component={JoinWorkspacePage} />
        <Route path="/terms" component={TermsPage} />
        <Route path="/authorize" component={AuthorizeJobOffers} />
        <Redirect from="/home" to="/" />
        <ProtectedRoute path="/:workspaceId?/:role?/adminPanel" component={AdminPanelContainer} />
        <ProtectedRoute path="/:workspaceId?/:role?/:chatRoomId?" component={HomePage} />
      </Switch>
    </Router>
  );
};

export default Routing;
