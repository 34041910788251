import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { MembersListSortValues } from 'pages/adminPanel/pages/members/members.types';

import { SortColumnHeaderProps } from './sort-column-header.types';
import styles from './sort-column-header.module.scss';

export const SortColumnHeader = <T extends MembersListSortValues>({
  title,
  onClick,
  currentSortValue,
  ascValue,
  descValue,
}: SortColumnHeaderProps<T>) => {
  const isSorted = currentSortValue === ascValue;
  const isSortActive = currentSortValue === ascValue || currentSortValue === descValue;

  const toggleSortValue = () => {
    if (!currentSortValue) onClick(ascValue);
    else if (currentSortValue === ascValue) onClick(descValue);
    else onClick(ascValue);
  };

  return (
    <div
      className={styles.columnHeaderWrapper}
      onClick={toggleSortValue}
      data-testid="sortColumnHeader"
    >
      {title}
      {isSorted ? (
        <ArrowDownOutlined
          className={classNames(styles.sortArrow, {
            [styles.arrowVisible]: isSortActive,
          })}
          data-testid="arrowDownIcon"
        />
      ) : (
        <ArrowUpOutlined
          className={classNames(styles.sortArrow, {
            [styles.arrowVisible]: isSortActive,
          })}
          data-testid="arrowUpIcon"
        />
      )}
    </div>
  );
};
