import React, { FC } from 'react';
import styles from '../../workspace-settings.module.scss';
import { Field, useFormikContext } from 'formik';
import FormLabel from '../../../../shared/form-label/form-label.component';
import { Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export type SectionProps = {
  notifications: boolean;
};

type OwnProps = {};
export type WorkspaceSettingsTabProps = OwnProps;

const WorkspaceSettingsPanel: FC<WorkspaceSettingsTabProps> = () => {
  const { t } = useTranslation(['pages', 'common']);
  const { values, setFieldValue } = useFormikContext<SectionProps>();

  return (
    <div className={styles.wrapper}>
      <FormLabel label="settings.notifications" dataTestId="settings-notifications" />
      <Field
        name="notifications"
        component={Checkbox}
        checked={values.notifications}
        onClick={() => setFieldValue('notifications', !values.notifications)}
        label={t('settings.unreadMessages')}
        data-testid="notifications-checkbox"
      />
    </div>
  );
};

export default WorkspaceSettingsPanel;
