import React, { FunctionComponent, useCallback } from 'react';
import { Header, Popup } from 'semantic-ui-react';
import styles from './app-chat-window-header.module.scss';
import { UnreadMessagesIndicator } from 'components/rooms/unread-messages-indicator/unread-messages-indicator.component';
import { AppChatRoom, CHAT_APPS, DisplayedAppChat } from '@xtrf/shared';
import CloseIcon from 'shared/icons/close-icon';
import MinimizeIcon from 'shared/icons/minimize-icon';
import { useTranslation } from 'react-i18next';

type OwnProps = {
  chatRoom: AppChatRoom;
  setIsMinimalized: (IsMinimalized: boolean) => void;
  handleClose: () => void;
  unreadMessages: number;
  displayedProjectChatRoom: DisplayedAppChat;
};

type ChatWindowHeaderProps = OwnProps;

const AppChatWindowHeader: FunctionComponent<ChatWindowHeaderProps> = ({
  chatRoom,
  handleClose,
  setIsMinimalized,
  unreadMessages: unreadMessagesCount,
  displayedProjectChatRoom,
}) => {
  const { t } = useTranslation('pages');
  const handleIconClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsMinimalized(!displayedProjectChatRoom.minimized);
    },
    [displayedProjectChatRoom.minimized, setIsMinimalized]
  );

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();
  };

  const getChatRoomName = () => {
    if (chatRoom.appId === CHAT_APPS['xtrf-joboffers'].id) {
      return chatRoom.name === CHAT_APPS[chatRoom.appId].name
        ? chatRoom.name
        : t('chatRoom.jobOfferCustomChatRoomHeaderTitle', { name: chatRoom.name });
    }

    return chatRoom.name;
  };

  const renderChatTitle = () => {
    return (
      <div data-testid="chat-window-header-title" className={styles.roomName}>
        <Popup
          content={getChatRoomName()}
          trigger={
            <div className={styles.projectDisplayId} data-testid="project-display-id">
              {chatRoom.icon && (
                <img
                  src={`data:image/svg+xml;utf8,${chatRoom.icon}`}
                  alt=""
                  className={styles.appIcon}
                />
              )}
              {getChatRoomName()}
              <div className={styles.unreadMessagesWrapper}>
                <UnreadMessagesIndicator count={unreadMessagesCount} />
              </div>
            </div>
          }
          mouseEnterDelay={800}
          on="hover"
          className={'xtrf-chat-popup'}
          position="top center"
          data-testid="project-display-id-popup"
        />
      </div>
    );
  };

  return (
    <div className={styles.titleWrapper} data-testid="chat-window-header" onClick={handleIconClick}>
      <Header as="h4" className={styles.titleHeader}>
        {renderChatTitle()}
      </Header>
      <MinimizeIcon
        data-testid="minimize-icon"
        onClick={handleIconClick}
        name={
          displayedProjectChatRoom.minimized ? 'window maximize outline' : 'window minimize outline'
        }
        className={styles.minimizeIcon}
      />
      <CloseIcon
        data-testid="close-icon"
        onClick={handleCloseClick}
        name="close"
        className={styles.chatWindowIcon}
      />
    </div>
  );
};

export default AppChatWindowHeader;
