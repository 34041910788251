import React from 'react';

import { PageContainerProps } from './page-container.types';
import styles from './page-container.module.scss';

export const PageContainer = ({ children, title, titleExtra }: PageContainerProps) => {
  return (
    <section className={styles.container}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>{title}</h2>
        {titleExtra && <div>{titleExtra}</div>}
      </div>

      {children}
    </section>
  );
};
