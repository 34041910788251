"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOutReadMessagesPerWorkspace = void 0;
const flatMap_1 = require("../../helpers/flatMap");
// Unread message received for one of the open chats should not increase unread messages' counter,
const filterOutReadMessagesPerWorkspace = ({ roomsData, unreadMessages, messagesToMark, workspaceId, }) => {
    if (workspaceId && Object.keys(unreadMessages) && unreadMessages[workspaceId]) {
        const filteredUnread = Object.assign({}, unreadMessages);
        if (Object.keys(unreadMessages) && unreadMessages[workspaceId]) {
            let messagesIdsToExclude = (0, flatMap_1.flatMap)(Object.values(roomsData), roomMessages => Array.isArray(roomMessages) ? roomMessages.map(message => message.id) : []);
            if (Object.keys(messagesToMark)) {
                messagesIdsToExclude = messagesIdsToExclude.concat((0, flatMap_1.flatMap)(Object.values(messagesToMark), messages => messages.map(id => id)));
            }
            filteredUnread[workspaceId] = unreadMessages[workspaceId].filter(id => !messagesIdsToExclude.includes(id));
        }
        return filteredUnread;
    }
    else {
        return unreadMessages;
    }
};
exports.filterOutReadMessagesPerWorkspace = filterOutReadMessagesPerWorkspace;
