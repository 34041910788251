import React from 'react';
import { Transition } from 'react-transition-group';

interface FadeProps {
  show: boolean;
  styles?: { [key: string]: string | number };
  onEnd?: () => void;
  onItemClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
} as { [key: string]: { [key: string]: string | number } };

export const Fade: React.FC<FadeProps> = ({ show, children, styles, onEnd, onItemClick }) => (
  <Transition
    in={show}
    timeout={{ enter: 0, exit: 300 }}
    onExited={onEnd}
    mountOnEnter={true}
    unmountOnExit={true}
  >
    {state => (
      <div
        style={{
          ...styles,
          ...defaultStyle,
          ...transitionStyles[state],
        }}
        onClick={onItemClick}
      >
        {children}
      </div>
    )}
  </Transition>
);
