"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromProjects = void 0;
const reducer_1 = require("../reducer");
const workspaces_1 = require("../../workspaces");
const unsubscribeFromProjects = () => (dispatch, _, { firestoreSubscriptions }) => {
    if (firestoreSubscriptions.projectsListener) {
        firestoreSubscriptions.projectsListener();
    }
    if (firestoreSubscriptions.workspaceListener) {
        firestoreSubscriptions.workspaceListener();
    }
    dispatch(workspaces_1.workspacesActions.unsubscribeFromPresence());
    dispatch(workspaces_1.workspacesActions.unsubscribeFromUnreadMessages());
    dispatch((0, reducer_1.unsubscribeFromProjects)());
};
exports.unsubscribeFromProjects = unsubscribeFromProjects;
