/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { canUseDOM } from 'exenv';

type Props = {
  onOutClick?: (e: any) => void;
  isGlobalChat?: boolean;
};

class Portal extends React.Component<Props> {
  node!: HTMLDivElement | Element;
  handleOutClick!: (e: any) => void;
  handleRootRef!: (root: any) => void;
  root: any = null;

  constructor(props: Props) {
    super(props);

    if (canUseDOM) {
      this.node = document.createElement('div');
      if (this.props.isGlobalChat) {
        // is necessary to apply styles for global chat
        const divNode = document.createElement('div');
        divNode.innerHTML =
          '<style>' +
          '.dropdownItem {\n' +
          '  position: relative;\n' +
          '  cursor: pointer;\n' +
          '  display: block;\n' +
          '  height: auto;\n' +
          '  text-align: left;\n' +
          '  border: none;\n' +
          '  line-height: 1em;\n' +
          '  color: rgba(0, 0, 0, 0.87);\n' +
          '  padding: 0.78571429rem 1.14285714rem !important;\n' +
          '  font-size: 1rem;\n' +
          '  text-transform: none;\n' +
          '  font-weight: 400;\n' +
          '  box-shadow: none; }' +
          '.dropdownItem:hover {\n' +
          '    background: rgba(0, 0, 0, 0.05);\n' +
          '    color: rgba(0, 0, 0, 0.95);\n' +
          '    z-index: 13;\n' +
          '  }' +
          '.smallDropdownItem {\n' +
          '  font-size: 13px;\n' +
          '  padding: 5px 10px !important;\n' +
          '  display: flex;\n' +
          '  align-items: center;\n' +
          '}' +
          '.disabledItem {\n' +
          '  color: #999 !important;\n' +
          '}';
        '</style>';
        this.node.appendChild(divNode);
      }
      this.root = null;
      this.handleRootRef = root => {
        this.root = root;
      };

      this.handleOutClick = e => {
        const { onOutClick } = this.props;
        if (typeof onOutClick === 'function') {
          if (this.root && !this.root.contains(e.target)) {
            onOutClick(e);
          }
          if (!this.root) {
            onOutClick(e);
          }
        }
      };

      document.addEventListener('click', this.handleOutClick, true);
    }
  }
  componentDidMount() {
    if (canUseDOM && this.node) {
      document.body.appendChild(this.node);
    }
  }

  componentWillUnmount() {
    if (canUseDOM && this.node) {
      // @ts-ignore
      document.removeEventListener('click', this.handleOutClick, true);
      document.body.removeChild(this.node);
    }
  }

  render() {
    const { onOutClick, isGlobalChat, ...props } = this.props;
    return ReactDOM.createPortal(<div {...props} ref={this.handleRootRef} />, this.node);
  }
}

export default Portal;
/* eslint-enable */
