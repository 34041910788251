import React from 'react';
import { OnlineIcon, OfflineIcon } from '../../../shared/icons';

import styles from './online-indicator.module.scss';

type OnlineIndicatorProps = {
  isOnline: boolean;
};

export const OnlineIndicator: React.FC<OnlineIndicatorProps> = ({ isOnline }) =>
  isOnline ? (
    <OnlineIcon className={styles.chatRoomIcon} data-testid="online-icon" />
  ) : (
    <OfflineIcon className={styles.chatRoomIcon} data-testid="offline-icon" />
  );
