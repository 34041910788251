"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyChatList = exports.newChatListCreated = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    displayedChatList: [],
};
const CHAT_LIST = 'ChatList';
const chatListSlice = (0, toolkit_1.createSlice)({
    name: CHAT_LIST,
    initialState,
    reducers: {
        newChatListCreated(state, { payload }) {
            return Object.assign(Object.assign({}, state), { displayedChatList: payload });
        },
        emptyChatList(state) {
            return Object.assign(Object.assign({}, state), { displayedChatList: [] });
        },
    },
});
_a = chatListSlice.actions, exports.newChatListCreated = _a.newChatListCreated, exports.emptyChatList = _a.emptyChatList;
exports.default = chatListSlice.reducer;
