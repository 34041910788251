import React from 'react';
import classNames from 'classnames';

import styles from './switch-button.module.scss';
import { SwitchButtonProps } from './switch-button.types';

export const SwitchButton = ({ onChange, checked, disabled }: SwitchButtonProps) => {
  return (
    <div className={classNames(styles.button, styles.switchButton)}>
      <input
        type="checkbox"
        className={styles.checkbox}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <div className={styles.switch}></div>
    </div>
  );
};
