"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorLabelForUpdatingMessageIssue = void 0;
const __1 = require("..");
const canRemoveUserMessage_1 = require("./canRemoveUserMessage");
const getErrorLabelForUpdatingMessageIssue = (message) => {
    return (0, canRemoveUserMessage_1.canRemoveUserMessage)(message.createdAt)
        ? { label: __1.VALIDATION_ERROR_LABELS.UNKNOWN_ERROR_WHEN_UPDATING_MESSAGE }
        : {
            label: __1.VALIDATION_ERROR_LABELS.TIME_TO_DELETE_EXCEEDED,
            interpolation: { limit: __1.TIME_LIMIT_TO_UPDATE_MESSAGE_IN_MINUTES },
        };
};
exports.getErrorLabelForUpdatingMessageIssue = getErrorLabelForUpdatingMessageIssue;
