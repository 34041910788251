import React, { FC } from 'react';
import {
  ProjectsReducer,
  ProjectsStatus,
  ResendInvitationData,
  roomsActions,
  selectors,
} from '@xtrf/shared';
import Message from '../../../../shared/message/message';
import styles from './projects-tab.module.scss';
import { isGlobalChat } from '../../../../../shared';
import ResendInvitation from '../../../resend-invitation/resend-invitation.component';
import { Project } from '../../../project/project.component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type OwnProps = {
  projects: ProjectsReducer | null;
  invitationData: ResendInvitationData | null;
};

export type ProjectsTabProps = OwnProps;

const ProjectsTab: FC<ProjectsTabProps> = ({ projects, invitationData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');
  const sortedProjects = useSelector(selectors.getSortedProjects);

  if (projects?.status === ProjectsStatus.HAS_ERROR) {
    return (
      <Message
        title={t('projects.errorWhileFetching')}
        testId="fetching-error-message"
        className={styles.error}
        type="error"
      />
    );
  }

  if (!projects?.data.length) {
    return (
      <Message
        title={t('projects.emptyContent')}
        testId="empty-content-message"
        className={styles.error}
        type="error"
      />
    );
  }

  if (isGlobalChat() && !!invitationData) {
    return (
      <div className={styles.resendInvitationContainer}>
        <ResendInvitation
          toggleModal={() => dispatch(roomsActions.invitationDataReset())}
          invitationData={invitationData}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {sortedProjects.map((project, i) => (
        <Project
          key={project.id}
          {...project}
          chatRoomsStatus={projects?.chatRoomsStatus}
          index={i}
        />
      ))}
    </div>
  );
};

export default ProjectsTab;
