import React, { memo, useContext, useEffect, useState } from 'react';
import { Button, Header, Modal as SemanticModal } from 'semantic-ui-react';
import { UnreadMessagesIndicator } from '../unread-messages-indicator/unread-messages-indicator.component';
import { FeaturesPanel } from '../features-panel/features-panel.component';
import styles from './workspace-room.module.scss';
import {
  AppStore,
  LeavingWorkspaceStatus,
  ProjectsReducer,
  ProjectsStatus,
  ProjectTab,
  roomsActions,
  selectors,
  UserRole,
  workspacesActions,
  WorkspacesStatus,
} from '@xtrf/shared';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ChatIcon } from '../../../shared/icons';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';
import { LeaveWorkspaceConfirmContext } from '../../../providers/leave-workspace-confirm.provider';
import WorkspaceSettings, {
  WorkspaceSettingsType,
} from '../../workspaces/workspace-settings/workspace-settings';
import { isGlobalChat } from '../../../shared';
import ResendInvitation from '../resend-invitation/resend-invitation.component';
import TransitionedModal from 'components/shared/transitioned-modal/transitioned-modal.component';
import CloseIcon from 'shared/icons/close-icon';
import ToggleTab from 'components/shared/toggle-tab/toggle-tab.component';
import AppsTab from './tabs/apps/apps-tab';
import ProjectsTab from './tabs/projects/projects-tab';
import JobOffersTab from './tabs/joboffers/joboffers-tab';
import TestModeLabel from 'components/shared/test-mode-label/test-mode-label';
import { AdminWorkspace } from 'components/workspaces/admin-workspace/admin-workspace';

interface WorkspaceRoomsProps {
  name: string;
  unreadMessagesCount: number;
  projects: ProjectsReducer | null;
  workspacesStatus: WorkspacesStatus | null;
  testMode: boolean;
}

const WorkspaceRooms: React.FC<WorkspaceRoomsProps> = ({
  name,
  unreadMessagesCount,
  projects,
  workspacesStatus,
  testMode,
}) => {
  const { t } = useTranslation('pages');
  const activeTab = useSelector((store: AppStore) => store.projects.activeTab);
  const invitationData = useSelector((store: AppStore) => store.rooms.invitationData);
  const unreadMessages = useSelector((store: AppStore) => store.workspaces.unreadMessages);
  const { unreadMessagesPerProject, unreadMessagesPerApp } = useSelector(
    selectors.getTotalUnreadMessagesForWorkspace,
    shallowEqual
  );
  const { workspaceSettingsConfigSchema } = useSelector((store: AppStore) => store.workspaces);
  const dispatch = useDispatch();
  const [projectsVisible, setProjectsVisible] = useState(true);
  const [joboffersVisible, setJobOffersVisbile] = useState(true);
  const [appsVisible, setAppsVisible] = useState(false);

  const {
    confirmLeaveWorkspaceVisible,
    setConfirmLeaveWorkspaceVisible,
    workspaceRole,
  } = useContext(LeaveWorkspaceConfirmContext);
  const hasActiveNotifications = unreadMessages?.hasActiveNotifications ?? false;
  const [acceptNotifications, setAcceptNotifications] = useState(hasActiveNotifications);
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);
  const leavingWorkspaceStatus = useSelector(
    (store: AppStore) => store.workspaces.leavingWorkspaceStatus
  );
  const chats = useSelector(selectors.getJobOfferChats);
  const perChatRoom = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom,
    shallowEqual
  );

  const countUnreadJobOffersMessages = () => {
    if (!perChatRoom) return;

    const unreadPerJobOffersProject = Object.keys(chats).map(chatroomKey => {
      const unreadPerChatRoom = chats[chatroomKey].map(chatRoom => {
        return perChatRoom[chatRoom.id] ? perChatRoom[chatRoom.id].length : 0;
      });

      return unreadPerChatRoom.reduce((acc, unreadCount) => acc + unreadCount, 0);
    });

    return unreadPerJobOffersProject.reduce((acc, unreadCount) => acc + unreadCount, 0);
  };

  useEffect(() => {
    if (acceptNotifications !== hasActiveNotifications) {
      setAcceptNotifications(hasActiveNotifications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, hasActiveNotifications, selectedWorkspace]);

  const renderConfirmLeaveWorkspace = () => {
    const handleLeaveWorkspace = () => {
      if (selectedWorkspace?.id) {
        workspaceRole &&
          dispatch(workspacesActions.leaveWorkspace(selectedWorkspace.id, workspaceRole));
        setConfirmLeaveWorkspaceVisible(false);
      }
    };

    return (
      <div className={styles.confirmWrapper}>
        <Header>{t('workspaces.leaveWorkspaceHeader')}</Header>
        <div className={styles.confirmContent}>{t('workspaces.leaveWorkspace')}</div>
        <div className={styles.confirmButtonsWrapper}>
          <Button
            onClick={() => handleLeaveWorkspace()}
            className={styles.leaveButton}
            disabled={leavingWorkspaceStatus === LeavingWorkspaceStatus.PROCESSING}
            data-testid="confirm-leave-button"
          >
            {t('buttons.leave')}
          </Button>
          <Button
            onClick={() => setConfirmLeaveWorkspaceVisible(false)}
            className={styles.cancelButton}
            disabled={leavingWorkspaceStatus === LeavingWorkspaceStatus.PROCESSING}
            data-testid="cancel-leave-button"
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    );
  };

  const toggleTabContent = [
    {
      active: projectsVisible,
      available: true,
      onClick: () => setProjectsVisible(prev => !prev),
      title: t('tabToggle.projectsAndQuotes'),
      content: <ProjectsTab invitationData={invitationData} projects={projects} />,
      unreadMessages: Object.values(unreadMessagesPerProject).reduce(
        (prev, current) => prev + current,
        0
      ),
    },
    {
      active: joboffersVisible,
      available: !!selectedWorkspace?.apps?.find(a => a.id === 'xtrf-joboffers'),
      onClick: () => setJobOffersVisbile(prev => !prev),
      title: t('tabToggle.joboffers'),
      content: <JobOffersTab projects={null} activeTab={activeTab} />,
      unreadMessages: countUnreadJobOffersMessages(),
    },
    {
      active: appsVisible,
      available: selectedWorkspace?.apps && selectedWorkspace?.apps.length,
      onClick: () => setAppsVisible(prev => !prev),
      title: t('tabToggle.apps'),
      content: <AppsTab />,
      unreadMessages: Object.values(unreadMessagesPerApp).reduce(
        (prev, current) => prev + current,
        0
      ),
    },
  ];

  const whenWorkspaceDataIsReady =
    workspacesStatus === WorkspacesStatus.FETCHED && projects?.selectedWorkspace !== null;

  const renderHeader = () => {
    if (name !== '') {
      return (
        <>
          <ChatIcon className={styles.chatIcon} />
          <Header.Content className={styles.headerContent} data-testid="sidepanel-workspace-name">
            {name}
          </Header.Content>
        </>
      );
    } else {
      return <LoaderDotted />;
    }
  };

  const renderWorkspaceSettings = () => {
    if (isGlobalChat()) {
      if (confirmLeaveWorkspaceVisible) {
        return renderConfirmLeaveWorkspace();
      }

      return (
        <WorkspaceSettings
          sections={[
            WorkspaceSettingsType.Workspace,
            WorkspaceSettingsType.Account,
            WorkspaceSettingsType.Apps,
          ]}
        />
      );
    }

    return (
      <WorkspaceSettings sections={[WorkspaceSettingsType.Workspace, WorkspaceSettingsType.Apps]} />
    );
  };

  const renderContent = () => {
    if (name === '') {
      return null;
    }

    if (projects?.status === ProjectsStatus.SUBSCRIBING || !whenWorkspaceDataIsReady) {
      return <LoaderDotted />;
    }
    if (activeTab === ProjectTab.Settings) {
      return workspaceSettingsConfigSchema ? renderWorkspaceSettings() : <LoaderDotted />;
    }

    return (
      <>
        {toggleTabContent.map(
          (tab, index) =>
            !!tab.available && (
              <ToggleTab
                active={tab.active}
                onClick={tab.onClick}
                title={tab.title}
                key={index}
                totalUnreadMessages={tab.unreadMessages}
              >
                {tab.content}
              </ToggleTab>
            )
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header as="h2" className={styles.headerTag}>
          {renderHeader()}
        </Header>
        <div className={styles.unreadMessageContainer}>
          <UnreadMessagesIndicator count={unreadMessagesCount} big />
        </div>
      </div>
      {testMode && <TestModeLabel />}
      {selectedWorkspace?.userRole === UserRole.Admin ? (
        <AdminWorkspace />
      ) : (
        <>
          <FeaturesPanel testMode={testMode} />
          <div
            className={
              activeTab === ProjectTab.Settings
                ? isGlobalChat()
                  ? styles.workspaceSettingsWrapperReduced
                  : styles.workspaceSettingsWrapper
                : styles.projectListsWrapper
            }
          >
            <TransitionedModal
              visible={!!invitationData && !isGlobalChat()}
              toggleModal={() => dispatch(roomsActions.invitationDataReset())}
              testId="resend-invitation-modal"
            >
              <Header className={styles.modalHeader}>
                <div data-testid="modal-title">{selectedWorkspace?.name}</div>
                <CloseIcon
                  onClick={() => {
                    dispatch(workspacesActions.resetResendInvitationStatus());
                    document.body.classList.remove('modal-fade-in');
                    dispatch(roomsActions.invitationDataReset());
                  }}
                  className={styles.closeIcon}
                  data-testid="close-icon"
                />
              </Header>
              <SemanticModal.Content>
                <ResendInvitation
                  toggleModal={() => dispatch(roomsActions.invitationDataReset())}
                  invitationData={invitationData}
                />
              </SemanticModal.Content>
            </TransitionedModal>
            {renderContent()}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(WorkspaceRooms);
