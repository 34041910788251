import React, { FunctionComponent, useCallback } from 'react';
import { Header, Popup } from 'semantic-ui-react';
import styles from './chat-window-header.module.scss';
import { UnreadMessagesIndicator } from 'components/rooms/unread-messages-indicator/unread-messages-indicator.component';
import { useSelector } from 'react-redux';
import {
  AppStore,
  ChatRoomStatus,
  DisplayedProjectChat,
  helpers,
  ProjectChatRoom,
  selectors,
} from '@xtrf/shared';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CloseIcon from 'shared/icons/close-icon';
import MinimizeIcon from 'shared/icons/minimize-icon';
import UserStatusIcon from '../../../rooms/user-status-icon/user-status-icon';

type OwnProps = {
  chatRoom: ProjectChatRoom;
  setIsMinimalized: (IsMinimalized: boolean) => void;
  handleClose: () => void;
  unreadMessages: number;
  isPendingInvitation?: boolean;
  isExclamationMark?: boolean;
  displayedProjectChatRoom: DisplayedProjectChat;
};

type ChatWindowHeaderProps = OwnProps;

const ChatWindowHeader: FunctionComponent<ChatWindowHeaderProps> = ({
  chatRoom,
  handleClose,
  setIsMinimalized,
  isPendingInvitation = false,
  isExclamationMark,
  unreadMessages: unreadMessagesCount,
  displayedProjectChatRoom,
}) => {
  const { t } = useTranslation('pages');
  const { projectDisplayId, projectIdIsLong } = helpers.createProjectDisplayIdentifier(
    displayedProjectChatRoom.projectDisplayId
  );

  const handleIconClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsMinimalized(!displayedProjectChatRoom.minimized);
    },
    [displayedProjectChatRoom.minimized, setIsMinimalized]
  );

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();
  };

  const getName = (name: string) => {
    const noLanguagesKey = 'noLanguages';

    if (name === noLanguagesKey) {
      return `(${t(`projects.${noLanguagesKey}`)})`;
    }

    return name;
  };

  const renderChatTitle = () => {
    const chatTitleStyle = projectIdIsLong
      ? styles.chatTitleContainerColumn
      : styles.chatTitleContainer;

    return (
      <div className={chatTitleStyle} data-testid="chat-window-header-title">
        <Popup
          content={displayedProjectChatRoom.projectDisplayId}
          trigger={
            <div className={styles.projectDisplayId} data-testid="project-display-id">
              {projectDisplayId}:
            </div>
          }
          mouseEnterDelay={800}
          on="hover"
          className={'xtrf-chat-popup'}
          position="top center"
          data-testid="project-display-id-popup"
        />
        <Popup
          content={getName(displayedProjectChatRoom.projectName)}
          trigger={
            <div className={styles.projectName} data-testid="project-name">
              {getName(displayedProjectChatRoom.projectName)}
            </div>
          }
          mouseEnterDelay={800}
          on="hover"
          className={classNames('xtrf-chat-popup')}
          position="top center"
          data-testid="project-name-popup"
        />
      </div>
    );
  };

  const isOnline = useSelector((store: AppStore) => selectors.getIsOnline(store, { chatRoom }));
  const roomName = helpers.createChatRoomName({ chatRoom, t, withAdditionalInfo: true });

  return (
    <div className={styles.titleWrapper} data-testid="chat-window-header" onClick={handleIconClick}>
      <Header as="h4" className={styles.titleHeader}>
        {renderChatTitle()}
        <Header.Subheader className={styles.subHeader}>
          <UserStatusIcon
            isExclamationMark={isExclamationMark}
            isOnline={chatRoom.status === ChatRoomStatus.Inactive ? false : isOnline}
            isAllProjectMembersRoom={displayedProjectChatRoom.isAllProjectMembersRoom}
            isPendingInvitation={isPendingInvitation}
          />
          <div className={styles.name} data-testid="chatroom-name">
            {roomName}
          </div>
          <div className={styles.unreadMessagesWrapper}>
            <UnreadMessagesIndicator count={unreadMessagesCount} />
          </div>
        </Header.Subheader>
      </Header>
      <MinimizeIcon
        data-testid="minimize-icon"
        onClick={handleIconClick}
        name={
          displayedProjectChatRoom.minimized ? 'window maximize outline' : 'window minimize outline'
        }
        className={styles.minimizeIcon}
      />
      <CloseIcon
        data-testid="close-icon"
        onClick={handleCloseClick}
        name="close"
        className={styles.chatWindowIcon}
      />
    </div>
  );
};

export default ChatWindowHeader;
