import classNames from 'classnames';
import React from 'react';
import { Button as SemanticButton, ButtonProps } from 'semantic-ui-react';

import styles from './button.module.scss';

export const Button = ({ className, ...props }: ButtonProps) => {
  if (props.primary) {
    return (
      <SemanticButton
        {...props}
        className={classNames(
          props.disabled ? styles.disabledButton : styles.blueButton,
          className
        )}
      />
    );
  } else {
    return <SemanticButton {...props} className={className} />;
  }
};
