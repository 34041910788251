import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './test-mode-label.module.scss';

const TestModeLabel: React.FC = () => {
  const { t } = useTranslation('pages');

  return (
    <div className={styles.testModeLabelWrapper}>
      <p className={styles.testModeLabelBoldText}>{t('workspaces.testModeTitle')}</p>
      <p>{t('workspaces.testModeText')}</p>
    </div>
  );
};

export default TestModeLabel;
