"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromUserDetails = exports.updateUsernameFailed = exports.updateUsernameSuccess = exports.updateUsernameStarted = exports.setAppVersion = exports.deleteAccountFailed = exports.deleteAccountSuccess = exports.deleteAccountStarted = exports.signUpSuccess = exports.signUpStarted = exports.signUpFailed = exports.resetUserStatus = exports.resetError = exports.resetPasswordSuccess = exports.resetPasswordStarted = exports.resetPasswordFailed = exports.updateUserDetailsSuccess = exports.updateUserDetailsStarted = exports.updateUserDetailsFailed = exports.getUserDetailsSuccess = exports.getUserDetailsStarted = exports.getUserDetailsFailed = exports.logOutSuccess = exports.logOutFailed = exports.logOutStarted = exports.logInSuccess = exports.logInStarted = exports.logInFailed = exports.USER_REDUCER = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("./types");
const enums_1 = require("../../enums");
const initialState = {
    data: null,
    status: null,
    error: null,
    detailsStatus: null,
    passwordStatus: null,
    deleteAccountStatus: null,
    appVersion: null,
    usernameStatus: null,
};
exports.USER_REDUCER = 'User';
const userSlice = (0, toolkit_1.createSlice)({
    name: exports.USER_REDUCER,
    initialState,
    reducers: {
        signUpStarted(state) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.CREATING_NEW_ACCOUNT, error: null });
        },
        signUpSuccess(state) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.LOGGING_IN });
        },
        signUpFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.HAS_ERROR, error: payload });
        },
        logInStarted(state) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.LOGGING_IN, error: null });
        },
        logInSuccess(state, { payload: { uid, email } }) {
            var _a;
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.IS_LOGGED, data: Object.assign(Object.assign({}, ((_a = state.data) !== null && _a !== void 0 ? _a : {})), { uid,
                    email }) });
        },
        logInFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.HAS_ERROR, error: payload });
        },
        logOutStarted(state) {
            return Object.assign(Object.assign({}, state), { error: null, status: types_1.UserStatus.LOGGING_OUT });
        },
        logOutSuccess(state) {
            return Object.assign(Object.assign({}, state), { data: null, status: types_1.UserStatus.IS_LOGGED_OUT, detailsStatus: null });
        },
        logOutFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { status: types_1.UserStatus.HAS_ERROR, error: payload });
        },
        getUserDetailsStarted(state) {
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.FETCHING });
        },
        getUserDetailsSuccess(state, { payload }) {
            var _a, _b, _c;
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.SUCCESS, data: {
                    email: ((_a = state.data) === null || _a === void 0 ? void 0 : _a.email) || payload.email,
                    uid: ((_b = state.data) === null || _b === void 0 ? void 0 : _b.uid) || '',
                    firstName: payload.firstName || '',
                    lastName: payload.lastName || '',
                    username: payload.username || `${payload.firstName} ${(_c = payload.lastName) !== null && _c !== void 0 ? _c : ''}`,
                    newsletterActive: payload.newsletterActive,
                    userId: payload.userId,
                    avatarUrl: payload.avatarUrl,
                } });
        },
        getUserDetailsFailed(state) {
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.FAILED });
        },
        updateUserDetailsStarted(state) {
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.FETCHING });
        },
        updateUserDetailsSuccess(state) {
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.SUCCESS });
        },
        updateUserDetailsFailed(state) {
            return Object.assign(Object.assign({}, state), { detailsStatus: enums_1.RequestStatus.FAILED });
        },
        resetPasswordStarted(state) {
            return Object.assign(Object.assign({}, state), { passwordStatus: enums_1.RequestStatus.FETCHING, error: null });
        },
        resetPasswordSuccess(state) {
            return Object.assign(Object.assign({}, state), { passwordStatus: enums_1.RequestStatus.SUCCESS, error: null });
        },
        resetPasswordFailed(state, { payload }) {
            return Object.assign(Object.assign({}, state), { passwordStatus: enums_1.RequestStatus.FAILED, error: payload });
        },
        resetError(state) {
            return Object.assign(Object.assign({}, state), { error: null, passwordStatus: null });
        },
        resetUserStatus(state) {
            return Object.assign(Object.assign({}, state), { status: null });
        },
        deleteAccountStarted(state) {
            state.deleteAccountStatus = types_1.DeleteAccountStatus.PROCESSING;
        },
        deleteAccountSuccess(state) {
            state.deleteAccountStatus = null;
        },
        deleteAccountFailed(state) {
            state.deleteAccountStatus = types_1.DeleteAccountStatus.HAS_ERROR;
        },
        setAppVersion(state, { payload }) {
            state.appVersion = payload;
        },
        updateUsernameStarted(state) {
            state.usernameStatus = enums_1.RequestStatus.FETCHING;
        },
        updateUsernameSuccess(state) {
            state.usernameStatus = enums_1.RequestStatus.SUCCESS;
        },
        updateUsernameFailed(state) {
            state.usernameStatus = enums_1.RequestStatus.FAILED;
        },
        unsubscribeFromUserDetails(state) {
            state.detailsStatus = null;
            state.data = null;
        },
    },
});
_a = userSlice.actions, exports.logInFailed = _a.logInFailed, exports.logInStarted = _a.logInStarted, exports.logInSuccess = _a.logInSuccess, exports.logOutStarted = _a.logOutStarted, exports.logOutFailed = _a.logOutFailed, exports.logOutSuccess = _a.logOutSuccess, exports.getUserDetailsFailed = _a.getUserDetailsFailed, exports.getUserDetailsStarted = _a.getUserDetailsStarted, exports.getUserDetailsSuccess = _a.getUserDetailsSuccess, exports.updateUserDetailsFailed = _a.updateUserDetailsFailed, exports.updateUserDetailsStarted = _a.updateUserDetailsStarted, exports.updateUserDetailsSuccess = _a.updateUserDetailsSuccess, exports.resetPasswordFailed = _a.resetPasswordFailed, exports.resetPasswordStarted = _a.resetPasswordStarted, exports.resetPasswordSuccess = _a.resetPasswordSuccess, exports.resetError = _a.resetError, exports.resetUserStatus = _a.resetUserStatus, exports.signUpFailed = _a.signUpFailed, exports.signUpStarted = _a.signUpStarted, exports.signUpSuccess = _a.signUpSuccess, exports.deleteAccountStarted = _a.deleteAccountStarted, exports.deleteAccountSuccess = _a.deleteAccountSuccess, exports.deleteAccountFailed = _a.deleteAccountFailed, exports.setAppVersion = _a.setAppVersion, exports.updateUsernameStarted = _a.updateUsernameStarted, exports.updateUsernameSuccess = _a.updateUsernameSuccess, exports.updateUsernameFailed = _a.updateUsernameFailed, exports.unsubscribeFromUserDetails = _a.unsubscribeFromUserDetails;
exports.default = userSlice.reducer;
