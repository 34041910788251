import React from 'react';
import classNames from 'classnames';
import styles from './dropdown-menu.module.scss';
import { Dropdown, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import RelativePortal from '../portal/RelativePortal';
import { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';
import { IconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { Fade } from '../fade/fade';

export type DropdownOption = {
  label: string;
  isDisabled?: boolean;
  onClick: () => void;
  icon?: SemanticShorthandItem<IconProps>;
  testId?: string;
};

type DropdownMenuProps = WithTranslation & {
  options: DropdownOption[];
  isSmall?: boolean;
  left?: number;
  right?: number;
  top?: number;
  onDropdownClick?: () => void;
  isGlobalChat?: boolean;
  testId?: string;
};

type DropdownMenuState = {
  show: boolean;
};

const dropdownStyle = {
  cursor: 'auto',
  outline: 0,
  top: '100%',
  minWidth: 'max-content',
  margin: 0,
  padding: 0,
  background: '#fff',
  fontSize: '1em',
  textAlign: 'left',
  textShadow: 'none',
  boxShadow: '0 2px 3px 0 rgba(34, 36, 38, 0.15)',
  border: '1px solid rgba(34, 36, 38, 0.15)',
  borderRadius: '0.28571429rem',
  zIndex: 11,
} as const;

class DropdownMenu extends React.PureComponent<DropdownMenuProps, DropdownMenuState> {
  setShowAsyncTimer: null | NodeJS.Timeout = null;

  constructor(props: DropdownMenuProps) {
    super(props);

    this.state = {
      show: false,
    };

    this.setShowAsyncTimer = null;
  }

  componentWillUnmount() {
    if (this.setShowAsyncTimer) {
      clearTimeout(this.setShowAsyncTimer);
    }
  }

  toggleShow = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }

    this.toggleShowAsync();
    if (this.props.onDropdownClick) {
      this.props.onDropdownClick();
    }
  };

  onItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.toggleShowAsync();
  };

  /**
   * Changes the dropdown show/hide state asynchronously.
   *
   * Need to change the dropdown state asynchronously,
   * otherwise the dropdown gets immediately closed
   * during the dropdown toggle's `onClick` which propagates to `onOutClick`.
   */
  toggleShowAsync() {
    if (this.setShowAsyncTimer) {
      clearTimeout(this.setShowAsyncTimer);
    }

    this.setShowAsyncTimer = setTimeout(() => {
      this.setState(prevState => ({ show: !prevState.show }));
    }, 0);
  }

  getItemStyles = (iSmall?: boolean, disabled?: boolean) => {
    let className = 'dropdownItem';
    if (iSmall) {
      className += ' smallDropdownItem';
    }
    if (disabled) {
      className += ' disabledItem';
    }

    return className;
  };

  render() {
    const { show } = this.state;
    const { options, isSmall, left, top, right, isGlobalChat, testId } = this.props;

    return (
      <div className={styles.dropdown} data-testid={testId}>
        <button className={styles.dropdownTrigger} onClick={this.toggleShow}>
          <Icon
            name="ellipsis vertical"
            className={classNames(styles.optionsIcon, isSmall && styles.smallIcon)}
          />
        </button>
        <RelativePortal
          component="div"
          left={left || 0}
          right={right}
          top={top || 0}
          onOutClick={show ? this.toggleShow : undefined}
          isGlobalChat={isGlobalChat}
        >
          <Fade show={show} styles={dropdownStyle} onItemClick={this.onItemClick}>
            {options.map(option => (
              <Dropdown.Item
                key={option.label}
                text={option.label}
                icon={option.icon}
                onClick={option.isDisabled ? undefined : option.onClick}
                data-testid={option.testId}
                className={
                  isGlobalChat
                    ? this.getItemStyles(isSmall, option.isDisabled)
                    : option.isDisabled
                    ? classNames(
                        styles.dropdownItem,
                        isSmall && styles.smallDropdownItem,
                        styles.disabledItem
                      )
                    : classNames(styles.dropdownItem, isSmall && styles.smallDropdownItem)
                }
              />
            ))}
          </Fade>
        </RelativePortal>
      </div>
    );
  }
}

export default withTranslation('pages')(DropdownMenu);
