"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvitation = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("../types");
exports.getInvitation = (0, toolkit_1.createAsyncThunk)(`${types_1.JOIN_TO_WORKSPACE}/getInvitation`, (token, { rejectWithValue, extra: { api } }) => __awaiter(void 0, void 0, void 0, function* () {
    const apiMethods = yield api();
    try {
        const { data } = yield apiMethods.getInvitationInfo(token);
        return Object.assign(Object.assign({}, data), { token });
    }
    catch (e) {
        const { response } = e;
        return rejectWithValue(response.status);
    }
}));
