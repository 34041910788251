import React, { FC, useState } from 'react';
import {
  ChatApp,
  chatListActions,
  projectsActions,
  ProjectTab,
  selectors,
  workspacesActions,
  WorkspaceSettingsSection,
} from '@xtrf/shared';
import styles from './app-list-item.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNumberOfChatRoomWindows } from '../../../../../../../hooks/useNumberOfChatRoomWindows';
import { ChatRoomType } from '@xtrf/shared/lib/store/enums/chatRoomType';
import { UnreadMessagesIndicator } from '../../../../../unread-messages-indicator/unread-messages-indicator.component';
import DropdownMenu from '../../../../../../shared/dropdown-menu/dropdown-menu';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type OwnProps = {
  app: ChatApp;
  workspaceId: string;
};

export type AppListItemProps = OwnProps;

const AppListItem: FC<AppListItemProps> = ({ app, workspaceId }) => {
  const dispatch = useDispatch();
  const numberOfChatRoomWindows = useNumberOfChatRoomWindows();
  const { unreadMessagesPerApp } = useSelector(
    selectors.getTotalUnreadMessagesForWorkspace,
    shallowEqual
  );
  const { t } = useTranslation(['pages', 'common']);
  const [dropdownButtonVisible, setDropdownButtonVisible] = useState(false);
  const handleChatRoomClick = () => {
    dispatch(
      chatListActions.addChatRoom(
        {
          type: ChatRoomType.AppChatRoom,
          appId: app.id,
          id: app.chatRoom.id,
          workspaceId,
        },
        numberOfChatRoomWindows
      )
    );
  };

  return (
    <div
      className={styles.container}
      onClick={handleChatRoomClick}
      onMouseEnter={() => {
        setDropdownButtonVisible(true);
      }}
      onMouseLeave={() => setDropdownButtonVisible(false)}
    >
      <div>
        <img src={`data:image/svg+xml;utf8,${app.icon}`} alt="" className={styles.appIcon} />
      </div>
      <div className={styles.appName}>{app.name}</div>
      <UnreadMessagesIndicator count={unreadMessagesPerApp[app.id] ?? 0} />
      <div
        className={classNames(
          styles.dropdownContainer,
          dropdownButtonVisible ? styles.dropdownVisible : styles.dropdownHidden
        )}
      >
        {/* temporarily hidden in job offers app */}
        {app.id !== 'xtrf-joboffers' && (
          <DropdownMenu
            right={10}
            options={[
              {
                label: t('common:button.settings'),
                onClick: () => {
                  dispatch(
                    workspacesActions.changeActiveWorkspaceSettingsSection(
                      WorkspaceSettingsSection[app.name as keyof typeof WorkspaceSettingsSection]
                    )
                  );
                  dispatch(projectsActions.changeProjectTab(ProjectTab.Settings));
                },
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default AppListItem;
