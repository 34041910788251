import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import UserProvider from 'providers/user.provider';
import Routing from 'components/routing.component';
import { createReduxStore } from '../initialize-store';
import ErrorBoundary from '../shared/error-boundary/error-boundary';

const { store } = createReduxStore(true);
export type AppDispatch = typeof store.dispatch;

const App: React.FC = () => {
  const isAdminPanelView = window.location.pathname.includes('adminPanel');

  const changeAppTitle = () => {
    const appTitle = document.getElementById('appTitle');
    if (!appTitle) return;

    if (isAdminPanelView) {
      appTitle.innerHTML = 'XTRF Chat - Admin Panel';
    }
  };

  useEffect(() => {
    changeAppTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminPanelView]);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <UserProvider>
          <Routing />
        </UserProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
