"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidationSchemaForSettingsForm = exports.FormFieldType = void 0;
const yup = __importStar(require("yup"));
var FormFieldType;
(function (FormFieldType) {
    FormFieldType["checkbox"] = "checkbox";
    FormFieldType["multiselect"] = "multiselect";
})(FormFieldType = exports.FormFieldType || (exports.FormFieldType = {}));
const getValidationSchemaForSettingsForm = ({ translate, appsProperties = { properties: {} }, validateAccountRules = true, }) => {
    const rules = Object.keys(appsProperties.properties).reduce((attrs, key) => {
        const validationRules = Object.keys(appsProperties.properties[key].properties).reduce((attrs1, key1) => {
            switch (appsProperties.properties[key].properties[key1].type) {
                case 'boolean': {
                    return Object.assign(Object.assign({}, attrs1), { [key1]: yup.boolean() });
                }
                case 'array': {
                    const validationSchema = appsProperties.properties[key].properties[key1].formEnableIfChecked.toString()
                        ? yup
                            .array()
                            .of(yup.number())
                            .when(appsProperties.properties[key].properties[key1].formEnableIfChecked.toString(), {
                            is: true,
                            then: yup.array().required('At least one selection required'),
                            otherwise: yup.array().notRequired(),
                        })
                        : yup.array().notRequired();
                    return Object.assign(Object.assign({}, attrs1), { [key1]: validationSchema });
                }
                default: {
                    return Object.assign({}, attrs1);
                }
            }
        }, {});
        return Object.assign(Object.assign({}, attrs), { [key]: yup.object().shape(Object.assign({}, validationRules)) });
    }, {});
    const userRules = {
        username: yup
            .string()
            .trim()
            .max(80, translate('common:validationErrors.tooLongNameError'))
            .required(translate('common:validationErrors.emptyNameError')),
    };
    const appsRulesValidationSchema = yup.object().shape(rules);
    return validateAccountRules
        ? yup.object().shape(Object.assign(Object.assign({}, userRules), { apps: appsRulesValidationSchema }))
        : yup.object().shape({ apps: appsRulesValidationSchema });
};
exports.getValidationSchemaForSettingsForm = getValidationSchemaForSettingsForm;
