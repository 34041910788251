import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, userActions } from '@xtrf/shared';

import { Header } from './header';

export const HeaderContainer = () => {
  const dispatch = useDispatch();

  const user = useSelector((store: AppStore) => store.user.data);
  const workspaces = useSelector((state: AppStore) => state.workspaces.data);

  const onLogout = () => {
    dispatch(userActions.logOutUser());
  };

  return <Header workspaces={workspaces} user={user} onLogout={onLogout} />;
};
