import React, { FC, useState } from 'react';
import {
  AppStore,
  DATE_FORMAT,
  helpers,
  MessageType,
  RemovedMessage,
  roomsActions,
  UpdatingMessageStatus,
  UserFileMessage,
  UserTextMessage,
} from '@xtrf/shared';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';
import styles from './chat-window-message.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ChatMessageFallback from '../chat-message-fallback/chat-message-fallback';
import { Button, Confirm, TransitionablePortal } from 'semantic-ui-react';
import ChatMessageErrorInfo from '../chat-message-error-info/chat-message-error-info';
import Avatar from 'components/shared/avatar/avatar.component';
import FileItem from '../file-item/file-item.component';
import { SmileIcon } from '../../../../shared/icons';
import DeleteIcon from '../../../../shared/icons/delete-icon';
import DropdownMenu from '../../../shared/dropdown-menu/dropdown-menu';
import { isGlobalChat } from '../../../../shared';
import parse from 'html-react-parser';

type OwnProps = {
  message: UserFileMessage | UserTextMessage | RemovedMessage;
  incomingMessage?: boolean;
  userName: string;
  userAvatar?: string;
  shouldRenderMetaTag: boolean;
  onLocalMessageRemove: (id: string) => void;
  removedVendorId: string | null;
  chatRoomId: string;
  toggleEmoji: (messageId?: string) => void;
};

export type ChatWindowMessageProps = OwnProps;

const ChatWindowMessage: FC<ChatWindowMessageProps> = ({
  message,
  shouldRenderMetaTag,
  incomingMessage,
  userName,
  userAvatar,
  onLocalMessageRemove,
  removedVendorId,
  chatRoomId,
  toggleEmoji,
}) => {
  const { t } = useTranslation('pages');
  const { selectedWorkspace } = useSelector((store: AppStore) => store.projects, shallowEqual);
  const updatingMessage = useSelector(
    (store: AppStore) => store.rooms.updatingMessage,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [showMessageDeleteConfirm, toggleMessageDeleteConfirm] = useState(false);
  const [dropdownButtonVisible, setDropdownButtonVisible] = useState(false);
  const [isMessageDeleteAvailable, setIsMessageDeleteAvailable] = useState(true);

  const parsedCreatedAtToDate = parseISO(message.createdAt);

  const onMessageDelete = () => {
    if (helpers.canRemoveUserMessage(message.createdAt)) {
      return dispatch(roomsActions.deleteMessage({ messageId: message.id, message, chatRoomId }));
    }
    setIsMessageDeleteAvailable(false);
  };

  const renderMessage = () => {
    if (message.type === MessageType.File) {
      return (
        <FileItem
          name={message.content.shortName}
          url={message.content.url}
          onRemove={() => onLocalMessageRemove(message.id)}
          errorMessage={message.content.errorMessage}
        />
      );
    }

    const parsedText = helpers.mrkdwn.markup.all(message.content);

    return parsedText.formattedText ? parse(parsedText.formattedText) : parsedText.text;
  };
  const userInWorkspace =
    selectedWorkspace?.assigned_users?.[
      helpers.featureFlag(selectedWorkspace!, 'manyRoles')
        ? `${message.authorId}-${message.authorRole}`
        : message.authorId
    ];
  const isAnonymized = selectedWorkspace?.config?.anonymizedVendorNames;

  const authorLabelParams = {
    authorId: message.authorId,
    userInWorkspace,
    t,
    removedVendorId,
    incomingMessage,
    isAnonymized,
    userRole: selectedWorkspace?.userRole,
    userName,
  };

  const authorName = helpers.getMessageAuthorLabel(authorLabelParams);
  const authorInitials = helpers.getMessageAuthorInitials(authorLabelParams);
  const avatarBackgroundColor = helpers.getAvatarBackgroundColor(authorInitials);
  const authorAvatar = !incomingMessage
    ? userAvatar
    : userInWorkspace && 'avatarUrl' in userInWorkspace
    ? userInWorkspace.avatarUrl
    : undefined;

  const dropdownOptions = [];
  const svgSmileIconStyles = {
    fill: '#6e6564',
    padding: '2px',
    marginRight: '5px',
  };

  dropdownOptions.push({
    label: t('message.addReaction'),
    onClick: () => {
      toggleEmoji(message.id);
      setDropdownButtonVisible(false);
    },
    icon: <SmileIcon style={svgSmileIconStyles} />,
    testId: 'messageActionReaction',
  });

  if (!incomingMessage && helpers.canRemoveUserMessage(message.createdAt)) {
    dropdownOptions.push({
      label: t('message.delete'),
      onClick: () => toggleMessageDeleteConfirm(true),
      icon: <DeleteIcon className={styles.icon} />,
      testId: 'messageActionDelete',
    });
  }

  const toggleShow = () => {
    toggleMessageDeleteConfirm(false);
  };

  const confirmMessage = isMessageDeleteAvailable
    ? `${t('message.deleteConfirm')} ${t('message.deleteConfirmUndone')}`
    : t('message.deletionUnavailable');

  const showErrorMessage =
    updatingMessage?.status === UpdatingMessageStatus.FAILED && updatingMessage?.id === message.id;

  return (
    <div
      className={styles.message}
      data-testid={incomingMessage ? 'incoming' : 'outgoing'}
      onMouseEnter={() => {
        if (message.type === MessageType.File && !!message.content.errorMessage) {
          return setDropdownButtonVisible(false);
        }
        setDropdownButtonVisible(true);
      }}
      onMouseLeave={() => setDropdownButtonVisible(false)}
    >
      <div
        className={classNames(styles.messageHeader, !shouldRenderMetaTag && styles.withoutMetaTag)}
      >
        {shouldRenderMetaTag && (
          <div className={styles.metaTag}>
            <Avatar
              initials={authorInitials}
              avatarUrl={authorAvatar}
              backgroundColor={avatarBackgroundColor}
            />
            <span className={styles.author} data-testid="message-author">
              {authorName}
            </span>
            <span className={styles.date} data-testid="message-date">
              {format(parsedCreatedAtToDate, DATE_FORMAT.SINGLE_MESSAGE)}
            </span>
          </div>
        )}
        {!!dropdownOptions.length && dropdownButtonVisible && (
          <div
            className={classNames(
              dropdownButtonVisible ? styles.dropdownVisible : styles.dropdownHidden,
              !shouldRenderMetaTag && styles.dropdownWithoutMetaTag
            )}
          >
            <DropdownMenu
              right={0}
              isSmall
              options={dropdownOptions}
              isGlobalChat={isGlobalChat()}
              testId="messageActionMenu"
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          styles.content,
          message.type === MessageType.File && styles.fileContent
        )}
        data-testid="message-content"
      >
        <ChatMessageFallback fallback={message.type === MessageType.Text ? message.content : ''}>
          {renderMessage()}
        </ChatMessageFallback>
      </div>
      {showErrorMessage && (
        <ChatMessageErrorInfo
          errorMessage={helpers.getErrorLabelForUpdatingMessageIssue(message)}
          onCancel={() => dispatch(roomsActions.removeInfoAboutUpdatedMessage())}
        />
      )}
      {!isGlobalChat() && (
        <TransitionablePortal
          open={showMessageDeleteConfirm}
          transition={{ animation: 'fade', duration: 300 }}
          onOpen={() => setTimeout(() => document.body.classList.add('confirm-fade-in'), 0)}
        >
          <Confirm
            header={confirmMessage}
            className={styles.confirm}
            open
            content=""
            size="small"
            onConfirm={() => {
              document.body.classList.remove('confirm-fade-in');
              isMessageDeleteAvailable ? onMessageDelete() : toggleShow();
            }}
            onCancel={() => {
              document.body.classList.remove('confirm-fade-in');
              toggleShow();
            }}
            cancelButton={<Button>{t('buttons.cancel')}</Button>}
            confirmButton={
              <Button loading={updatingMessage?.status === UpdatingMessageStatus.REMOVING}>
                {isMessageDeleteAvailable ? t('buttons.delete') : t('buttons.ok')}
              </Button>
            }
          />
        </TransitionablePortal>
      )}
      {showMessageDeleteConfirm && isGlobalChat() && (
        <div className={styles.deleteConfirmationModal}>
          <span className={styles.confirmText}>{confirmMessage}</span>
          <div className={styles.modalButtonsWrapper}>
            <Button
              loading={updatingMessage?.status === UpdatingMessageStatus.REMOVING}
              className={styles.blueButton}
              onClick={isMessageDeleteAvailable ? onMessageDelete : toggleShow}
            >
              {isMessageDeleteAvailable ? t('buttons.delete') : t('buttons.ok')}
            </Button>
            <Button onClick={toggleShow}>{t('buttons.cancel')}</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindowMessage;
