"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recipientsCondition = void 0;
const recipientsCondition = (ref, enabled, userId, userRole, manyRoles) => {
    return enabled
        ? ref.where('recipients', 'array-contains-any', [
            'all',
            userRole,
            userId,
            ...(manyRoles ? [`${userId}-${userRole}`] : []),
        ])
        : ref;
};
exports.recipientsCondition = recipientsCondition;
