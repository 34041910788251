"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.inviteUserToWorkspace = void 0;
const reducer_1 = require("../reducer");
const logEvent_1 = require("../../../../helpers/logEvent");
const inviteUserToWorkspace = (payload) => (dispatch, _, { api, auth, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.inviteUserToWorkspaceStarted)());
    try {
        const methods = yield api(auth);
        yield methods.inviteUserToWorkspace(payload);
        dispatch((0, reducer_1.inviteUserToWorkspaceSuccess)());
        (0, logEvent_1.logEvent)(analytics, 'invite_user_to_workspace');
    }
    catch (e) {
        const { response } = e;
        dispatch((0, reducer_1.inviteUserToWorkspaceFailed)(response ? response.data.message : 'Something went wrong'));
    }
});
exports.inviteUserToWorkspace = inviteUserToWorkspace;
