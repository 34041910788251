import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppStore,
  projectsActions,
  ProjectTab,
  workspacesActions,
  WorkspaceSettingsSection,
} from '@xtrf/shared';
import { useDispatch, useSelector } from 'react-redux';
import { FeatureButton } from '../feature-button/feature-button.component';
import styles from './features-panel.module.scss';
import { OngoingIcon, AllIcon, SettingsIcon } from '../../../shared/icons';
import { LeaveWorkspaceConfirmContext } from '../../../providers/leave-workspace-confirm.provider';

type Tab = {
  key: ProjectTab;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

type FeaturesPanelProps = {
  testMode: boolean;
};

export const FeaturesPanel: React.FC<FeaturesPanelProps> = ({ testMode }) => {
  const { t } = useTranslation('common');
  const activeTab = useSelector((store: AppStore) => store.projects.activeTab);
  const dispatch = useDispatch();
  const { setConfirmLeaveWorkspaceVisible } = useContext(LeaveWorkspaceConfirmContext);

  const tabs: Tab[] = [
    {
      key: ProjectTab.Ongoing,
      label: 'button.ongoing',
      icon: <OngoingIcon />,
      onClick: () => dispatch(projectsActions.changeProjectTab(ProjectTab.Ongoing)),
    },
    {
      key: ProjectTab.All,
      label: 'button.all',
      icon: <AllIcon />,
      onClick: () => dispatch(projectsActions.changeProjectTab(ProjectTab.All)),
    },
    {
      key: ProjectTab.Settings,
      label: 'button.settings',
      icon: <SettingsIcon />,
      onClick: () => {
        dispatch(
          workspacesActions.changeActiveWorkspaceSettingsSection(WorkspaceSettingsSection.Any)
        );
        dispatch(projectsActions.changeProjectTab(ProjectTab.Settings));
      },
    },
  ];

  return (
    <div className={styles.container}>
      {tabs.map(tab => (
        <FeatureButton
          key={tab.key}
          icon={tab.icon}
          text={t(tab.label)}
          isActive={tab.key === activeTab}
          testMode={testMode}
          onClick={() => {
            tab.onClick();
            setConfirmLeaveWorkspaceVisible(false);
          }}
        />
      ))}
    </div>
  );
};
