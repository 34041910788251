import React from 'react';
import { Accordion, Icon, Transition } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './toggle-tab.module.scss';
import { UnreadMessagesIndicator } from '../../rooms/unread-messages-indicator/unread-messages-indicator.component';

type ToggleTabProps = {
  title: string;
  active: boolean;
  onClick: () => void;
  totalUnreadMessages?: number;
  testId?: string;
};

const ToggleTab: React.FC<ToggleTabProps> = ({
  title,
  active,
  onClick,
  children,
  totalUnreadMessages = 0,
  testId,
}) => {
  return (
    <Accordion data-testid={testId}>
      <Accordion.Title
        active={active}
        onClick={onClick}
        className={classNames(styles.title, active && styles.titleActive)}
      >
        {title}
        <div>
          <UnreadMessagesIndicator
            className={classNames(styles.unreadMessages, active && styles.unreadMessagesHidden)}
            count={totalUnreadMessages}
          />
          <Icon
            name={active ? 'chevron up' : 'chevron down'}
            className={styles.toggleIcon}
            size="large"
          />
        </div>
      </Accordion.Title>
      <Transition visible={active} animation="slide down" duration={300}>
        <Accordion.Content className={styles.content} active={active}>
          {children}
        </Accordion.Content>
      </Transition>
    </Accordion>
  );
};

export default ToggleTab;
