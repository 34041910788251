import React from 'react';
import classNames from 'classnames';
import styles from './unread-messages-indicator.module.scss';

type UnreadMessagesIndicatorProps = {
  count?: number;
  big?: boolean;
  className?: string;
  symbol?: string | null;
  title?: string;
};

export const UnreadMessagesIndicator: React.FC<UnreadMessagesIndicatorProps> = ({
  count,
  big,
  className,
  symbol,
  title,
}) => {
  if ((!count || count <= 0) && !symbol) {
    return null;
  }

  return (
    <>
      {
        <div
          className={classNames([styles.numberInCircle, className], big && styles.big)}
          title={title}
          data-testid={`unread-messages-indicator ${count}`}
        >
          {symbol ? symbol : (count as number) >= 100 ? '99+' : count}
        </div>
      }
    </>
  );
};
