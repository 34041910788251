import React, { useCallback, useState } from 'react';
import { AppStore, filesActions, UploadFileStatus, helpers } from '@xtrf/shared';
import FileLoader from 'components/shared/file-loader/file-loader.component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header } from 'semantic-ui-react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import styles from './set-avatar.module.scss';
import 'react-easy-crop/react-easy-crop.css';

const CROP_SIZE = { width: 160, height: 160 };

export const SetAvatar: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'common']);
  const userId = useSelector((store: AppStore) => store.user.data?.userId);
  const files = useSelector((store: AppStore) => userId && store.files.uploadingFiles?.[userId]);
  const avatarFile = useSelector((store: AppStore) => store.files.settingAvatar)?.file;

  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const status = userId && avatarFile?.file.uid && files && files[avatarFile?.file.uid]?.status;

  const loading = status === UploadFileStatus.Uploading || false;

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getImage = useCallback(async () => {
    if (!avatarFile || !croppedAreaPixels) {
      return;
    }

    const croppedImage = (await helpers.getCroppedImg(
      avatarFile.imageDataURL,
      croppedAreaPixels,
      CROP_SIZE
    )) as Blob;

    if (avatarFile && userId) {
      dispatch(
        filesActions.uploadFileToStorage({
          file: {
            blob: croppedImage,
            uid: avatarFile.file.uid,
            name: avatarFile.file.name,
            size: croppedImage.size,
            type: croppedImage.type,
            lastModifiedDate: new Date(),
          },
          id: userId,
          collection: 'users',
        })
      );
    }
  }, [avatarFile, croppedAreaPixels, dispatch, userId]);

  return (
    <>
      <Header>
        <div className={styles.headerTitle}>{t('settings.updateAvatar')}</div>
      </Header>

      {files && (
        <div>
          <FileLoader
            files={files}
            onUploadCancel={ids => dispatch(filesActions.cancelFileUploadToStorage(ids))}
            backgroundColor="unset"
          />
        </div>
      )}
      <div className={styles.cropperWrapper}>
        <Cropper
          image={avatarFile?.imageDataURL}
          cropShape="round"
          showGrid={false}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropSize={CROP_SIZE}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          disableAutomaticStylesInjection={true}
        />
      </div>
      <Button loading={loading} className={styles.blueButton} onClick={getImage}>
        {t('buttons.saveChanges')}
      </Button>

      <Button
        className={styles.cancelButton}
        onClick={() => dispatch(filesActions.resetAvatarSetting())}
      >
        {t('buttons.cancel')}
      </Button>
    </>
  );
};
