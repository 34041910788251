"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromUnreadMessagesPerWorkspace = void 0;
const reducer_1 = require("../reducer");
const unsubscribeFromUnreadMessagesPerWorkspace = () => (dispatch, _, { firestoreSubscriptions }) => {
    if (firestoreSubscriptions.unreadMessagesPerWorkspaceListener) {
        firestoreSubscriptions.unreadMessagesPerWorkspaceListener();
    }
    dispatch((0, reducer_1.unsubscribeFromUnreadMessagesPerWorkspace)());
};
exports.unsubscribeFromUnreadMessagesPerWorkspace = unsubscribeFromUnreadMessagesPerWorkspace;
