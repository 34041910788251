import React from 'react';
import { useDispatch } from 'react-redux';
import { roomsActions } from '@xtrf/shared';
import { useTranslation } from 'react-i18next';
import { Dropdown, Header, Modal } from 'semantic-ui-react';

import TransitionedModal from 'components/shared/transitioned-modal/transitioned-modal.component';
import ResendInvitation from 'components/rooms/resend-invitation/resend-invitation.component';
import CloseIcon from 'shared/icons/close-icon';

import { ResendInvitationActionProps } from './resend-invitation-action.types';
import styles from './resend-invitation-action.module.scss';

export const ResendInvitationAction = ({
  workspace,
  invitationData,
  isModalOpen,
  onModalClose,
  onModalOpen,
  onSubmit,
}: ResendInvitationActionProps) => {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();

  return (
    <>
      <Dropdown.Item className={styles.dropdownItem} onClick={onModalOpen}>
        {t('adminPanel.members.resendInvitationButton')}
      </Dropdown.Item>

      <TransitionedModal
        visible={isModalOpen}
        toggleModal={onModalClose}
        testId="resend-invitation-modal"
      >
        <Header className={styles.modalHeader}>
          <div>{workspace?.name}</div>
          <CloseIcon onClick={onModalClose} className={styles.closeIcon} data-testid="close-icon" />
        </Header>

        <Modal.Content>
          <ResendInvitation
            toggleModal={() => dispatch(roomsActions.invitationDataReset())}
            invitationData={invitationData}
            customResendInvitationAction={onSubmit}
          />
        </Modal.Content>
      </TransitionedModal>
    </>
  );
};
