"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logEvent = void 0;
const logEvent = (analytics, eventName, params) => {
    if ('EventName' in analytics) {
        analytics().logEvent(eventName, params);
    }
    else {
        analytics().logEvent(eventName, params);
    }
};
exports.logEvent = logEvent;
