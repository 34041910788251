import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '../../../../shared/form-label/form-label.component';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'semantic-ui-react';
import styles from '../../workspace-settings.module.scss';
import AvatarUpload from '../../../../shared/avatar/avatar-upload.component';
import Input from '../../../../shared/input/input-component';
import { SetAvatar } from '../../../../shared/set-avatar/set-avatar';
import { useSelector } from 'react-redux';
import { AppStore, helpers } from '@xtrf/shared';
import CropAvatarError from 'components/shared/set-avatar/set-avatar-error';

type OwnProps = {
  onRemovePhotoClick: () => void;
  onTermsClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};
export type AccountSettingsTabProps = OwnProps;

export type SectionProps = {
  newsletter: boolean;
  username: string;
};

const AccountSettingsPanel: FC<AccountSettingsTabProps> = ({
  onRemovePhotoClick,
  onTermsClick,
}) => {
  const { setting: settingAvatar, error: setAvatarError } =
    useSelector((store: AppStore) => store.files.settingAvatar) || {};
  const { data: user } = useSelector((store: AppStore) => store.user);
  const { initialValues, values, setFieldValue } = useFormikContext<SectionProps>();
  const initials = helpers.getInitials(initialValues.username);
  const avatarBackgroundColor = helpers.getAvatarBackgroundColor(initials);

  const { t } = useTranslation(['pages', 'common']);

  return (
    <div className={styles.wrapper}>
      {settingAvatar && <SetAvatar />}
      {setAvatarError && <CropAvatarError errorMessage={setAvatarError} />}
      {!settingAvatar && !setAvatarError && (
        <>
          <FormLabel label="settings.profile" dataTestId="account-settings-profile-label" />
          <div className={styles.userWrapper} data-testid="account-settings-profile-details">
            <AvatarUpload initials={initials} backgroundColor={avatarBackgroundColor} big />
            <Field
              name="username"
              autoComplete="off"
              component={Input}
              style={{ marginLeft: '10px' }}
            />
          </div>
          {user?.avatarUrl && (
            <div className={styles.removeAvatarWrapper}>
              <span className={styles.link} onClick={onRemovePhotoClick}>
                {t('settings.removeAvatar')}
              </span>
            </div>
          )}
          <FormLabel
            label="settings.notifications"
            dataTestId="account-settings-notifications-label"
          />
          <Field
            name="newsletter"
            component={Checkbox}
            label="XTRF newsletters"
            checked={values.newsletter}
            onClick={() => setFieldValue('newsletter', !values.newsletter)}
            data-testid="newsletter-checkbox"
          />
          <FormLabel label="settings.agreements" dataTestId="account-settings-agreements-label" />
          <Field
            component={Checkbox}
            label={
              <label className={styles.termsAndConditions}>
                {t('register.agree')}
                <span className={styles.link} onClick={onTermsClick}>
                  {t('register.terms')}
                </span>
              </label>
            }
            disabled
            checked
            data-testid="account-settings-terms-checkbox"
          />
        </>
      )}
    </div>
  );
};

export default AccountSettingsPanel;
