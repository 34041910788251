"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatRoomsStatus = exports.ProjectsStatus = exports.PROJECTS_REDUCER = void 0;
exports.PROJECTS_REDUCER = 'Projects';
var ProjectsStatus;
(function (ProjectsStatus) {
    ProjectsStatus["SUBSCRIBING"] = "SUBSCRIBING";
    ProjectsStatus["SUBSCRIBED"] = "SUBSCRIBED";
    ProjectsStatus["HAS_ERROR"] = "HAS_ERROR";
    ProjectsStatus["FETCHING"] = "FETCHING";
    ProjectsStatus["FETCHED"] = "FETCHED";
})(ProjectsStatus = exports.ProjectsStatus || (exports.ProjectsStatus = {}));
var ChatRoomsStatus;
(function (ChatRoomsStatus) {
    ChatRoomsStatus["FETCHING"] = "FETCHING";
    ChatRoomsStatus["FETCHED"] = "FETCHED";
    ChatRoomsStatus["HAS_ERROR"] = "HAS_ERROR";
})(ChatRoomsStatus = exports.ChatRoomsStatus || (exports.ChatRoomsStatus = {}));
