"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageAuthorInitials = void 0;
const userStatusInWorkspace_1 = require("../store/enums/userStatusInWorkspace");
const getInitials_1 = require("./getInitials");
const getMessageAuthorInitials = ({ authorId, userInWorkspace, removedVendorId, incomingMessage, isAnonymized, userName, t, }) => {
    if (!userInWorkspace) {
        return (0, getInitials_1.getInitials)(t('chatRoom.defaultUser'));
    }
    if (userInWorkspace.status === userStatusInWorkspace_1.UserStatusInWorkspace.Removed || authorId === removedVendorId) {
        return '?';
    }
    const { anonymizedName, firstName, lastName, username: authorUserName } = userInWorkspace;
    const authorName = authorUserName || `${firstName !== null && firstName !== void 0 ? firstName : ''} ${lastName !== null && lastName !== void 0 ? lastName : ''}`.trim();
    if (isAnonymized && anonymizedName) {
        if (!incomingMessage && userName) {
            return (0, getInitials_1.getInitials)(userName);
        }
        if (authorName) {
            return (0, getInitials_1.getInitials)(authorName);
        }
        return (0, getInitials_1.getInitials)(anonymizedName);
    }
    return (0, getInitials_1.getInitials)(authorName);
};
exports.getMessageAuthorInitials = getMessageAuthorInitials;
