"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvatarBackgroundColor = void 0;
const getAvatarBackgroundColor = (initials) => {
    if (initials === '?') {
        return '#DDDDDD';
    }
    const initialsCharCodeValue = (initials !== null && initials !== void 0 ? initials : '')
        .split('')
        .map(c => c.charCodeAt(0))
        .reduce((acc, c) => acc + c, 0);
    switch (initialsCharCodeValue % 5) {
        case 0:
            return '#066781';
        case 1:
            return '#5FAAA7';
        case 2:
            return '#FC4628';
        case 3:
            return '#EBA400';
        default:
            return '#8B2753';
    }
};
exports.getAvatarBackgroundColor = getAvatarBackgroundColor;
