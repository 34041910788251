"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateWithTimeZone = void 0;
const formatDateWithTimeZone = (date, { dateStyle }) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const dateOptions = {
        full: {
            dateStyle: 'short',
            timeStyle: 'long',
        },
        time: {
            dateStyle: undefined,
            timeStyle: 'short',
        },
        date: {
            dateStyle: 'short',
            timeStyle: undefined,
        },
    }[dateStyle];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new Intl.DateTimeFormat(locale, Object.assign({ timeZone }, dateOptions)).format(new Date(date));
};
exports.formatDateWithTimeZone = formatDateWithTimeZone;
