"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserDetails = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const setUserPresence_1 = require("./setUserPresence");
const logOutUser_1 = require("./logOutUser");
const getUserDetails = (email) => (dispatch, getState, { auth, firestore, analytics, firestoreSubscriptions }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.getUserDetailsStarted)());
    try {
        if (firestoreSubscriptions.userDetailsListener) {
            firestoreSubscriptions.userDetailsListener();
        }
        const ref = firestore()
            .collection(firebaseCollectionNames_1.USERS_COLLECTION_ID)
            .where('email', '==', email);
        firestoreSubscriptions.userDetailsListener = ref.onSnapshot(snapshot => {
            if (snapshot.empty) {
                dispatch((0, reducer_1.getUserDetailsFailed)());
                return;
            }
            const data = snapshot.docs[0].data();
            dispatch((0, reducer_1.getUserDetailsSuccess)(data));
            dispatch((0, setUserPresence_1.setUserPresence)());
            analytics().setUserProperties({ userId: data.userId });
            snapshot.docChanges().forEach((change) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c;
                if (change.type === 'modified') {
                    const updatedData = change.doc.data();
                    const { user: { data }, } = getState();
                    if (((_a = data === null || data === void 0 ? void 0 : data.updatedAt) === null || _a === void 0 ? void 0 : _a.seconds) !== ((_b = updatedData.updatedAt) === null || _b === void 0 ? void 0 : _b.seconds)) {
                        try {
                            yield ((_c = auth().currentUser) === null || _c === void 0 ? void 0 : _c.getIdToken(true));
                        }
                        catch (e) {
                            dispatch((0, logOutUser_1.logOutUser)());
                        }
                    }
                }
            }));
        }, () => {
            dispatch((0, reducer_1.getUserDetailsFailed)());
        });
    }
    catch (e) {
        dispatch((0, reducer_1.getUserDetailsFailed)());
    }
});
exports.getUserDetails = getUserDetails;
