import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './file-item.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FileErrorMessage } from '@xtrf/shared';

type FileItemProps = {
  name: string;
  url?: string;
  errorMessage?: FileErrorMessage;
  onRemove: () => void;
};

const FileItem: FC<FileItemProps> = ({ name, url, errorMessage, onRemove }) => {
  const { t } = useTranslation('common');
  const renderLeftIcon = () => {
    if (errorMessage) {
      return <Icon name="exclamation triangle" className={styles.errorIcon} />;
    }

    return <Icon name="file alternate outline" className={styles.fileIcon} />;
  };

  const renderBottomElement = () => {
    if (errorMessage) {
      return (
        <div className={styles.errorMessage}>
          {t(errorMessage.label, errorMessage.interpolation || {})}
        </div>
      );
    }

    return null;
  };

  return (
    <a
      href={url || undefined}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.container}
      data-testid="file-item"
    >
      <div className={classNames(styles.mainContent, errorMessage && styles.errorContent)}>
        {renderLeftIcon()}
        <div className={styles.name}>{name}</div>
        {errorMessage && <Icon name="close" className={styles.deleteIcon} onClick={onRemove} />}
      </div>
      {renderBottomElement()}
    </a>
  );
};

export default FileItem;
