import { useState, useLayoutEffect } from 'react';
import { isGlobalChat } from '../shared';

export function useNumberOfChatRoomWindows() {
  const [numberOfChatRoomWindows, setNumberOfChatRoomWindows] = useState(3);
  useLayoutEffect(() => {
    function updateNumberOfChatRoomWindows() {
      setNumberOfChatRoomWindows(isGlobalChat() && window.innerWidth < 1440 ? 2 : 3);
    }
    window.addEventListener('resize', updateNumberOfChatRoomWindows);
    updateNumberOfChatRoomWindows();

    return () => window.removeEventListener('resize', updateNumberOfChatRoomWindows);
  }, []);

  return numberOfChatRoomWindows;
}
