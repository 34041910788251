"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsOnline = exports.isManagerOnline = exports.isVendorOnline = void 0;
const enums_1 = require("../../enums");
const reselect_1 = require("reselect");
const __1 = require("../../..");
const helpers_1 = require("../../../helpers");
const isVendorOnline = (workspace, usersPresence, assignedUsers, vendorEmail) => {
    var _a;
    if (!usersPresence || !vendorEmail || !assignedUsers) {
        return false;
    }
    const vendor = Object.entries(assignedUsers)
        .map(([id, data]) => ({ id, email: 'email' in data ? data.email : null }))
        .find(user => user.email === vendorEmail);
    if (!vendor) {
        return false;
    }
    const regex = new RegExp(`-${__1.UserRole.Manager}|-${__1.UserRole.Vendor}`);
    const vendorId = (0, helpers_1.featureFlag)(workspace, 'manyRoles') ? vendor.id.replace(regex, '') : vendor.id;
    return ((_a = usersPresence[vendorId]) === null || _a === void 0 ? void 0 : _a.status) === enums_1.UserPresenceStatus.Online;
};
exports.isVendorOnline = isVendorOnline;
const isManagerOnline = (usersPresence, managersUid) => !!usersPresence &&
    !!managersUid &&
    !!Object.keys(usersPresence).find(uid => managersUid.includes(uid) && usersPresence[uid].status === enums_1.UserPresenceStatus.Online);
exports.isManagerOnline = isManagerOnline;
const getProps = (_, props) => props;
const getUserPresence = (store) => store.workspaces.usersPresence;
const getSelectedWorkspace = (store) => store.projects.selectedWorkspace;
exports.getIsOnline = (0, reselect_1.createSelector)([getUserPresence, getSelectedWorkspace, getProps], (usersPresence, selectedWorkspace, { chatRoom }) => {
    if (!selectedWorkspace) {
        return false;
    }
    const { userRole } = selectedWorkspace;
    return userRole === __1.UserRole.Vendor
        ? (0, exports.isManagerOnline)(usersPresence, chatRoom.managers)
        : (0, exports.isVendorOnline)(selectedWorkspace, usersPresence, selectedWorkspace.assigned_users, chatRoom.vendorEmail);
});
exports.default = exports.getIsOnline;
