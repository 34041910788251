"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeProjectTab = void 0;
const reducer_1 = require("../reducer");
const enums_1 = require("../../../enums");
const subscribeToProjects_1 = require("./subscribeToProjects");
const helpers_1 = require("../../../../helpers");
const workspaces_1 = require("../../workspaces");
const changeProjectTab = (newTab) => (dispatch, getState) => {
    const { activeTab, selectedWorkspace } = getState().projects;
    if (activeTab === newTab) {
        return;
    }
    dispatch((0, reducer_1.changeTab)(newTab));
    if ((selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.id) && [enums_1.ProjectTab.Ongoing, enums_1.ProjectTab.All].includes(newTab)) {
        dispatch((0, subscribeToProjects_1.subscribeToProjects)((0, helpers_1.createWorkspacePath)(selectedWorkspace.id, selectedWorkspace.userRole), newTab === enums_1.ProjectTab.All));
    }
    if ((selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.id) && newTab === enums_1.ProjectTab.Settings) {
        dispatch(workspaces_1.workspacesActions.getWorkspaceSettingsConfigSchema(selectedWorkspace));
    }
};
exports.changeProjectTab = changeProjectTab;
