"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.editMessageReaction = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const logEvent_1 = require("../../../../helpers/logEvent");
const editMessageReaction = ({ icon, chatRoomId, messageId, }) => (dispatch, getState, { auth, firestore, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.editMessageReactionStarted)({ id: messageId }));
    try {
        const { projects: { workspaceId }, user: { data }, } = getState();
        const user = auth().currentUser;
        if (!user || !user.email || !workspaceId || !(data === null || data === void 0 ? void 0 : data.userId)) {
            throw new Error('user-is-not-logged');
        }
        let reactions = [{ icon, authorId: data.userId }];
        const ref = firestore()
            .collection(firebaseCollectionNames_1.CHAT_ROOMS_COLLECTION_ID)
            .doc(chatRoomId)
            .collection(firebaseCollectionNames_1.MESSAGES_COLLECTION_ID)
            .doc(messageId);
        yield firestore().runTransaction((t) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const doc = yield t.get(ref);
            const oldReactions = (_a = doc.data()) === null || _a === void 0 ? void 0 : _a.reactions;
            const existingEmoji = oldReactions === null || oldReactions === void 0 ? void 0 : oldReactions.find((oldReaction) => oldReaction.authorId === data.userId && oldReaction.icon === icon);
            if (oldReactions) {
                if (!!existingEmoji) {
                    reactions = oldReactions.filter((oldReaction) => oldReaction !== existingEmoji);
                }
                else {
                    reactions = [...oldReactions, { icon, authorId: data.userId }];
                }
            }
            t.update(ref, { updatedAt: firestore.FieldValue.serverTimestamp(), reactions });
        }));
        (0, logEvent_1.logEvent)(analytics, 'add_reaction_to_message', {
            chatRoomId,
            workspaceId,
            messageId,
            authorId: data.userId,
        });
        dispatch((0, reducer_1.editMessageReactionSuccess)());
    }
    catch (e) {
        dispatch((0, reducer_1.editMessageReactionFailed)());
    }
});
exports.editMessageReaction = editMessageReaction;
