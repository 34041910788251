"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancelFileUploadToStorage = void 0;
const cancelFileUploadToStorage = (fileIds = []) => (dispatch, getState, { firestoreSubscriptions }) => {
    fileIds.forEach(fileId => {
        const uploadTask = firestoreSubscriptions.fileTaskQueue[fileId];
        if (uploadTask) {
            uploadTask.cancel();
        }
    });
};
exports.cancelFileUploadToStorage = cancelFileUploadToStorage;
