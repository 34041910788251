import React, { FunctionComponent, memo } from 'react';
import {
  ChatRoomWithGroupedMessages,
  DATE_FORMAT,
  helpers,
  RoomInfo,
  roomsActions,
  User,
  AppStore,
} from '@xtrf/shared';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import ChatWindowMessage from '../chat-window-message/chat-window-message';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Loader } from 'semantic-ui-react';
import styles from './chat-window-messages.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import UnreadMessagesDivider from './unread-messages-divider/unread-messages-divider';
import Reactions from '../reactions/reactions';
import AppChatMessage from '../app-chat-message/app-chat-message';

type OwnProps = {
  roomData: ChatRoomWithGroupedMessages;
  user: User;
  roomInfo: RoomInfo | null;
  unreadRef: React.RefObject<HTMLDivElement>;
  firstNewMessage: string;
  removedVendorId: string | null;
  toggleEmoji: (messageId?: string) => void;
  sendNewMessage: (message: string) => void;
};

type ChatWindowMessagesProps = OwnProps;

const ChatWindowMessages: FunctionComponent<ChatWindowMessagesProps> = ({
  roomData,
  user,
  roomInfo,
  unreadRef,
  firstNewMessage,
  removedVendorId,
  toggleEmoji,
  sendNewMessage,
}) => {
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const unreadMessages = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom[roomData.roomId]
  );

  return (
    <div className={styles.container}>
      {roomInfo?.fetchingNextPage ? (
        <Loader active size="small" inline="centered" />
      ) : (
        <div className={styles.beginningOfConversation}>
          {t('chatRoom.beginningOfConversation')}
        </div>
      )}

      {roomData.groupedByDay.map(group => (
        <div key={group.date}>
          <Divider horizontal className={styles.divider}>
            <Header as="h5" className={styles.dateHeader}>
              {format(parseISO(group.date), DATE_FORMAT.MESSAGES_GROUP_WITH_DAY_LOCALE)}
            </Header>
          </Divider>
          {group.messages.map((message, i) => {
            const firstNewMessageInOpenBlurredChatWindow = unreadMessages ? unreadMessages[0] : '';
            const hasUnreadMessages =
              firstNewMessage === message.id ||
              firstNewMessageInOpenBlurredChatWindow === message.id;
            const shouldRenderMetaTag =
              i < 1 ||
              !(
                helpers.hasTheSameDateString(message.createdAt, group.messages[i - 1].createdAt) &&
                // @ts-ignore
                message.authorId === group.messages[i - 1].authorId &&
                message.authorRole === group.messages[i - 1].authorRole
              ) ||
              hasUnreadMessages;

            const isLastMessage = i === group.messages.length - 1;

            return (
              <React.Fragment key={message.id}>
                {hasUnreadMessages && (
                  <div ref={unreadRef} className={styles.unreadDividerContainer}>
                    <UnreadMessagesDivider />
                  </div>
                )}
                {'appId' in message ? (
                  <AppChatMessage
                    incomingMessage={true}
                    chatRoomId={roomData.roomId}
                    message={message}
                    userName={user.username || t('chatRoom.user')}
                    userAvatar={user.avatarUrl}
                    shouldRenderMetaTag={shouldRenderMetaTag}
                    removedVendorId={removedVendorId}
                    onLocalMessageRemove={messageId =>
                      dispatch(
                        roomsActions.removeLocalMessage({ messageId, roomId: roomData.roomId })
                      )
                    }
                    toggleEmoji={toggleEmoji}
                    sendNewMessage={sendNewMessage}
                    isLastMessage={isLastMessage}
                  />
                ) : (
                  <ChatWindowMessage
                    incomingMessage={![user.userId, user.email].includes(message.authorId)}
                    chatRoomId={roomData.roomId}
                    message={message}
                    userName={user.username || t('chatRoom.user')}
                    userAvatar={user.avatarUrl}
                    shouldRenderMetaTag={shouldRenderMetaTag}
                    removedVendorId={removedVendorId}
                    onLocalMessageRemove={messageId =>
                      dispatch(
                        roomsActions.removeLocalMessage({ messageId, roomId: roomData.roomId })
                      )
                    }
                    toggleEmoji={toggleEmoji}
                  />
                )}

                {message.reactions && !!message.reactions.length && (
                  <Reactions
                    message={message}
                    chatRoomId={roomData.roomId}
                    toggleEmoji={toggleEmoji}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default memo(ChatWindowMessages);
