import { FileErrorMessage, filesActions } from '@xtrf/shared';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import styles from './set-avatar.module.scss';

type SetAvatarErrorProps = {
  errorMessage?: FileErrorMessage;
};

const SetAvatarError: FunctionComponent<SetAvatarErrorProps> = ({ errorMessage }) => {
  const { t } = useTranslation(['common', 'pages']);
  const dispatch = useDispatch();

  if (!errorMessage) return null;

  return (
    <>
      <div className={styles.errorWrapper}>
        <Icon name="exclamation triangle" className={styles.errorIcon} />
        <div className={styles.errorMessage}>
          {t(errorMessage.label, errorMessage.interpolation || {})}
        </div>
      </div>
      <Button
        className={styles.cancelButton}
        onClick={() => dispatch(filesActions.resetAvatarSetting())}
      >
        {t('pages:buttons.cancel')}
      </Button>
    </>
  );
};

export default SetAvatarError;
