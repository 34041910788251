import React, { FC, SVGProps } from 'react';

const UserIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={28} height={28} viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          style={{ fill: 'rgb(94, 94, 94)' }}
        />
      </g>
    </svg>
  );
};

export default UserIcon;
