"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAvatarError = exports.resetAvatarSetting = exports.setAvatarStarted = exports.uploadFileToStorageSuccess = exports.uploadFileToStorageFailed = exports.uploadFileToStorageCancel = exports.uploadFileToStorageStarted = exports.uploadFileToStorageChangeProgress = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const enums_1 = require("../../enums");
const FILES = 'Files';
const initialState = {
    uploadingFiles: {},
    settingAvatar: undefined,
};
const filesSlice = (0, toolkit_1.createSlice)({
    name: FILES,
    initialState,
    reducers: {
        uploadFileToStorageStarted: (state, { payload }) => {
            var _a;
            return Object.assign(Object.assign({}, state), { uploadingFiles: Object.assign(Object.assign({}, state.uploadingFiles), { [payload.id]: Object.assign(Object.assign({}, (((_a = state.uploadingFiles) === null || _a === void 0 ? void 0 : _a[payload.id]) || {})), { [payload.uid]: Object.assign(Object.assign({}, payload), { status: enums_1.UploadFileStatus.Uploading, progress: 0 }) }) }) });
        },
        uploadFileToStorageChangeProgress: (state, { payload }) => {
            if (!state.uploadingFiles || !state.uploadingFiles[payload.id])
                return;
            state.uploadingFiles[payload.id][payload.uid].progress = payload.progress;
            state.uploadingFiles[payload.id][payload.uid].bytesTransferred = payload.bytesTransferred;
        },
        uploadFileToStorageSuccess: (state, { payload }) => {
            if (!state.uploadingFiles || !state.uploadingFiles[payload.id])
                return;
            delete state.uploadingFiles[payload.id][payload.uid];
        },
        uploadFileToStorageFailed: (state, { payload }) => {
            if (!state.uploadingFiles || !state.uploadingFiles[payload.id])
                return;
            delete state.uploadingFiles[payload.id][payload.uid];
        },
        uploadFileToStorageCancel: (state, { payload }) => {
            if (!state.uploadingFiles || !state.uploadingFiles[payload.id])
                return;
            delete state.uploadingFiles[payload.id][payload.uid];
        },
        setAvatarStarted(state, { payload }) {
            return Object.assign(Object.assign({}, state), { settingAvatar: {
                    setting: true,
                    file: payload,
                    error: undefined
                } });
        },
        resetAvatarSetting(state) {
            return Object.assign(Object.assign({}, state), { settingAvatar: undefined });
        },
        setAvatarError(state, { payload }) {
            return Object.assign(Object.assign({}, state), { settingAvatar: {
                    setting: false,
                    file: undefined,
                    error: payload
                } });
        },
    },
});
_a = filesSlice.actions, exports.uploadFileToStorageChangeProgress = _a.uploadFileToStorageChangeProgress, exports.uploadFileToStorageStarted = _a.uploadFileToStorageStarted, exports.uploadFileToStorageCancel = _a.uploadFileToStorageCancel, exports.uploadFileToStorageFailed = _a.uploadFileToStorageFailed, exports.uploadFileToStorageSuccess = _a.uploadFileToStorageSuccess, exports.setAvatarStarted = _a.setAvatarStarted, exports.resetAvatarSetting = _a.resetAvatarSetting, exports.setAvatarError = _a.setAvatarError;
exports.default = filesSlice.reducer;
