//eslint-disable-next-line complexity
import React from 'react';
import scrollBottom from 'utils/scroll-bottom/scroll-bottom';

const addMarkupAndSetCaretPosition = (
  setNewMessage: React.Dispatch<React.SetStateAction<string>>,
  markup: string,
  textArea?: HTMLTextAreaElement
) => {
  if (textArea) {
    if (textArea.selectionStart || textArea.selectionStart === 0) {
      const startPos = textArea.selectionStart;
      const endPos = textArea.selectionEnd;
      setNewMessage(
        textArea.value.substring(0, startPos) +
          markup +
          textArea.value.substring(endPos, textArea.value.length)
      );
      textArea.selectionStart = startPos + 1;
      textArea.selectionEnd = startPos + 1;
    } else {
      textArea.value += markup;
    }
  }
};

const handleHotkeys = (
  keyName: string,
  e: KeyboardEvent,
  setNewMessage: React.Dispatch<React.SetStateAction<string>>,
  setEnumarate: React.Dispatch<React.SetStateAction<number>>,
  newMessage: string,
  enumarate: number,
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement | undefined>,
  setSelectedText: React.Dispatch<React.SetStateAction<string | undefined>>,
  selectedText?: string
) => {
  e.preventDefault();
  e.stopPropagation();
  switch (keyName) {
    case 'command+b':
    case 'ctrl+b':
      if (selectedText) {
        setNewMessage(prev => prev.replace(`${selectedText}`, `*${selectedText.trim()}*`));
        setSelectedText(undefined);
        break;
      }
      addMarkupAndSetCaretPosition(setNewMessage, '**', textAreaRef.current);
      break;
    case 'command+i':
    case 'ctrl+i':
      if (selectedText) {
        setNewMessage(prev => prev.replace(`${selectedText}`, `_${selectedText.trim()}_`));
        setSelectedText(undefined);
        break;
      }
      addMarkupAndSetCaretPosition(setNewMessage, '__', textAreaRef.current);
      break;
    case 'command+shift+x':
    case 'ctrl+shift+x':
      if (selectedText) {
        setNewMessage(prev => prev.replace(`${selectedText}`, `~${selectedText.trim()}~`));
        setSelectedText(undefined);
        break;
      }
      addMarkupAndSetCaretPosition(setNewMessage, '~~', textAreaRef.current);
      break;
    case 'command+shift+9':
    case 'ctrl+shift+9':
      setNewMessage(prev => prev.concat('> '));
      break;
    case 'command+shift+8':
    case 'ctrl+shift+8':
      setNewMessage(prev => prev.concat('* '));
      break;
    case 'command+shift+7':
    case 'ctrl+shift+7':
      setEnumarate(prev => prev + 1);
      setNewMessage(prev => prev.concat(`${enumarate}. `));
      break;
    case 'shift+enter':
      scrollBottom(textAreaRef, true);
      if (!newMessage.includes('1. ') && !newMessage.includes('* ')) {
        setNewMessage(prev => prev.concat('\n'));
      }
      if (newMessage === '1. ' || newMessage === '* ') {
        setNewMessage('');
        break;
      }
      if (newMessage.includes('1. ') && enumarate === 1) {
        setEnumarate(prev => prev + 1);
      }
      if (enumarate > 1) {
        setEnumarate(prev => prev + 1);
        setNewMessage(prev => prev.concat(`\n${enumarate}. `));
      }
      newMessage.includes('* ') && setNewMessage(prev => prev.concat(`\n* `));
      break;
    default:
      break;
  }
};

export default handleHotkeys;
