import React from 'react';
import styles from './page-header.module.scss';

type HeaderProps = {
  title?: string;
};

const PageHeader: React.FC<HeaderProps> = ({ title }) => (
  <>
    <div className={styles.topBar}></div>
    <div className={styles.header} data-testid={title}>
      <img
        alt="xtrf_logo"
        src={require('../../../theme/xtrf/images/logo.png')}
        className={styles.logoIcon}
      />
      {title && <span className={styles.title}>{title}</span>}
    </div>
  </>
);

export default PageHeader;
