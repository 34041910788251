import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { UserRole } from '@xtrf/shared';
import { useTranslation } from 'react-i18next';

import { AdminPanelRouting } from 'pages/adminPanel/admin-panel.routing';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';
import { PagesContainer } from 'components/shared/pages-container/pages-container';

import { Menu } from './components/menu/menu';
import { HeaderContainer } from './components/header/header.container';
import { AdminPanelProps } from './admin-panel.types';
import styles from './admin-panel.module.scss';
import { Fade } from 'components/shared/fade/fade';

export const AdminPanel = ({ workspaces, isLoading }: AdminPanelProps) => {
  const { workspaceId, role } = useParams<{ workspaceId: string; role: string }>();
  const { t } = useTranslation('pages');

  const workspace = workspaces.find(workspace => workspace.id === workspaceId);

  if (isLoading) {
    return (
      <PagesContainer>
        <LoaderDotted />
      </PagesContainer>
    );
  }

  if (role !== UserRole.Admin) {
    return <Redirect to={`/${workspaceId}/${role}`} />;
  }

  return (
    <div className={styles.page}>
      <HeaderContainer />

      <Fade show={!!workspace?.config?.testMode}>
        <div className={styles.testModeLabelWrapper}>
          <p className={styles.testModeLabelBoldText}>{t('workspaces.testModeTitle')}</p>
          <p>{t('workspaces.testModeText')}</p>
        </div>
      </Fade>

      <section className={styles.container}>
        <Menu />

        <div className={styles.content}>
          <AdminPanelRouting />
        </div>
      </section>
    </div>
  );
};
