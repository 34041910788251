import React, { FunctionComponent } from 'react';
import styles from './reactions.module.scss';
import { Message, helpers, roomsActions, AppStore } from '@xtrf/shared';
import { useDispatch, useSelector } from 'react-redux';
import { SmileIcon } from 'shared/icons';
import parse from 'html-react-parser';

type ReactionProps = {
  message: Message;
  chatRoomId: string;
  toggleEmoji: (messageId?: string) => void;
};

const Reactions: FunctionComponent<ReactionProps> = ({ message, chatRoomId, toggleEmoji }) => {
  const dispatch = useDispatch();
  const userId = useSelector((store: AppStore) => store.user.data?.userId);

  const reactions = message.reactions && helpers.getReactionsWithCount(message.reactions);

  const handleEmojiPick = (emoji: string) => {
    dispatch(
      roomsActions.editMessageReaction({
        icon: emoji,
        chatRoomId,
        messageId: message.id,
      })
    );
  };

  return (
    <div className={styles.container}>
      {reactions?.map((reaction, index) => (
        <div
          className={
            userId && reaction.authors.includes(userId)
              ? styles.reactionWrapperAuthor
              : styles.reactionWrapper
          }
          onClick={() => handleEmojiPick(reaction.icon)}
          key={index}
        >
          <div className={styles.reaction}>
            {parse(helpers.emojiConverter.replace_unified(reaction?.icon))}
          </div>
          {reaction.count > 1 && <p className={styles.counter}>{reaction.count}</p>}
        </div>
      ))}
      <div className={styles.addReactionIcon} onClick={() => toggleEmoji(message.id)}>
        <SmileIcon fill="#6e6564" width={16} height={16} />
      </div>
    </div>
  );
};

export default Reactions;
