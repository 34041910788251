import React, { useState, useEffect } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import styles from './reset-password-form.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, userActions, RequestStatus } from '@xtrf/shared';
import NoAccountInfo from 'components/shared/no-account-info/no-account-info.component';
import { isGlobalChat } from '../../../shared';
import { History } from 'history';
import Message from 'components/shared/message/message';

interface ResetPasswordFormProps {
  toggleForm?: () => void;
  history?: History<unknown>;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ toggleForm, history }) => {
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const user = useSelector((store: AppStore) => store.user);
  const dispatch = useDispatch();
  const { t } = useTranslation(['pages', 'common']);

  useEffect(() => {
    dispatch(userActions.resetError());
  }, [dispatch]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const callback = () => {
      setLinkSent(true);
      setTimeout(() => {
        if (isGlobalChat() && toggleForm) {
          toggleForm();
        } else history?.push('/');
      }, 3000);
    };
    await dispatch(userActions.resetPassword({ email }, callback));
  };

  const sending = user.passwordStatus === RequestStatus.FETCHING;

  const handleOnChange = (updater: (value: string) => void) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updater(e.target.value);

  return (
    <div className={styles.wrapper} data-testid="reset-password-form">
      <Message
        hidden={user.error === null}
        testId="reset-password-error-message"
        title={t('common:validationErrors.resetPasswordTitle')}
        type="error"
      />
      <Message
        hidden={!linkSent}
        testId="reset-password-success-message"
        title={t('forgotPassword.linkSent')}
        type="info"
      />
      <Header as="h3" textAlign="left" className={styles.header}>
        {t('forgotPassword.resetPassword')}
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="E-mail address"
          className={styles.loginInput}
          value={email}
          onChange={handleOnChange(setEmail)}
          data-testid="reset-password-email-input"
        />
        <Button
          loading={sending}
          className={styles.submitButton}
          disabled={!email}
          data-testid="reset-password-submit-button"
        >
          {t('forgotPassword.sendLink')}
        </Button>
        {isGlobalChat() && toggleForm ? (
          <div data-testid="reset-password-signin-link">
            <p onClick={toggleForm} className={styles.signIn}>
              {t('login.signIn')}
            </p>
          </div>
        ) : (
          <div className={styles.loginForm} data-testid="reset-password-signin-link">
            <Link to="/login">{t('login.signIn')}</Link>
          </div>
        )}
        <NoAccountInfo />
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
