"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToRoom = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const getRoomMessages_1 = require("./getRoomMessages");
const helpers_1 = require("../../../../helpers");
const enums_1 = require("../../../enums");
const recipientsCondition_1 = require("./recipientsCondition");
const subscribeToRoom = (roomId, useRecipients) => (dispatch, getState, { firestoreSubscriptions, firestore, auth }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    dispatch((0, reducer_1.subscribeToRoomStarted)(roomId));
    const { user: { data }, projects: { selectedWorkspace }, } = getState();
    if (!(data === null || data === void 0 ? void 0 : data.userId)) {
        return;
    }
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        if (firestoreSubscriptions.chatRoomsListener &&
            typeof ((_a = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _a === void 0 ? void 0 : _a.ref) === 'function') {
            ((_b = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _b === void 0 ? void 0 : _b.ref)();
        }
        firestoreSubscriptions.chatRoomsListener[roomId] = {};
        dispatch((0, getRoomMessages_1.getRoomMessages)(roomId, useRecipients));
        const ref = firestore()
            .collection(firebaseCollectionNames_1.CHAT_ROOMS_COLLECTION_ID)
            .doc(roomId)
            .collection(firebaseCollectionNames_1.MESSAGES_COLLECTION_ID);
        firestoreSubscriptions.chatRoomsListener[roomId].ref = (0, recipientsCondition_1.recipientsCondition)(ref, useRecipients, data.userId, selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole, (_d = (_c = selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.config) === null || _c === void 0 ? void 0 : _c.featureFlags) === null || _d === void 0 ? void 0 : _d.manyRoles)
            .orderBy('createdAt', 'desc')
            .limit(1)
            .onSnapshot(snapshot => {
            const messages = snapshot.docs
                .map(helpers_1.parseToMessage)
                .filter(message => message.type !== enums_1.MessageType.Removed);
            const chatRoom = {
                roomId,
                messages,
                isFromCache: snapshot.metadata.fromCache,
            };
            const { user: { data: userData }, workspaces: { activeChatRooms }, } = getState();
            const isOpen = activeChatRooms.includes(roomId);
            if (!snapshot.metadata.fromCache && (userData === null || userData === void 0 ? void 0 : userData.userId) && isOpen) {
                dispatch((0, reducer_1.updateMessagesToMark)({
                    messages: messages
                        .filter(message => 'appId' in message || message.authorId !== userData.userId)
                        .map(message => message.id),
                    roomId,
                }));
            }
            dispatch((0, reducer_1.subscribeToRoomSuccess)(chatRoom));
        }, e => {
            dispatch((0, reducer_1.subscribeToRoomFailed)(roomId));
        });
    }
    catch (e) {
        dispatch((0, reducer_1.subscribeToRoomFailed)(roomId));
    }
});
exports.subscribeToRoom = subscribeToRoom;
