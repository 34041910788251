import React from 'react';

type State = {
  hasError: boolean;
};

type Props = {
  fallback: string;
};

class ChatMessageFallback extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return fallback;
    }

    return children || fallback;
  }
}

export default ChatMessageFallback;
