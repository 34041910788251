"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToAppChats = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const models_1 = require("../../../models");
const enums_1 = require("../../../enums");
const subscribeToAppChats = (selectedWorkspace, queryFilters) => (dispatch, getState, { firestoreSubscriptions, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user: { data }, } = getState();
    if (!(data === null || data === void 0 ? void 0 : data.userId)) {
        return;
    }
    try {
        dispatch((0, reducer_1.subscribeToAppChatsStarted)({ workspace: selectedWorkspace, queryFilters }));
        if (firestoreSubscriptions.appChatsListener) {
            firestoreSubscriptions.appChatsListener();
        }
        let ref = firestore()
            .collection(firebaseCollectionNames_1.APP_CHATS)
            .where('workspaceId', '==', selectedWorkspace.id);
        if (selectedWorkspace.userRole === models_1.UserRole.Vendor) {
            ref = ref.where('userId', '==', data.userId);
        }
        else {
            ref = ref.where('managers', 'array-contains', data.userId);
        }
        if (queryFilters.projectsType === enums_1.ProjectTab.Ongoing) {
            ref = ref.where('status', '==', enums_1.ChatRoomStatus.Active);
        }
        firestoreSubscriptions.appChatsListener = ref.onSnapshot(snapshot => {
            const list = snapshot.docs.map(doc => doc.data());
            dispatch((0, reducer_1.subscribeToAppChatsSuccess)(list));
        }, (error) => {
            dispatch((0, reducer_1.subscribeToAppChatsFailed)(error.message));
        });
    }
    catch (e) {
        const { message } = e;
        dispatch((0, reducer_1.subscribeToAppChatsFailed)(message));
    }
});
exports.subscribeToAppChats = subscribeToAppChats;
