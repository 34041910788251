import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppStore, RequestStatus, workspacesActions } from '@xtrf/shared';

import { Members } from './members';
import { MembersListSortValues } from './members.types';

export const MembersContainer = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const dispatch = useDispatch();

  const user = useSelector((store: AppStore) => store.user.data);
  const workspaceUsers = useSelector((state: AppStore) => state.workspaces.workspaceUsers);
  const workspaceUsersStatus = useSelector(
    (state: AppStore) => state.workspaces.workspaceUsersStatus
  );

  const [sortValue, setSortValue] = useState<MembersListSortValues>();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    !workspaceUsers.length &&
      searchValue.length === 0 &&
      dispatch(workspacesActions.getWorkspaceUsers(workspaceId, searchValue, sortValue));
  }, [dispatch, searchValue, sortValue, workspaceId, workspaceUsers]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(workspacesActions.getWorkspaceUsers(workspaceId, searchValue, sortValue));
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, searchValue, sortValue, workspaceId]);

  useEffect(() => {
    dispatch(workspacesActions.fetchAdminPendingInvitations(workspaceId));
  }, [dispatch, workspaceId]);

  return (
    <Members
      workspaceUsers={workspaceUsers}
      isLoading={workspaceUsersStatus === RequestStatus.FETCHING}
      user={user}
      sortValue={sortValue}
      setSortValue={setSortValue}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  );
};
