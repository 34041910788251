import React from 'react';
import { PagesContainer } from '../../components/shared/pages-container/pages-container';
import { isGlobalChat } from '../isGlobalChat';
import { Grid } from 'semantic-ui-react';
import Segment from '../../components/shared/segment/segment-component';
import PageHeader from '../../components/shared/page-header/page-header.component';
import { LoaderDotted } from '../../components/shared/loader-dotted/loader-dotted';
import styles from './app-loader.module.scss';

const AppLoader: React.FC = () => {
  return (
    <PagesContainer>
      {!isGlobalChat() && (
        <Grid textAlign="center" className={styles.grid} verticalAlign="middle">
          <Grid.Column className={styles.gridColumn}>
            <Segment>
              <PageHeader />
              <div className={styles.container}>
                <LoaderDotted />
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      )}
    </PagesContainer>
  );
};

export default AppLoader;
