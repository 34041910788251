import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';

import { PageContainer } from 'pages/adminPanel/components/pageContainer/page-container';
import { SwitchButton } from 'pages/adminPanel/components/switchButton/switch-button';
import { Button } from 'pages/adminPanel/components/button/button';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';

import { TestModeProps } from './test-mode.types';
import styles from './test-mode.module.scss';

export const TestMode = ({
  testModeChecked,
  setTestModeChecked,
  domainsInputValue,
  setDomainsInputValue,
  onSetWhitelistSettings,
  isDirtyDomainsInput,
  isLoading,
}: TestModeProps) => {
  const { t } = useTranslation('pages');

  return (
    <PageContainer
      title={
        <div className={styles.titleWrapper}>
          {t('adminPanel.testMode.title')}
          <div className={styles.loaderWrapper}>{isLoading && <LoaderDotted />}</div>
        </div>
      }
      titleExtra={
        <SwitchButton
          checked={testModeChecked}
          disabled={isLoading}
          onChange={e => {
            setTestModeChecked(e.target.checked);
            onSetWhitelistSettings(e.target.checked);
          }}
        />
      }
    >
      <span className={styles.infoText}>{t('adminPanel.testMode.infoText')}</span>

      <label className={styles.label}>{t('adminPanel.testMode.label')}</label>
      <Input
        className={styles.input}
        placeholder={t('adminPanel.testMode.placeholder')}
        onChange={e => setDomainsInputValue(e.target.value)}
        value={domainsInputValue}
      />

      <div className={styles.saveDomainsButtonWrapper}>
        <Button
          size="large"
          primary
          onClick={() => {
            onSetWhitelistSettings(testModeChecked);
          }}
          disabled={!isDirtyDomainsInput || isLoading}
          className={styles.saveButton}
        >
          {t('adminPanel.testMode.saveDomainsButton')}
        </Button>
      </div>
    </PageContainer>
  );
};
