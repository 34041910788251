import React, { FC, SVGProps } from 'react';

const Ongoing: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z" />
    </svg>
  );
};

export default Ongoing;
