"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanInvitation = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const types_1 = require("./types");
const enums_1 = require("../../enums");
const actions_1 = require("./actions");
const initialState = {
    invitationToHandle: null,
    getInvitationStatus: null,
    joinToWorkspaceStatus: null,
    errorCode: null,
    token: null,
};
const joinToWorkspaceSlice = (0, toolkit_1.createSlice)({
    name: types_1.JOIN_TO_WORKSPACE,
    initialState,
    reducers: {
        cleanInvitation: () => {
            return Object.assign({}, initialState);
        },
    },
    extraReducers: builder => {
        builder.addCase(actions_1.getInvitation.pending, state => {
            state.getInvitationStatus = enums_1.RequestStatus.FETCHING;
            state.joinToWorkspaceStatus = null;
            state.invitationToHandle = null;
            state.errorCode = null;
        });
        builder.addCase(actions_1.getInvitation.fulfilled, (state, action) => {
            state.getInvitationStatus = enums_1.RequestStatus.SUCCESS;
            state.invitationToHandle = action.payload;
        });
        builder.addCase(actions_1.getInvitation.rejected, (state, action) => {
            state.getInvitationStatus = enums_1.RequestStatus.FAILED;
            state.errorCode = action.payload || null;
        });
        builder.addCase(actions_1.handleInvitation.pending, state => {
            state.joinToWorkspaceStatus = enums_1.RequestStatus.FETCHING;
        });
        builder.addCase(actions_1.handleInvitation.fulfilled, (state, action) => {
            state.joinToWorkspaceStatus = enums_1.RequestStatus.SUCCESS;
            state.getInvitationStatus = null;
            state.invitationToHandle = null;
        });
        builder.addCase(actions_1.handleInvitation.rejected, (state, action) => {
            state.joinToWorkspaceStatus = enums_1.RequestStatus.FAILED;
            state.getInvitationStatus = null;
            state.errorCode = action.payload || null;
        });
    },
});
exports.cleanInvitation = joinToWorkspaceSlice.actions.cleanInvitation;
exports.default = joinToWorkspaceSlice.reducer;
