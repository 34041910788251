"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.signUpUser = void 0;
const reducer_1 = require("../reducer");
const logInUser_1 = require("./logInUser");
const updateUserData_1 = require("./updateUserData");
const signUpUser = ({ email, password, newsletterActive }, callback) => (dispatch, _, { auth }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.signUpStarted)());
    try {
        yield auth().createUserWithEmailAndPassword(email, password);
        dispatch((0, reducer_1.signUpSuccess)());
        dispatch((0, logInUser_1.logInUser)({ email, password }));
        yield dispatch((0, updateUserData_1.updateUserData)({ newsletterActive }));
        if (callback) {
            callback();
        }
    }
    catch (e) {
        const { code } = e;
        dispatch((0, reducer_1.signUpFailed)(code));
    }
});
exports.signUpUser = signUpUser;
