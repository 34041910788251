import React from 'react';
import { getMonth, getYear, setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import { Input, Icon } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

import { months, years } from './DateTimePicker.utils';
import styles from './DateTimePicker.module.scss';
import { formatDateWithTimeZone } from '@xtrf/shared/lib/helpers/formatDateWithTimeZone';

export type DateTimePickerProps = {
  onDateChange: (value: Date) => void;
  onTimeChange: (value: Date) => void;
  selected: Date;
  dateSelected: Date;
  timeSelected: Date;
  minTime: string;
  setDeadlineDate: React.Dispatch<React.SetStateAction<Date>>;
  isInputDateError: boolean;
  setIsInputDateError: React.Dispatch<React.SetStateAction<boolean>>;
  isInputTimeError: boolean;
};

export const DateTimePicker = ({
  onDateChange,
  onTimeChange,
  selected,
  dateSelected,
  timeSelected,
  minTime,
  setDeadlineDate,
  isInputDateError,
  setIsInputDateError,
  isInputTimeError,
}: DateTimePickerProps) => {
  const splittedDate = formatDateWithTimeZone(selected.toISOString(), {
    dateStyle: 'full',
  }).split(' ');

  return (
    <>
      <div className={styles.timezone}>
        Timezone: <span>{splittedDate[splittedDate.length - 1]}</span>
      </div>

      <div className={styles.dateTimePickerWrapper}>
        <DatePicker
          onChange={onDateChange}
          wrapperClassName={styles.datePickerWrapper}
          popperClassName={styles.datePopper}
          calendarClassName={styles.calendar}
          dayClassName={() => styles.day}
          className={styles.datePicker}
          selected={dateSelected}
          dateFormat="dd-MM-yyyy"
          showPopperArrow={false}
          popperPlacement="top"
          placeholderText="dd-MM-yyyy"
          minDate={new Date(minTime)}
          onChangeRaw={event => {
            const isDateFromCalendar = event.target.value === undefined;
            if (isDateFromCalendar) return;

            const inputDateParts = event.target.value.split('-');
            const inputDateStringInDDMMYYY = `${inputDateParts[1]}-${inputDateParts[0]}-${inputDateParts[2]}`;
            const isDateValid = !isNaN(Date.parse(inputDateStringInDDMMYYY));

            if (isDateValid) {
              const newInputRawDate = new Date(inputDateStringInDDMMYYY);
              const timeHours = timeSelected.getHours();
              const timeMinutes = timeSelected.getMinutes();
              const timeSeconds = 0;

              newInputRawDate.setHours(timeHours, timeMinutes, timeSeconds);
              setDeadlineDate(newInputRawDate);
              setIsInputDateError(true);
            }
          }}
          customInput={
            <Input
              className={`${styles.input} ${isInputDateError ? styles.errorInput : ''}`}
              icon={<Icon name="calendar alternate outline" className={styles.icon} />}
            />
          }
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
          }) => (
            <div className={styles.datepickerHeader}>
              <Icon
                name="chevron left"
                onClick={decreaseMonth}
                className={styles.arrowIcon}
                disabled={prevMonthButtonDisabled}
              />
              <div>
                <select
                  className={styles.select}
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  className={styles.select}
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                  {years.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <Icon name="chevron right" onClick={increaseMonth} className={styles.arrowIcon} />
            </div>
          )}
        />

        <div>
          <DatePicker
            onChange={onTimeChange}
            wrapperClassName={styles.datePickerWrapper}
            timeClassName={() => styles.time}
            className={styles.timePicker}
            selected={timeSelected}
            placeholderText="hh:mm"
            minTime={
              dateSelected.toISOString().split('T')[0] === minTime.split('T')[0]
                ? setHours(
                    setMinutes(new Date(), new Date(minTime).getMinutes()),
                    new Date(minTime).getHours()
                  )
                : setHours(setMinutes(new Date(), 0), 0)
            }
            maxTime={setHours(setMinutes(new Date(), 59), 23)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            showPopperArrow={false}
            popperPlacement="top"
            popperClassName={styles.timePopper}
            customInput={
              <Input
                className={`${styles.input} ${isInputTimeError ? styles.errorInput : ''}`}
                icon={<Icon name="clock outline" className={styles.icon} />}
              />
            }
          />
        </div>
      </div>

      {(isInputDateError || isInputTimeError) && (
        <div className={styles.inputErrorMessage}>
          The date cannot be earlier than the Planned Start Date.
        </div>
      )}
    </>
  );
};
