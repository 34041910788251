import React, { SetStateAction, useState } from 'react';
import { Form, Button, Header, Checkbox, CheckboxProps } from 'semantic-ui-react';
import styles from './register-form.module.scss';
import { initializeFirebase } from 'initialize-firebase';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { userActions, LINKS } from '@xtrf/shared';
import Message from 'components/shared/message/message';

interface RegisterError {
  message: string;
  code?: string;
}

interface RegisterFormProps {
  joinWorkspaceToken?: string;
  email: string;
}

const RegisterForm: React.FC<RegisterFormProps> = props => {
  const [acceptNotifications, setAcceptNotifications] = useState(false);
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState('');
  const [userAcceptedTerms, setUserAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<RegisterError | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');

  const handleTermsAndConditionsChanged = (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    setUserAcceptedTerms(data.checked ?? false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!userAcceptedTerms) {
      setError({ message: 'You must accept terms and conditions.' });

      return;
    }

    setIsLoading(true);

    try {
      const { auth } = await initializeFirebase();
      await auth.createUserWithEmailAndPassword(email, password);
      await auth.signInWithEmailAndPassword(email, password);
      await dispatch(userActions.updateUserData({ newsletterActive: acceptNotifications }));
      setIsLoading(false);
      setError(null);
      history.push('join-workspace', {
        token: props.joinWorkspaceToken,
      });
    } catch (err) {
      setIsLoading(false);
      setError(err as SetStateAction<RegisterError | null>);
    }
  };

  const handleTermsClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(LINKS.TERMS);
  };

  const handleOnChange = (updater: (value: string) => void) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updater(e.target.value);

  return (
    <div className={styles.wrapper} data-testid="register-form">
      <Message
        hidden={!error}
        testId="register-error-message"
        title={t('register.errorTitle')}
        content={error?.message}
        type="error"
      />
      <Header as="h3" textAlign="left" className={styles.header}>
        {t('register.registerTitle')}
      </Header>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="E-mail address"
          value={email}
          disabled
          className={styles.emailAddressInput}
          onChange={handleOnChange(setEmail)}
          data-testid="register-email-field"
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          className={styles.passwordInput}
          onChange={handleOnChange(setPassword)}
          data-testid="register-password-field"
        />

        <div className={styles.checkboxes}>
          <Checkbox
            checked={userAcceptedTerms}
            onChange={handleTermsAndConditionsChanged}
            className={styles.termsAndConditionsCheckbox}
            label={
              <label className={styles.termsAndConditions}>
                {t('register.agree')}
                <span className={styles.link} onClick={handleTermsClick}>
                  {t('register.terms')}
                </span>
              </label>
            }
            data-testid="register-terms-checkbox"
          />
          <Checkbox
            label={t('register.newsletter')}
            onChange={() => setAcceptNotifications(prevState => !prevState)}
            data-testid="register-newsletter-checkbox"
          />
        </div>

        <Button
          loading={isLoading}
          size="big"
          className={styles.submitButton}
          data-testid="register-submit-button"
        >
          {t('register.registerTitle')}
        </Button>
      </Form>
    </div>
  );
};

export default RegisterForm;
