import React from 'react';
import styles from './segment-component.module.scss';
import { Segment as SegmentComponent } from 'semantic-ui-react';

const Segment: React.FC = ({ children }) => {
  return (
    <SegmentComponent type="pilled" className={styles.segment}>
      {children}
    </SegmentComponent>
  );
};

export default Segment;
