"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldType = exports.ResendInvitationStatus = exports.SubscriptionStatus = exports.LeavingWorkspaceStatus = exports.WorkspacesStatus = void 0;
var WorkspacesStatus;
(function (WorkspacesStatus) {
    WorkspacesStatus["FETCHING"] = "FETCHING";
    WorkspacesStatus["FETCHED"] = "FETCHED";
    WorkspacesStatus["HAS_ERROR"] = "HAS_ERROR";
})(WorkspacesStatus = exports.WorkspacesStatus || (exports.WorkspacesStatus = {}));
var LeavingWorkspaceStatus;
(function (LeavingWorkspaceStatus) {
    LeavingWorkspaceStatus["PROCESSING"] = "PROCESSING";
    LeavingWorkspaceStatus["HAS_ERROR"] = "HAS_ERROR";
})(LeavingWorkspaceStatus = exports.LeavingWorkspaceStatus || (exports.LeavingWorkspaceStatus = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["SUBSCRIBING"] = "SUBSCRIBING";
    SubscriptionStatus["SUBSCRIBED"] = "SUBSCRIBED";
    SubscriptionStatus["FAILED_TO_SUBSCRIBE"] = "FAILED_TO_SUBSCRIBE";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
var ResendInvitationStatus;
(function (ResendInvitationStatus) {
    ResendInvitationStatus["SENDING"] = "SENDING";
    ResendInvitationStatus["SENT"] = "SENT";
    ResendInvitationStatus["FAILED_TO_SEND"] = "FAILED_TO_SEND";
})(ResendInvitationStatus = exports.ResendInvitationStatus || (exports.ResendInvitationStatus = {}));
var FormFieldType;
(function (FormFieldType) {
    FormFieldType["checkbox"] = "checkbox";
    FormFieldType["multiselect"] = "multiselect";
})(FormFieldType = exports.FormFieldType || (exports.FormFieldType = {}));
