"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WORKSPACE_ANONYMIZED_SUBCOLLECTION_ID = exports.FIRESTORE_PRESENCE = exports.APP_CHATS = exports.MERGED_USER_JOBS = exports.WORKSPACE_SUBCOLLECTION_ID = exports.UNREAD_MESSAGES_COUNT_COLLECTION_ID = exports.ROLE_IN_WORKSPACE_COLLECTION_ID = exports.WORKSPACE_TOKEN_COLLECTION_ID = exports.WORKSPACE_COLLECTION_ID = exports.INVITATIONS_COLLECTION_ID = exports.USERS_COLLECTION_ID = exports.MESSAGES_COLLECTION_ID = exports.CHAT_ROOMS_COLLECTION_ID = exports.USERS_SUBCOLLECTION_ID = exports.USERS_IN_CHAT_ROOMS_COLLECTION_ID = exports.PROJECTS_COLLECTION_ID = exports.JOBS_COLLECTION_ID = void 0;
exports.JOBS_COLLECTION_ID = 'jobs';
exports.PROJECTS_COLLECTION_ID = 'projects';
exports.USERS_IN_CHAT_ROOMS_COLLECTION_ID = 'users-in-chat-rooms';
exports.USERS_SUBCOLLECTION_ID = 'users';
exports.CHAT_ROOMS_COLLECTION_ID = 'chat-rooms';
exports.MESSAGES_COLLECTION_ID = 'messages';
exports.USERS_COLLECTION_ID = 'users';
exports.INVITATIONS_COLLECTION_ID = 'workspace-invitations';
exports.WORKSPACE_COLLECTION_ID = 'workspaces';
exports.WORKSPACE_TOKEN_COLLECTION_ID = 'workspace-tokens';
exports.ROLE_IN_WORKSPACE_COLLECTION_ID = 'role-in-workspace';
exports.UNREAD_MESSAGES_COUNT_COLLECTION_ID = 'unread-messages-count';
exports.WORKSPACE_SUBCOLLECTION_ID = 'unread-messages-per-workspace';
exports.MERGED_USER_JOBS = 'merged-jobs';
exports.APP_CHATS = 'app-chats';
exports.FIRESTORE_PRESENCE = 'presence';
exports.WORKSPACE_ANONYMIZED_SUBCOLLECTION_ID = 'workspace-subcollection';
