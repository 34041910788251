import React, {
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { isEqual, last } from 'lodash';
import { Picker } from 'mr-emoji';
import classNames from 'classnames';
import styles from './app-chat-window.module.scss';
import { useTranslation } from 'react-i18next';
import {
  AppStore,
  ChatRoomWithGroupedMessages,
  filesActions,
  GroupedMessage,
  ProjectsStatus,
  RoomInfo,
  RoomStatus,
  User,
  workspacesActions,
  roomsActions,
  AppChatRoom,
  DisplayedAppChat,
} from '@xtrf/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import scrollBottom from '../../../utils/scroll-bottom/scroll-bottom';
import FileLoader from '../../shared/file-loader/file-loader.component';
import scrollTop from '../../../utils/scroll-top/scroll-top';
import { LoaderDotted } from '../../shared/loader-dotted/loader-dotted';
import Message from 'components/shared/message/message';
import { EmojiData } from '../types';
import ChatWindowMessages from '../components/chat-window-messages/chat-window-messages.component';
import DragAndDrop from '../components/drag-and-drop/drag-and-drop.component';
import ChatInput from '../components/chat-input/chat-input';
import AppChatWindowHeader from './components/app-chat-window-header.component';

export const getTheNewestMessage = (data: GroupedMessage[]) => last(last(data)?.messages) || null;

export interface ChatWindowProps {
  roomInfo: RoomInfo | null;
  roomData: ChatRoomWithGroupedMessages;
  getPreviousMessages: (roomId: string, useRecipients: boolean) => Promise<void>;
  sendNewMessage: (message: string) => void;
  setIsMinimalized: (state: boolean) => void;
  setIsClosed: () => void;
  chatRoom: AppChatRoom;
  user: User | null;
  displayedChatRoom: DisplayedAppChat;
}

type PickerType = {
  isVisible: boolean;
  messageId?: string;
};

type Emoji = {
  native: string;
} & EmojiData;

// component is not annonymous, eslint bug
// eslint-disable-next-line react/display-name
const ChatWindow: FC<ChatWindowProps> = ({
  roomInfo,
  roomData,
  getPreviousMessages,
  sendNewMessage,
  setIsClosed,
  chatRoom,
  setIsMinimalized,
  user,
  displayedChatRoom,
}) => {
  const [firstNewMessage, setFirstNewMessage] = useState('');
  const [picker, setPicker] = useState<PickerType>({ isVisible: false });
  const [newEmoji, setNewEmoji] = useState<EmojiData | null>(null);
  const TextareaRef = useRef<HTMLTextAreaElement>();
  const { t } = useTranslation('pages');
  const dispatch = useDispatch();
  const windowRef = useRef<HTMLDivElement | null>(null);
  const pickerRef = useRef<HTMLSpanElement | null>(null);
  const lastElement = useRef(getTheNewestMessage(roomData.groupedByDay));
  const unreadMessagesRef = useRef<HTMLDivElement>(null);

  const { status: projectStatus, data } = useSelector((store: AppStore) => store.projects);
  const files = useSelector((store: AppStore) => store.files.uploadingFiles?.[chatRoom.id]);
  const perChatRoom = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom[chatRoom.id],
    shallowEqual
  );

  const unreadMessages = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom[roomData.roomId]
  );

  useEffect(() => {
    if (unreadMessages) {
      setFirstNewMessage(unreadMessages[0]);
    }
    setTimeout(() => {
      scrollBottom(windowRef, true);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNewMessage]);

  useLayoutEffect(() => {
    const latestMessage = getTheNewestMessage(roomData.groupedByDay);
    const hasNewMessage = !isEqual(lastElement.current?.id, latestMessage?.id);
    const isInitialFetch = hasNewMessage && !lastElement.current?.id && latestMessage?.id;
    if (firstNewMessage && isInitialFetch) {
      unreadMessagesRef.current?.scrollIntoView();
      setTimeout(() => {
        lastElement.current = latestMessage;
      }, 2000);

      return;
    }

    if (isInitialFetch) {
      setTimeout(() => {
        scrollBottom(windowRef, true);
      }, 300);
      lastElement.current = latestMessage;

      return;
    }

    lastElement.current = latestMessage;
    if (hasNewMessage) {
      scrollBottom(windowRef, true);
    }
  }, [roomData.groupedByDay, firstNewMessage]);

  const handleScroll = async (event: SyntheticEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const shouldFetchPreviousPage =
      !roomInfo?.isLastPage && !roomInfo?.fetchingNextPage && !roomInfo?.fetchingFirstPage;

    if (target.scrollTop === 0 && shouldFetchPreviousPage) {
      const scrollHeightBeforeNewMessages = target.scrollHeight;
      await getPreviousMessages(chatRoom.id, !!chatRoom.useRecipients);
      target.scrollTop = target.scrollHeight - scrollHeightBeforeNewMessages;
    }
  };

  const toggleEmoji = useCallback(
    (messageId?: string) => {
      picker.isVisible ? scrollTop(pickerRef) : scrollBottom(pickerRef, true);
      picker.isVisible
        ? setPicker({ isVisible: false })
        : setPicker({ isVisible: true, messageId });
    },
    [picker]
  );

  const handleEmojiPick = (emoji: Emoji) => {
    if (typeof picker.messageId === 'string' && picker.messageId) {
      dispatch(
        roomsActions.editMessageReaction({
          icon: emoji.native,
          chatRoomId: roomData.roomId,
          messageId: picker.messageId,
        })
      );

      return setPicker({ isVisible: false });
    }

    return setNewEmoji(emoji);
  };

  const renderContent = useCallback(() => {
    if (!user || roomInfo?.status === RoomStatus.HAS_ERROR) {
      return (
        <Message
          type="error"
          testId="error-alert"
          title={t('chatRoom.fetchingError')}
          className={styles.infoMessage}
        />
      );
    }

    if (
      !roomData.groupedByDay.length &&
      (roomInfo?.status !== RoomStatus.SUBSCRIBED || roomInfo?.fetchingFirstPage)
    ) {
      return <LoaderDotted testId="content-loader" />;
    }

    if (!roomData.groupedByDay.length) {
      return (
        <Message
          type="info"
          testId="first-message-alert"
          title={t('chatRoom.sendFirstMessage')}
          className={styles.infoMessage}
        />
      );
    }

    return (
      <ChatWindowMessages
        roomData={roomData}
        user={user}
        roomInfo={roomInfo}
        unreadRef={unreadMessagesRef}
        firstNewMessage={firstNewMessage}
        toggleEmoji={toggleEmoji}
        removedVendorId={null}
        sendNewMessage={sendNewMessage}
      />
    );
  }, [user, roomInfo, roomData, firstNewMessage, t, toggleEmoji, sendNewMessage]);

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node | null)) {
      dispatch(workspacesActions.setChatRoomAsActive(chatRoom.id));
      TextareaRef.current?.focus();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node | null)) {
      dispatch(workspacesActions.setChatRoomAsInactive(chatRoom.id));
      TextareaRef.current?.blur();
    }
  };

  return (
    <div
      className={classNames(styles.mainWrapper, {
        [styles.minimalized]: displayedChatRoom.minimized,
      })}
      onFocus={e => handleFocus(e)}
      onBlur={e => handleBlur(e)}
      tabIndex={0}
      data-testid="mainWrapper"
    >
      <AppChatWindowHeader
        chatRoom={chatRoom}
        handleClose={setIsClosed}
        unreadMessages={perChatRoom?.length ?? 0}
        setIsMinimalized={setIsMinimalized}
        displayedProjectChatRoom={displayedChatRoom}
      />
      <DragAndDrop
        chatRoomId={chatRoom.id}
        chatRoomName={chatRoom.name}
        disabled={displayedChatRoom.minimized}
      >
        <div className={styles.messageContainer}>
          {!data.length && projectStatus === ProjectsStatus.SUBSCRIBING && (
            <div className={styles.loaderWrapper} data-testid="subscribing-to-projects">
              <LoaderDotted />
            </div>
          )}
          <div
            className={classNames(
              picker.isVisible ? styles.messageWrapperNoScroll : styles.messageWrapper
            )}
            ref={windowRef}
            onScroll={handleScroll}
          >
            <div className={styles.filesContainer}>
              {files && !picker.isVisible && (
                <FileLoader
                  files={files}
                  onUploadCancel={ids => dispatch(filesActions.cancelFileUploadToStorage(ids))}
                />
              )}
            </div>
            {renderContent()}
            <span
              className={picker.isVisible ? styles.emojiPickerVisible : styles.emojiPicker}
              ref={pickerRef}
              data-testid="emoji-picker"
            >
              <Picker onClick={handleEmojiPick} showPreview={false} emojiSize={28} />
            </span>
          </div>
        </div>
        {!displayedChatRoom.minimized && (
          <ChatInput
            chatRoomName={chatRoom.name}
            chatRoomId={chatRoom.id}
            textAreaRef={TextareaRef}
            setPicker={setPicker}
            sendNewMessage={sendNewMessage}
            toggleEmoji={toggleEmoji}
            newEmoji={newEmoji}
            setNewEmoji={setNewEmoji}
          />
        )}
      </DragAndDrop>
    </div>
  );
};

export default memo(ChatWindow);
