"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetPassword = void 0;
const reducer_1 = require("../reducer");
const enums_1 = require("../../../enums");
const resetPassword = ({ email }, callback) => (dispatch, _, { auth }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!email) {
            throw new Error('email-doesnt-exist');
        }
        dispatch((0, reducer_1.resetPasswordStarted)());
        yield auth().sendPasswordResetEmail(email);
        dispatch((0, reducer_1.resetPasswordSuccess)());
        callback();
    }
    catch (e) {
        const { code } = e;
        if (code === enums_1.AuthValidationError.USER_NOT_FOUND) {
            dispatch((0, reducer_1.resetError)());
            return callback();
        }
        dispatch((0, reducer_1.resetPasswordFailed)(code));
    }
});
exports.resetPassword = resetPassword;
