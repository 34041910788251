import React from 'react';
import { Input as InputField } from 'semantic-ui-react';
import { FormikValues } from 'formik';
import styles from './input-component.module.scss';

type FieldProps = FormikValues;

const Input: React.FC<FieldProps> = ({ field, form: { touched, errors }, ...props }) => (
  <>
    <InputField
      {...field}
      {...props}
      className={errors[field.name] ? styles.inputError : styles.input}
      data-testid={field.name}
    />
    {touched[field.name] && errors[field.name] && (
      <div className={styles.error}>{errors[field.name]}</div>
    )}
  </>
);

export default Input;
