import React, { FC } from 'react';

import classNames from 'classnames';
import styles from './avatar.module.scss';
import { UploadIcon } from 'shared/icons';
import Upload, { RcCustomRequestOptions } from 'rc-upload';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, filesActions, uploadValidator } from '@xtrf/shared';
import { RcFile } from '@xtrf/shared/lib/store/reducers/files/types';

export type AvatarProps = {
  initials: string;
  backgroundColor: string;
  big?: boolean;
};

const AvatarUpload: FC<AvatarProps> = ({ initials, big, backgroundColor }) => {
  const { avatarUrl } = useSelector((store: AppStore) => store.user.data) || {};
  const dispatch = useDispatch();

  const style =
    big && avatarUrl
      ? {
          backgroundColor,
          backgroundImage: `url(${avatarUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }
      : { backgroundColor };

  const handleImageChoice = async (props: RcCustomRequestOptions) => {
    if (props.file) {
      const imageDataURL = window.URL.createObjectURL(props.file);
      const validationError = uploadValidator(props.file);
      if (validationError) {
        dispatch(filesActions.setAvatarError(validationError));

        return;
      }
      dispatch(
        filesActions.setAvatarStarted({ imageDataURL, file: (props.file as unknown) as RcFile })
      );
    }
  };

  return (
    <Upload
      className={classNames(big && styles.container)}
      customRequest={handleImageChoice}
      accept=".png, .jpg, .jpeg"
    >
      <div className={classNames(styles.wrapper, big && styles.wrapperBig)} style={style}>
        {!avatarUrl && initials}
      </div>
      <UploadIcon className={styles.uploadIcon} />
    </Upload>
  );
};

export default AvatarUpload;
