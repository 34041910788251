"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAccount = void 0;
const reducer_1 = require("../../chatList/reducer");
const reducer_2 = require("../reducer");
const logOutUser_1 = require("./logOutUser");
const deleteAccount = () => (dispatch, _, { api, auth }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_2.deleteAccountStarted)());
    try {
        dispatch((0, reducer_1.emptyChatList)());
        const methods = yield api(auth);
        yield methods.deleteAccount();
        dispatch((0, reducer_2.deleteAccountSuccess)());
        dispatch((0, logOutUser_1.logOutUser)());
    }
    catch (e) {
        dispatch((0, reducer_2.deleteAccountFailed)());
    }
});
exports.deleteAccount = deleteAccount;
