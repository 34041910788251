import React, { FC, SVGProps } from 'react';

const UploadIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M9 16v-6H5l7-7 7 7h-4v6H9m-4 4v-2h14v2H5z" />
    </svg>
  );
};

export default UploadIcon;
