import React, { FC, SVGProps } from 'react';

const MinimizeIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
      <path d="M6 19h12v2H6z" />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  );
};

export default MinimizeIcon;
