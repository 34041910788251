import React from 'react';

import ProtectedRoute from 'utils/protected-route/protected-route.component';

import { MembersContainer } from './pages/members/members.container';
import { Apps } from './pages/apps/apps';
import { TestModeContainer } from './pages/testMode/test-mode.container';
// import { Privacy } from './pages/privacy/privacy';
// import { ResetWorkspace } from './pages/resetWorkspace/reset-workspace';

export const AdminPanelRouting = () => {
  return (
    <>
      <ProtectedRoute
        path="/:workspaceId?/:role?/adminPanel/members"
        component={MembersContainer}
      />
      {/* UNCOMMENT WHEN START WORKING ON PRIVACY FEATURES */}
      {/* <ProtectedRoute path="/:workspaceId?/:role?/adminPanel/privacy" component={Privacy} /> */}
      <ProtectedRoute path="/:workspaceId?/:role?/adminPanel/apps" component={Apps} />
      <ProtectedRoute
        path="/:workspaceId?/:role?/adminPanel/testMode"
        component={TestModeContainer}
      />
      {/* UNCOMMENT WHEN START WORKING ON RESET WORKSPACE FEATURES */}
      {/* <ProtectedRoute
        path="/:workspaceId?/:role?/adminPanel/resetWorkspace"
        component={ResetWorkspace}
      /> */}
    </>
  );
};
