import React, { useEffect } from 'react';
import { WorkspaceSelector } from './workspace-selector.component';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, userActions, workspacesActions } from '@xtrf/shared';
import { initializeFirebase } from '../../../initialize-firebase';

export const WorkspaceSelectorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((store: AppStore) => store.workspaces);
  const userData = useSelector((store: AppStore) => store.user.data);

  useEffect(() => {
    (async () => {
      const { messaging } = await initializeFirebase();
      if (messaging) {
        messaging.onTokenRefresh(async () => {
          const token = await messaging.getToken();
          if (token && !userData?.fcmTokens?.includes(token)) {
            dispatch(userActions.addNewFcmToken({ token }));
          }
        });
      }
    })();
  }, [dispatch, userData]);

  useEffect(() => {
    (async () => {
      const { messaging } = await initializeFirebase();
      if (messaging) {
        try {
          const token = await messaging.getToken();
          if (token) {
            dispatch(userActions.addNewFcmToken({ token }));
          }
        } catch {
          //  Messaging: The notification permission was not granted and blocked instead.
        }
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    dispatch(workspacesActions.getWorkspaces());
  }, [dispatch]);

  return <WorkspaceSelector initiallyOpened={true} workspaces={data} />;
};
