import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Grid } from 'semantic-ui-react';
import { PagesContainer } from '../../components/shared/pages-container/pages-container';
import PageHeader from '../../components/shared/page-header/page-header.component';
import Segment from '../../components/shared/segment/segment-component';
import styles from './error-boundary.module.scss';
import Message from 'components/shared/message/message';

type State = {
  eventId: null | string;
  hasError: boolean;
};

class ErrorBoundary extends Component<WithTranslation, State> {
  state = {
    eventId: null,
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    const { t } = this.props;
    const { hasError, eventId } = this.state;
    if (hasError) {
      return (
        <PagesContainer>
          <Grid textAlign="center" style={{ height: `100vh` }} verticalAlign="middle">
            <Grid.Column style={{ width: 450 }}>
              <Segment>
                <PageHeader />
                <div className={styles.wrapper}>
                  <Message type="error" title={t('appErrorDescription')} />
                  <Button
                    onClick={() => Sentry.showReportDialog({ eventId: eventId || '' })}
                    className={classNames(styles.button, styles.submitButton)}
                  >
                    {t('button.reportFeedback')}
                  </Button>
                  <Button onClick={this.refreshPage} className={styles.button}>
                    {t('button.refreshPage')}
                  </Button>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </PagesContainer>
      );
    }

    return this.props.children;
  }
}

export default withTranslation('common')(ErrorBoundary);
