import React from 'react';
import WorkspaceRooms from './workspace-rooms.component';
import { shallowEqual, useSelector } from 'react-redux';
import { selectors, AppStore } from '@xtrf/shared';

export const WorkspaceRoomsContainer: React.FC = () => {
  const projects = useSelector((state: AppStore) => state.projects, shallowEqual);
  const { total } = useSelector(selectors.getTotalUnreadMessagesForWorkspace);
  const workspacesStatus = useSelector((store: AppStore) => store.workspaces.status, shallowEqual);

  const testMode = projects.selectedWorkspace?.config?.testMode;

  return (
    projects.selectedWorkspace && (
      <WorkspaceRooms
        unreadMessagesCount={total}
        projects={projects}
        name={projects.selectedWorkspace.name}
        workspacesStatus={workspacesStatus}
        testMode={!!testMode}
      />
    )
  );
};
