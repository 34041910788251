import React, { FC } from 'react';
import { AppChatRoom, AppStore, ChatApp, chatListActions } from '@xtrf/shared';
import styles from './chatroom.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNumberOfChatRoomWindows } from '../../../../../../../hooks/useNumberOfChatRoomWindows';
import { ChatRoomType } from '@xtrf/shared/lib/store/enums/chatRoomType';
import { UnreadMessagesIndicator } from '../../../../../unread-messages-indicator/unread-messages-indicator.component';

type OwnProps = {
  app: ChatApp;
  chatRoom: AppChatRoom;
};

export type AppListItemProps = OwnProps;

const AppListItem: FC<AppListItemProps> = ({ app, chatRoom }) => {
  const dispatch = useDispatch();
  const numberOfChatRoomWindows = useNumberOfChatRoomWindows();

  const perChatRoom = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom[chatRoom.id],
    shallowEqual
  );

  const handleChatRoomClick = () => {
    dispatch(
      chatListActions.addChatRoom(
        {
          type: ChatRoomType.CustomAppChatRoom,
          appId: app.id,
          id: chatRoom.id,
          workspaceId: chatRoom.workspaceId,
        },
        numberOfChatRoomWindows
      )
    );
  };

  return (
    <div className={styles.container} onClick={handleChatRoomClick}>
      <img src={`data:image/svg+xml;utf8,${app.icon}`} alt="app-icon" className={styles.appIcon} />

      <div className={styles.appName}>{chatRoom.name}</div>
      <UnreadMessagesIndicator count={perChatRoom?.length ?? 0} />
    </div>
  );
};

export default AppListItem;
