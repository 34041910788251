import './wdyr';

import 'react-hot-loader';
import { hot } from 'react-hot-loader/root';

import React from 'react';
import ReactDOM from 'react-dom';
import 'utils/i18n/i18n';
import App from './components/app.component';
import 'semantic-ui-css/semantic.min.css';
import 'mr-emoji/css/emoji-mart.css';
import 'theme/xtrf/index.scss';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from 'service-worker';
import { NEW_PRODUCTION_URL, OLD_PRODUCTION_HOSTNAME } from './shared/constants';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  environment: process.env.REACT_APP_FIREBASE_PROJECT_ID,
});

const HotApp = hot(App);
/* eslint-disable no-restricted-globals */
if (location.host === OLD_PRODUCTION_HOSTNAME) {
  let href = `${NEW_PRODUCTION_URL}${location.pathname}`;
  if (location.search) {
    href += `${location.search}`;
  }
  location.href = href;
} else {
  ReactDOM.render(<HotApp />, document.getElementById('app'));
}
/* eslint-enable no-restricted-globals */

serviceWorker.unregister();
