"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkspaceSettingsConfigSchema = void 0;
const user_1 = require("../../user");
const logEvent_1 = require("../../../../helpers/logEvent");
const reducer_1 = require("../reducer");
const getWorkspaceSettingsConfigSchema = (workspace) => (dispatch, getState, { api, auth, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.getWorkspaceSettingsConfigSchemaStarted)());
    const removeAppsWithNoConfigurationProperties = (workspaceSettingsConfigSchema) => {
        var _a, _b, _c, _d;
        if ((_b = (_a = workspaceSettingsConfigSchema.schema.properties) === null || _a === void 0 ? void 0 : _a.apps) === null || _b === void 0 ? void 0 : _b.properties) {
            workspaceSettingsConfigSchema.schema.properties.apps.properties = Object.entries((_d = (_c = workspaceSettingsConfigSchema.schema.properties) === null || _c === void 0 ? void 0 : _c.apps) === null || _d === void 0 ? void 0 : _d.properties).reduce((attrs1, [key1, value]) => {
                return Object.keys(value.properties).length ? Object.assign(Object.assign({}, attrs1), { [key1]: value }) : Object.assign({}, attrs1);
            }, {});
        }
        return workspaceSettingsConfigSchema;
    };
    try {
        const methods = yield api(auth);
        const response = yield methods.getUserConfigSchema(workspace);
        const cleanedWorkspaceSettingsConfigSchema = removeAppsWithNoConfigurationProperties(response.data);
        dispatch((0, reducer_1.getWorkspaceSettingsConfigSchemaSuccess)(cleanedWorkspaceSettingsConfigSchema));
        (0, logEvent_1.logEvent)(analytics, 'get_user_config_schema');
    }
    catch (e) {
        const { message } = e;
        if (message === 'user-is-not-logged') {
            dispatch(user_1.userActions.logOutUser());
        }
        dispatch((0, reducer_1.getWorkspaceSettingsConfigSchemaFailed)());
    }
});
exports.getWorkspaceSettingsConfigSchema = getWorkspaceSettingsConfigSchema;
