import React from 'react';

import styles from './workspace.module.scss';
import { Workspace as WorkspaceModel } from '@xtrf/shared';

export const Workspace: React.FC<WorkspaceModel> = ({ name, userRole, url }) => {
  return (
    <div className={styles.container} data-testid={`workspace-${name}`}>
      <div className={styles.workspaceName}>
        {name} ({userRole})
      </div>
      <div className={styles.url}>{url.replace(/(^\w+:|^)\/\//, '')}</div>
    </div>
  );
};
