import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './apps-tab.module.scss';
import { useSelector } from 'react-redux';
import { AppStore } from '@xtrf/shared';
import AppListItem from './components/app-list-item/app-list-item';

type OwnProps = {};

export type AppsTabProps = OwnProps;

const AppsTab: FC<AppsTabProps> = () => {
  const { t } = useTranslation('pages');
  const selectedWorkspace = useSelector((store: AppStore) => store.projects.selectedWorkspace);

  if (!selectedWorkspace?.apps || !selectedWorkspace?.apps.length) {
    return (
      <div className={styles.placeholder}>
        <p className={styles.emptyStateText}>{t('tabToggle.appsEmptyState')}</p>
      </div>
    );
  }

  return (
    <div className={styles.list}>
      {selectedWorkspace.apps.map(app => (
        <AppListItem app={app} key={app.id} workspaceId={selectedWorkspace.id} />
      ))}
    </div>
  );
};

export default AppsTab;
