"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logInUser = void 0;
const reducer_1 = require("../reducer");
const getUserDetails_1 = require("./getUserDetails");
const logInUser = ({ email, password }, callback) => (dispatch, _, { auth, analytics }) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch((0, reducer_1.logInStarted)());
    try {
        const res = yield auth().signInWithEmailAndPassword(email, password);
        if (!res.user) {
            return;
        }
        const { uid, email: userEmail } = res.user;
        dispatch((0, reducer_1.logInSuccess)({
            uid,
            email: userEmail,
        }));
        analytics().setUserId(uid);
        dispatch((0, getUserDetails_1.getUserDetails)(userEmail));
        if (callback) {
            callback();
        }
    }
    catch (e) {
        const { code } = e;
        dispatch((0, reducer_1.logInFailed)(code));
    }
});
exports.logInUser = logInUser;
