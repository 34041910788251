import React from 'react';
import styles from './unread-messages-divider.module.scss';
import { useTranslation } from 'react-i18next';

const UnreadMessagesDivider: React.FC = () => {
  const { t } = useTranslation('pages');

  return (
    <>
      <div className={styles.divider} data-testid="message-unread-divider" />
      <div className={styles.unreadDividerText}>{t('chatRoom.unreadMessages')}</div>
      <div className={styles.divider} />
    </>
  );
};

export default UnreadMessagesDivider;
