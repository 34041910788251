"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageTaskState = void 0;
var StorageTaskState;
(function (StorageTaskState) {
    StorageTaskState["STATE_CHANGED"] = "state_changed";
    StorageTaskState["RUNNING"] = "running";
    StorageTaskState["PAUSE"] = "pause";
    StorageTaskState["PROGRESS"] = "progress";
})(StorageTaskState = exports.StorageTaskState || (exports.StorageTaskState = {}));
