import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AppStore,
  helpers,
  projectsActions,
  ProjectTab,
  UserRole,
  Workspace as WorkspaceModel,
} from '@xtrf/shared';
import { Workspace } from '../workspace/workspace.component';
import { RadioButton } from '../../shared/radio/radio-button.component';
import { UnreadMessagesIndicator } from 'components/rooms/unread-messages-indicator/unread-messages-indicator.component';
import { isGlobalChat } from '../../../shared';
import { LeaveWorkspaceConfirmContext } from '../../../providers/leave-workspace-confirm.provider';
import DropdownMenu from '../../shared/dropdown-menu/dropdown-menu';
import styles from './workspace-list.module.scss';
import { useHistory } from 'react-router';
import { useRouteHandler } from '../../../hooks/useRouteHandler';
import { Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import { featureFlag } from '@xtrf/shared/lib/helpers';

type WorkspaceListProps = {
  workspaces: WorkspaceModel[];
  selectedWorkspace: WorkspaceModel | null;
};

export const WorkspaceList: React.FC<WorkspaceListProps> = ({ workspaces, selectedWorkspace }) => {
  const contextRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');
  const unreadMessagesPerWorkspace = useSelector(
    (store: AppStore) => store.workspaces.unreadMessagesPerWorkspace,
    shallowEqual
  );
  const { setConfirmLeaveWorkspaceVisible, setWorkspaceId, setWorkspaceRole } = useContext(
    LeaveWorkspaceConfirmContext
  );
  const history = useHistory();

  useRouteHandler(workspaces, selectedWorkspace);

  const getCountForWorkspace = (workspace: WorkspaceModel) => {
    if (!unreadMessagesPerWorkspace) {
      return 0;
    }

    return (
      unreadMessagesPerWorkspace[
        featureFlag(workspace, 'manyRoles') ? `${workspace.id}-${workspace.userRole}` : workspace.id
      ]?.length || 0
    );
  };

  const handleWorkspaceSelection = useCallback(
    (id: string) => {
      if (
        !selectedWorkspace ||
        id !== helpers.createWorkspacePath(selectedWorkspace?.id, selectedWorkspace?.userRole)
      ) {
        history.replace(`/${id}`);
      }
    },
    [selectedWorkspace, history]
  );

  const handleOptions = useCallback(
    (id: string) => {
      handleWorkspaceSelection(id);
      dispatch(projectsActions.changeProjectTab(ProjectTab.Settings));
    },
    [dispatch, handleWorkspaceSelection]
  );

  const handleWorkspaceLeaveConfirm = useCallback(
    (id: string, role: UserRole) => {
      setConfirmLeaveWorkspaceVisible(true);
      setWorkspaceId(id);
      setWorkspaceRole(role);
    },
    [setConfirmLeaveWorkspaceVisible, setWorkspaceId, setWorkspaceRole]
  );

  const renderWorkspaces = (workspaces: WorkspaceModel[]) =>
    workspaces.map(workspace => {
      const id = helpers.createWorkspacePath(workspace.id, workspace.userRole);
      const disabledWorkspace = workspace.config?.disabled === true;
      const disabledOpenOption =
        disabledWorkspace ||
        (selectedWorkspace?.userRole === workspace.userRole &&
          workspace.id === selectedWorkspace?.id);

      return (
        <div
          key={id}
          onMouseEnter={event => {
            if (disabledWorkspace) {
              contextRef.current = event.target as HTMLDivElement;
              setOpen(true);
            }
          }}
          onMouseLeave={() => {
            if (disabledWorkspace) {
              setOpen(false);
            }
          }}
        >
          <RadioButton
            onElementChecked={handleWorkspaceSelection}
            key={id}
            id={id}
            disabled={disabledWorkspace}
            checked={
              workspace.id === selectedWorkspace?.id &&
              selectedWorkspace.userRole === workspace.userRole
            }
          >
            <Workspace {...workspace} />
            {!disabledWorkspace && (
              <div className={styles.unreadMessagesIndicator}>
                <UnreadMessagesIndicator count={getCountForWorkspace(workspace)} />
              </div>
            )}
            <DropdownMenu
              onDropdownClick={() => {
                setOpen(false);
              }}
              options={[
                {
                  label: t('buttons.openWorkspace'),
                  onClick: () =>
                    handleWorkspaceSelection(
                      helpers.createWorkspacePath(workspace.id, workspace.userRole)
                    ),
                  isDisabled: disabledOpenOption,
                },
                {
                  label: t('buttons.workspacePreferences'),
                  onClick: () =>
                    handleOptions(helpers.createWorkspacePath(workspace.id, workspace.userRole)),
                  isDisabled: disabledWorkspace,
                },
                {
                  label: t('buttons.leaveWorkspace'),
                  onClick: () => handleWorkspaceLeaveConfirm(workspace.id, workspace.userRole),
                  isDisabled: workspace.config?.disableLeaveWorkspaceOption,
                },
              ]}
            />
          </RadioButton>
        </div>
      );
    });

  return (
    <div>
      <Popup
        content={t('home.workspace.workspaceDeactivatedTooltip.message')}
        context={contextRef}
        open={open}
        className={classNames(styles.popup, 'xtrf-chat-popup')}
        position="top center"
        data-testid="workspace-disabled-popup"
      />
      <div className={styles.listContent}>
        {workspaces.length > 0 ? (
          renderWorkspaces(workspaces)
        ) : (
          <div> {!isGlobalChat() && t('home.workspace.notAMemberError')}</div>
        )}
      </div>
    </div>
  );
};
