import React, { useState } from 'react';
import classNames from 'classnames';
import { Container, Icon, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './workspace-selector.module.scss';
import { WorkspaceList } from '../workspace-list/workspace-list.component';
import { Workspace } from '../workspace/workspace.component';
import AccountsPanel from '../../account/accounts-panel.component';
import { AppStore, Workspace as WorkspaceModel, WorkspacesStatus } from '@xtrf/shared';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';
import Message from 'components/shared/message/message';

type WorkspaceSelectorProps = {
  initiallyOpened: boolean;
  workspaces: WorkspaceModel[] | null;
};

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({ initiallyOpened }) => {
  const [expanded, setIsExpanded] = useState(initiallyOpened);
  const { t } = useTranslation('pages');

  const workspaces = useSelector((state: AppStore) => state.workspaces.data);
  const workspacesStatus = useSelector((state: AppStore) => state.workspaces.status);
  const user = useSelector((state: AppStore) => state.user.data);
  const projects = useSelector((state: AppStore) => state.projects);

  const toggleIsExpanded = () => {
    setIsExpanded(!expanded);
  };

  const renderWorkspacesList = () => {
    if (workspacesStatus === WorkspacesStatus.FETCHING) {
      return (
        <div className={styles.loadingBox}>
          <LoaderDotted />
        </div>
      );
    }

    if (workspacesStatus === WorkspacesStatus.HAS_ERROR) {
      return (
        <Container className={styles.errorMessage}>
          <Message type="error" title={t('home.workspace.fetchingError')} />
        </Container>
      );
    }

    return <WorkspaceList workspaces={workspaces} selectedWorkspace={projects.selectedWorkspace} />;
  };

  return (
    <div
      className={classNames(styles.container, { [styles.containerCollapsed]: !expanded })}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className={classNames(styles.headerWrapper, { [styles.collapsed]: !expanded })}>
        <div
          className={classNames(styles.header, { [styles.headerCollapsed]: !expanded })}
          onClick={toggleIsExpanded}
        >
          {projects.selectedWorkspace ? (
            <Workspace {...projects.selectedWorkspace} />
          ) : (
            <div>{t('home.workspace.notSelected')}</div>
          )}
          <Icon
            name={expanded ? 'chevron up' : 'chevron down'}
            className={styles.toggleIcon}
            size="large"
          />
        </div>
      </div>
      <div className={classNames(styles.listWrapper, !expanded && styles.updown)}>
        <div className={styles.list}>
          <hr className={styles.separator} />
          <Header className={styles.workspaceListHeader}>{t('home.workspace.workspaces')}</Header>
          {renderWorkspacesList()}
        </div>
        <AccountsPanel username={user?.email} />
      </div>
    </div>
  );
};
