"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatRoomType = void 0;
var ChatRoomType;
(function (ChatRoomType) {
    ChatRoomType["ProjectChatRoom"] = "projectChatRoom";
    ChatRoomType["AppChatRoom"] = "appChatRoom";
    ChatRoomType["CustomAppChatRoom"] = "customAppChatRoom";
    ChatRoomType["offerVendor"] = "offerVendor";
    ChatRoomType["offerManager"] = "offerManager";
})(ChatRoomType = exports.ChatRoomType || (exports.ChatRoomType = {}));
