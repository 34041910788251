"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsubscribeFromUserDetails = void 0;
const reducer_1 = require("../reducer");
const unsubscribeFromUserDetails = () => (dispatch, _, { firestoreSubscriptions }) => {
    if (firestoreSubscriptions.userDetailsListener) {
        firestoreSubscriptions.userDetailsListener();
    }
    dispatch((0, reducer_1.unsubscribeFromUserDetails)());
};
exports.unsubscribeFromUserDetails = unsubscribeFromUserDetails;
