"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadFileToStorage = void 0;
const reducer_1 = require("../reducer");
const actions_1 = require("../../rooms/actions");
const enums_1 = require("../../../enums");
const helpers_1 = require("../helpers");
const files_1 = require("../../../constants/files");
const constants_1 = require("../../../../constants");
const user_1 = require("../../user");
const uploadFileToStorage = ({ file, id, collection, chatRoomName, }) => (dispatch, getState, { storage, firestoreSubscriptions }) => {
    const storageName = `${file.uid}-${file.name}`;
    const ref = storage().ref(`${collection}/${id}/${storageName}`);
    const fileData = {
        uid: file.uid,
        name: file.name,
        storageName,
        shortName: (0, helpers_1.createFileShortcutName)(file.name),
        size: file.size,
    };
    const { selectedWorkspace } = getState().projects;
    const fileDataWithId = Object.assign(Object.assign({}, fileData), { id });
    const additionalMessagePayload = {
        authorRole: selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole,
        workspaceName: selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.name,
        chatRoomName: chatRoomName === constants_1.CHAT_TYPE.ALL_PROJECT_MEMBERS
            ? constants_1.CHAT_TYPE.ALL_PROJECT_MEMBERS
            : constants_1.CHAT_TYPE.PROJECT_MANAGERS,
    };
    dispatch((0, reducer_1.uploadFileToStorageStarted)(fileDataWithId));
    const validationError = (0, helpers_1.uploadValidator)(file);
    const createMessageWithError = (errorMessage) => (Object.assign(Object.assign({}, fileData), { url: '', type: file.type, errorMessage }));
    if (validationError) {
        if (collection === 'rooms') {
            dispatch((0, actions_1.sendNewLocalMessage)(Object.assign({ chatRoomId: id, messageType: enums_1.MessageType.File, message: createMessageWithError(validationError) }, additionalMessagePayload)));
        }
        dispatch((0, reducer_1.uploadFileToStorageFailed)(fileDataWithId));
        dispatch((0, reducer_1.setAvatarError)(validationError));
        return;
    }
    const dataToUpload = 'blob' in file ? file.blob : file;
    const uploadTask = ref.put(dataToUpload);
    firestoreSubscriptions.fileTaskQueue[file.uid] = uploadTask;
    uploadTask.on(enums_1.StorageTaskState.STATE_CHANGED, (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch((0, reducer_1.uploadFileToStorageChangeProgress)(Object.assign(Object.assign({}, fileData), { progress, bytesTransferred: snapshot.bytesTransferred, id })));
    }, err => {
        // to omit TS error https://github.com/firebase/firebase-js-sdk/issues/1515
        const error = err;
        if (error.code === 'storage/canceled' || error.code === 'storage/cancelled') {
            dispatch((0, reducer_1.uploadFileToStorageCancel)(fileDataWithId));
            return;
        }
        const uploadError = createMessageWithError({
            label: files_1.VALIDATION_ERROR_LABELS.CONNECTION_ERROR,
        });
        if (collection === 'rooms') {
            dispatch((0, actions_1.sendNewLocalMessage)(Object.assign({ chatRoomId: id, messageType: enums_1.MessageType.File, message: uploadError }, additionalMessagePayload)));
        }
        dispatch((0, reducer_1.setAvatarError)(uploadError.errorMessage));
        dispatch((0, reducer_1.uploadFileToStorageFailed)(fileDataWithId));
    }, () => __awaiter(void 0, void 0, void 0, function* () {
        const url = yield uploadTask.snapshot.ref.getDownloadURL();
        if (collection === 'rooms') {
            yield dispatch((0, actions_1.sendNewMessage)(Object.assign({ chatRoomId: id, messageType: enums_1.MessageType.File, message: Object.assign(Object.assign({}, fileData), { url, size: file.size, type: file.type }) }, additionalMessagePayload)));
        }
        else {
            yield dispatch(user_1.userActions.updateUserData({ avatarUrl: url }));
        }
        dispatch((0, reducer_1.uploadFileToStorageSuccess)(fileDataWithId));
        if (collection === 'users') {
            dispatch((0, reducer_1.resetAvatarSetting)());
        }
    }));
};
exports.uploadFileToStorage = uploadFileToStorage;
