import React, { FC, useState, memo, useEffect } from 'react';
import { emojify } from 'react-emojione';
import { useTranslation } from 'react-i18next';
import { filesActions } from '@xtrf/shared';
import { useDispatch } from 'react-redux';
import Upload, { RcCustomRequestOptions } from 'rc-upload';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './chat-input.module.scss';
import { EmojiData } from '../../types';
import { HOTKEYS } from '../../../../shared';
import { useHotkeys } from '../../../../hooks/useHotkeys';
import handleHotkeys from '../../../../utils/hotkeys/hotkeys';
import { QUESTION_MARK_KEY_CODE } from '../../../../shared/constants';
import { AttachmentIcon, SendIcon, SmileIcon } from '../../../../shared/icons';
import { AvatarFile, RcFile } from '@xtrf/shared/lib/store/reducers/files/types';

export interface ChatInputProps {
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement | undefined>;
  sendNewMessage: (message: string) => void;
  toggleEmoji: () => void;
  chatRoomId: string;
  setPicker: (obj: { isVisible: boolean }) => void;
  newEmoji: null | EmojiData;
  setNewEmoji: (emoji: null | EmojiData) => void;
  chatRoomName: string;
}

const keyName = [
  HOTKEYS.COMMAND_B,
  HOTKEYS.CTRL_B,
  HOTKEYS.COMMAND_I,
  HOTKEYS.CTRL_I,
  HOTKEYS.COMMAND_SHIFT_X,
  HOTKEYS.CTRL_SHIFT_X,
  HOTKEYS.COMMAND_SHIFT_7,
  HOTKEYS.CTRL_SHIFT_7,
  HOTKEYS.COMMAND_SHIFT_8,
  HOTKEYS.CTRL_SHIFT_8,
  HOTKEYS.COMMAND_SHIFT_9,
  HOTKEYS.CTRL_SHIFT_9,
  HOTKEYS.SHIFT_ENTER,
].toString();

const ChatInput: FC<ChatInputProps> = ({
  textAreaRef,
  toggleEmoji,
  sendNewMessage,
  chatRoomId,
  setPicker,
  setNewEmoji,
  newEmoji,
  chatRoomName,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [enumarate, setEnumarate] = useState(1);
  const [selectedText, setSelectedText] = useState<string | undefined>();
  const [isFocused, setFocusState] = useState(false);

  const getSelection = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      setSelectedText(textArea.value.substring(textArea.selectionStart, textArea.selectionEnd));
    }
  };

  useHotkeys(
    keyName,
    (e, handler) => {
      if (!isFocused) {
        return;
      }
      handleHotkeys(
        handler.key,
        e,
        setNewMessage,
        setEnumarate,
        newMessage,
        enumarate,
        textAreaRef,
        setSelectedText,
        selectedText
      );
    },
    undefined,
    [newMessage, selectedText, enumarate, isFocused]
  );

  const { t } = useTranslation('pages');
  const dispatch = useDispatch();

  useEffect(() => {
    if (newEmoji) {
      if ('native' in newEmoji) {
        setNewMessage(newMessage => newMessage.concat(newEmoji.native));
      }
      setNewEmoji(null);
      toggleEmoji();
      textAreaRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEmoji]);

  const onAddNewFiles = (props: RcCustomRequestOptions) => {
    dispatch(
      filesActions.uploadFileToStorage({
        file: (props.file as unknown) as RcFile | AvatarFile,
        id: chatRoomId,
        collection: 'rooms',
        chatRoomName,
      })
    );
  };

  const handleSubmit = (event: React.KeyboardEvent<HTMLTextAreaElement>, sendClick?: boolean) => {
    getSelection();
    if (event.keyCode === QUESTION_MARK_KEY_CODE) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }

    const pressSendButton = event.keyCode === 13 && !event.shiftKey;

    if (!newMessage.trim()) {
      if (pressSendButton) {
        event.preventDefault();
      }

      return;
    }

    const regexOl = /^\d+[.]$/;
    const regexUl = /^[*]$/;

    let message = emojify(newMessage, { output: 'unicode' }).trim();

    const messageLastElement = message.split('\n').slice(-1)[0];
    const endOfMessageIsEmptyListElement =
      !!messageLastElement.match(regexOl) || !!messageLastElement.match(regexUl);

    if (pressSendButton || sendClick) {
      if (endOfMessageIsEmptyListElement) {
        event.preventDefault();
        setNewMessage(
          (message = message
            .split('\n')
            .slice(0, -1)
            .join('\n'))
        );
      } else {
        event.preventDefault();
        sendNewMessage(message);
        setPicker({ isVisible: false });
        setNewMessage('');
        setEnumarate(1);
      }
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const listIteratorRegex = /\d. /g;
    const inputValue = e.target.value;
    const listElements = inputValue.match(listIteratorRegex);
    if (!inputValue.length) {
      setEnumarate(1);
    }

    if (listElements && listElements.length) {
      setEnumarate(listElements.length + 1);
    }

    if (inputValue[inputValue.length - 1] === ' ') {
      setNewMessage(emojify(inputValue, { output: 'unicode' }));
    } else {
      setNewMessage(inputValue);
    }
  };

  return (
    <div className={styles.chatInputContainer} data-testid="chat-input">
      <div
        className={styles.uploadInputContainer}
        onClick={() => setPicker({ isVisible: false })}
        data-testid="upload-icon"
      >
        <Upload
          className={styles.uploadInput}
          component="div"
          customRequest={onAddNewFiles}
          multiple
          data-testid="upload-input"
        >
          <AttachmentIcon width={18} height={18} />
        </Upload>
      </div>
      <TextareaAutosize
        id={chatRoomId}
        placeholder={t('home.writeMessage')}
        autoFocus
        inputRef={tag => (textAreaRef.current = tag)}
        value={newMessage}
        className={styles.textarea}
        onChange={onInputChange}
        onKeyDown={handleSubmit}
        onFocus={() => setFocusState(true)}
        onBlur={() => setFocusState(false)}
        minRows={1}
        maxRows={15}
        onMouseUp={getSelection}
        data-testid="textarea"
      />
      <SmileIcon
        width={18}
        height={18}
        className={styles.iconEmoji}
        onClick={toggleEmoji}
        data-testid="smileIcon"
      />
      <SendIcon
        width={18}
        height={18}
        className={newMessage.length > 0 ? styles.iconSendActive : styles.iconSend}
        onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          handleSubmit((e as unknown) as React.KeyboardEvent<HTMLTextAreaElement>, true)
        }
        data-testid="sendIcon"
      />
    </div>
  );
};

export default memo(ChatInput);
