"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToPresence = void 0;
const setUserStatusManually_1 = require("../../../userPresence/setUserStatusManually");
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const enums_1 = require("../../../enums");
const fetchVendorsWithPendingInvitation_1 = require("./fetchVendorsWithPendingInvitation");
const subscribeToPresence = (workspaceId, vendors) => (dispatch, getState, { firestoreSubscriptions, firestore, auth, database }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user } = getState();
    dispatch((0, reducer_1.subscribeToPresenceStarted)());
    const switchSelfBackToOnline = (usersPresenceInWorkspace) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!usersPresenceInWorkspace || !((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.email) || !(user === null || user === void 0 ? void 0 : user.data.userId)) {
            return;
        }
        const { userId } = user.data;
        if (((_b = usersPresenceInWorkspace[userId]) === null || _b === void 0 ? void 0 : _b.status) === enums_1.UserPresenceStatus.Offline) {
            yield (0, setUserStatusManually_1.setUserStatusManually)({
                database,
                userId,
                state: enums_1.UserPresenceStatus.Online,
            });
        }
    });
    try {
        const user = auth().currentUser;
        if (!(user === null || user === void 0 ? void 0 : user.email)) {
            throw new Error('user-is-not-logged');
        }
        if (firestoreSubscriptions.presenceListener) {
            firestoreSubscriptions.presenceListener();
        }
        firestoreSubscriptions.presenceListener = yield firestore()
            .collection(firebaseCollectionNames_1.FIRESTORE_PRESENCE)
            .doc(workspaceId)
            .onSnapshot((snapshot) => __awaiter(void 0, void 0, void 0, function* () {
            const usersPresenceInWorkspace = snapshot.data();
            const { appState } = getState().workspaces;
            if (appState !== 'inactive' && appState !== 'background') {
                yield switchSelfBackToOnline(usersPresenceInWorkspace);
            }
            dispatch((0, reducer_1.subscribeToPresenceSuccess)(usersPresenceInWorkspace));
            if (vendors && vendors.length) {
                dispatch((0, fetchVendorsWithPendingInvitation_1.fetchVendorsWithPendingInvitation)(vendors));
            }
        }), () => {
            dispatch((0, reducer_1.subscribeToPresenceFailed)());
        });
    }
    catch (e) {
        dispatch((0, reducer_1.subscribeToPresenceFailed)());
    }
});
exports.subscribeToPresence = subscribeToPresence;
