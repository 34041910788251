import React from 'react';
import { AppStore, ChatApp, projectsActions, selectors } from '@xtrf/shared';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Accordion, Popup, Transition } from 'semantic-ui-react';

import { UnreadMessagesIndicator } from 'components/rooms/unread-messages-indicator/unread-messages-indicator.component';

import Chatroom from '../chatroom/chatroom';
import styles from './project-group.module.scss';

type Props = {
  chatroomKey: string;
  app: ChatApp;
  projectIdentifier?: string;
  projectName?: string;
};

export const ProjectGroup = ({ chatroomKey, app, projectIdentifier, projectName }: Props) => {
  const dispatch = useDispatch();
  const chats = useSelector(selectors.getJobOfferChats);
  const collapsedJobOffers = useSelector((store: AppStore) => store.projects.collapsedJobOffers);

  const perChatRoom = useSelector(
    (store: AppStore) => store.workspaces.unreadMessages?.perChatRoom,
    shallowEqual
  );

  const countUnreadMessagesPerProject = (key: string) => {
    if (!perChatRoom) return;

    const unreadPerChatRoom = chats[key].map(chatRoom => {
      return perChatRoom[chatRoom.id] ? perChatRoom[chatRoom.id].length : 0;
    });

    return unreadPerChatRoom.reduce((acc, unreadCount) => acc + unreadCount, 0);
  };

  const active = !collapsedJobOffers.includes(chatroomKey);
  const unreadMessagesPerProject = countUnreadMessagesPerProject(chatroomKey);

  return (
    <div className={styles.projectItem} key={chatroomKey}>
      <Accordion>
        <Accordion.Title
          className={styles.accordionTitle}
          active={active}
          onClick={() =>
            dispatch(projectsActions.setCollapsedJobOffers({ id: chatroomKey, active }))
          }
        >
          <div className={styles.projectHeader}>
            <Popup
              content={projectIdentifier}
              trigger={
                <div className={styles.id} data-testid="project-displayid">
                  {projectIdentifier}
                </div>
              }
              mouseEnterDelay={800}
              on="hover"
              className={'xtrf-chat-popup'}
              position="bottom center"
              data-testid="project-displayid-popup"
            />
            {projectName && (
              <Popup
                content={projectName}
                trigger={
                  <div className={styles.name} data-testid="project-name">
                    {projectName}
                  </div>
                }
                mouseEnterDelay={800}
                on="hover"
                className={'xtrf-chat-popup'}
                position="bottom center"
                data-testid="project-name-popup"
              />
            )}

            {!active && <UnreadMessagesIndicator count={unreadMessagesPerProject} />}
          </div>
          <hr className={styles.separator} />
        </Accordion.Title>
        <Transition visible={active} animation="slide down" duration={300}>
          <Accordion.Content active={active} className={styles.accordionContent}>
            {chats[chatroomKey].map(chat => {
              return <Chatroom key={chat.id} app={app} chatRoom={chat} />;
            })}
          </Accordion.Content>
        </Transition>
      </Accordion>
    </div>
  );
};
