import React, { FC, SVGProps } from 'react';
import styles from './chat-icon.module.scss';

const ChatIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg width={50} height={50} viewBox="0 0 50 50" {...props}>
      <g>
        <rect y="0" className={styles.st0} width="50" height="50" />
        <g>
          <path
            className={styles.st1}
            d="M25,6.2c-3.4,0-6.8,0.9-9.7,2.7C6.4,14.2,3.5,25.8,8.8,34.7C9,35,9,35.3,9,35.6L6.6,43
			c-0.1,0.3,0.2,0.5,0.4,0.4l7.4-2.4c0.3-0.1,0.6-0.1,0.9,0.1c2.9,1.8,6.3,2.7,9.8,2.7c10.4,0,18.8-8.4,18.8-18.8
			C43.8,14.6,35.4,6.2,25,6.2z M25,41.7c-3.1,0-6-0.8-8.7-2.4c-0.2-0.2-1-0.6-1.6-0.5c-1.2,0.2-3.4,1-4.6,1.4
			c-0.3,0.1-0.5-0.2-0.4-0.4c0.4-1,1.2-2.9,1.4-4c0.1-0.8-0.3-1.9-0.5-2.1c-4.7-7.9-2.2-18.2,5.7-22.9C19,9.1,22,8.3,25,8.3
			c9.2,0,16.7,7.5,16.7,16.7C41.7,34.2,34.2,41.7,25,41.7z"
          />
          <g>
            <path
              className={styles.st1}
              d="M18.2,31.9c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c3,0,4.2-1.7,5.9-4.4c0.8-1.3,1.6-2.6,2.8-3.6
				c1.4-1.3,3-1.9,5-1.9c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-2.3,0-3.3,1.4-4.9,4c-0.9,1.4-1.8,2.8-3.1,3.9
				C22.3,31.2,20.4,31.9,18.2,31.9z"
            />
            <path
              className={styles.st1}
              d="M15.5,30.1c0-0.8,0.5-1.5,1.2-1.8c-1.1-0.5-1.9-1.5-1.9-2.8c0-1.2,0.6-2.6,2-3.2c-0.8-0.3-1.3-1-1.3-1.9
				c0-0.5,0.2-1,0.6-1.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.9,0.3-1.6,0.9-2.2,1.5c-1.2,1.2-1.9,2.9-1.9,4.8c0,1.8,0.8,3.5,2.1,4.7
				c0.8,0.7,1.7,1.2,2.6,1.5C15.8,31.3,15.5,30.7,15.5,30.1z"
            />
            <path
              className={styles.st1}
              d="M34.5,30.1c0-0.8-0.5-1.5-1.2-1.8c1.1-0.5,1.9-1.5,1.9-2.8c0-1.2-0.6-2.6-2-3.2c0.8-0.3,1.3-1,1.3-1.9
				c0-0.5-0.2-1-0.6-1.4c0.2,0.1,0.3,0.1,0.5,0.2c0.9,0.3,1.6,0.9,2.2,1.5c1.2,1.2,1.9,2.9,1.9,4.8c0,1.8-0.8,3.5-2.1,4.7
				c-0.8,0.7-1.7,1.2-2.6,1.5C34.2,31.3,34.5,30.7,34.5,30.1z"
            />
            <path
              className={styles.st1}
              d="M18.2,21.9c2.1,0,3.2,1.2,4.5,3.3c0.3-0.5,0.7-1.1,1.1-1.7c0.3-0.5,0.6-0.9,0.9-1.4
				c-0.4-0.6-0.9-1.2-1.5-1.7c-1.4-1.3-3.1-1.9-5-1.9c-0.9,0-1.7,0.8-1.7,1.7C16.5,21.2,17.2,21.9,18.2,21.9z"
            />
            <path
              className={styles.st1}
              d="M32,28.6c-2.1,0-3.4-0.9-4.5-2.3c-0.6,1-1.2,2-2,2.9c0.2,0.3,0.5,0.5,0.8,0.7c1.6,1.4,3.5,2.1,5.7,2.1
				c0.9,0,1.7-0.8,1.7-1.7C33.6,29.4,32.9,28.6,32,28.6z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ChatIcon;
