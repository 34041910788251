import React from 'react';
import PageHeader from 'components/shared/page-header/page-header.component';
import RegisterForm from 'components/auth/register-form/register-form.component';
import { Grid } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { HistoryState } from 'models/history-state.model';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import Segment from '../components/shared/segment/segment-component';

const RegisterPage: React.FC<RouteComponentProps<{}, {}, HistoryState>> = props => {
  const joinWorkspaceToken = props.location.state?.token;
  const email = props.location.state?.email;

  if (!email || !joinWorkspaceToken) {
    return null;
  }

  return (
    <PagesContainer>
      <Grid textAlign="center" style={{ height: `100vh` }} verticalAlign="middle">
        <Grid.Column style={{ width: 450 }}>
          <Segment>
            <PageHeader />
            <RegisterForm joinWorkspaceToken={joinWorkspaceToken} email={email} />
          </Segment>
        </Grid.Column>
      </Grid>
    </PagesContainer>
  );
};

export default RegisterPage;
