import React from 'react';
import styles from './no-account-info.module.scss';
import { useTranslation } from 'react-i18next';

const NoAccountInfo: React.FC = () => {
  const { t } = useTranslation('pages');

  return (
    <div className={styles.registerInfo} data-testid="no-account-info">
      <div className={styles.noAccount}>{t('login.noAccount')}</div>
      <div className={styles.learnMore}>{t('login.registerInfo')}</div>
    </div>
  );
};

export default NoAccountInfo;
