"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockApplicationState = void 0;
exports.mockApplicationState = {
    user: {
        error: null,
        status: null,
        data: null,
        detailsStatus: null,
        passwordStatus: null,
        deleteAccountStatus: null,
        appVersion: null,
        usernameStatus: null,
    },
};
