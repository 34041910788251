import { filesActions } from '@xtrf/shared';
import Upload, { RcCustomRequestOptions } from 'rc-upload';
import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './drag-and-drop.module.scss';
import { DragAndDropIcon } from '../drag-and-drop-icon/drag-and-drop-icon.component';
import { useTranslation } from 'react-i18next';
import { isGlobalChat } from '../../../../shared';
import { AvatarFile, RcFile } from '@xtrf/shared/lib/store/reducers/files/types';

type DragAndDropProps = {
  chatRoomId: string;
  chatRoomName: string;
  disabled: boolean;
};
const DragAndDrop: FunctionComponent<DragAndDropProps> = ({
  children,
  chatRoomId,
  chatRoomName,
  disabled,
}) => {
  const [drag, setDrag] = useState(false);
  const [dragWindow, setDragWindow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('pages');

  const dropRef = useRef<HTMLDivElement>(null);
  const dragCounter = useRef(0);
  const dragWindowCounter = useRef(0);

  const handleDragIn = useCallback(() => {
    dragCounter.current++;
    setDrag(true);
  }, [dragCounter]);

  const handleDragInWindow = useCallback(() => {
    dragWindowCounter.current++;
    setDragWindow(true);
  }, [dragWindowCounter]);

  const handleDragOut = useCallback(() => {
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDrag(false);
    }
  }, [dragCounter]);

  const handleDragOutWindow = useCallback(() => {
    dragWindowCounter.current--;
    if (dragWindowCounter.current === 0) {
      setDragWindow(false);
    }
  }, [dragWindowCounter]);

  const handleDrag = useCallback((e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      dragWindowCounter.current = 0;
      dragCounter.current = 0;
      setDrag(false);
      setDragWindow(false);
    },
    [dragWindowCounter]
  );

  const onAddNewFiles = (props: RcCustomRequestOptions) => {
    dragCounter.current = 0;
    dragWindowCounter.current = 0;
    setDrag(false);
    setDragWindow(false);
    dispatch(
      filesActions.uploadFileToStorage({
        file: (props.file as unknown) as RcFile | AvatarFile,
        id: chatRoomId,
        chatRoomName,
        collection: 'rooms',
      })
    );
  };

  useEffect(() => {
    if (!disabled) {
      const div = dropRef.current;
      if (!isGlobalChat()) {
        window.addEventListener('dragenter', handleDragInWindow);
        window.addEventListener('dragleave', handleDragOutWindow);
        window.addEventListener('dragover', handleDrag);
        window.addEventListener('drop', handleDrop);
      }
      if (div) {
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
      }

      return () => {
        if (!isGlobalChat()) {
          window.removeEventListener('dragenter', handleDragInWindow);
          window.removeEventListener('dragleave', handleDragOutWindow);
          window.removeEventListener('dragover', handleDrag);
          window.removeEventListener('drop', handleDrop);
        }
        if (div) {
          div.removeEventListener('dragenter', handleDragIn);
          div.removeEventListener('dragleave', handleDragOut);
        }
      };
    }
  }, [
    handleDragIn,
    handleDragInWindow,
    handleDragOut,
    handleDragOutWindow,
    handleDrag,
    handleDrop,
    disabled,
  ]);

  let overlayStyle;
  let preliminary = null;
  if (drag) {
    overlayStyle = styles.dropOverlay;
    preliminary = false;
  } else if (dragWindow && !drag) {
    overlayStyle = styles.preliminaryDropOverlay;
    preliminary = true;
  } else {
    overlayStyle = styles.dropHidden;
    preliminary = null;
  }

  return (
    <div className={styles.container} ref={dropRef}>
      <Upload
        customRequest={onAddNewFiles}
        component="div"
        openFileDialogOnClick={false}
        style={{ outline: 'none' }}
      >
        <div className={overlayStyle}>
          <DragAndDropIcon preliminary={preliminary} />
          <div className={styles.text}>
            {preliminary ? t('dragAndDrop.dropHere') : t('dragAndDrop.uploadHere')}
          </div>
        </div>
        {children}
      </Upload>
    </div>
  );
};

export default DragAndDrop;
