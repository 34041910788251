"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchVendorsWithPendingInvitation = void 0;
const reducer_1 = require("../reducer");
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const models_1 = require("../../../models");
const helpers_1 = require("../../../../helpers");
const fetchVendorsWithPendingInvitation = (vendors) => (dispatch, getState, { firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!vendors.length) {
        return;
    }
    dispatch((0, reducer_1.fetchVendorsWithPendingInvitationStarted)());
    try {
        const baseRef = firestore()
            .collection(firebaseCollectionNames_1.INVITATIONS_COLLECTION_ID)
            .where('expired', '==', false)
            .where('roleInWorkspace', '==', models_1.UserRole.Vendor);
        const refs = vendors.map(vendor => baseRef.where('email', '==', vendor.email).where('workspaceId', '==', vendor.workspaceId));
        const data = yield Promise.all(refs.map(ref => ref.get()));
        const pendingInvitations = data.map(queryDoc => queryDoc.docs.map(helpers_1.parseToInvitation)).flat();
        dispatch((0, reducer_1.fetchVendorsWithPendingInvitationSuccess)(pendingInvitations));
    }
    catch (e) {
        dispatch((0, reducer_1.fetchVendorsWithPendingInvitationFailed)());
    }
});
exports.fetchVendorsWithPendingInvitation = fetchVendorsWithPendingInvitation;
