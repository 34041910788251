const scrollTop = (ref: React.RefObject<HTMLDivElement | unknown>) => {
  if (!ref?.current) {
    return;
  }

  const currentNode = ref.current as HTMLDivElement;
  currentNode.scrollTop = -currentNode.scrollHeight;
};

export default scrollTop;
