"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToRoomUpdate = void 0;
const firebaseCollectionNames_1 = require("../../../constants/firebaseCollectionNames");
const enums_1 = require("../../../enums");
const reducer_1 = require("../reducer");
const helpers_1 = require("../../../../helpers");
const recipientsCondition_1 = require("./recipientsCondition");
const subscribeToRoomUpdate = (roomId, useRecipients) => (dispatch, getState, { firestoreSubscriptions, firestore }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    if (firestoreSubscriptions.chatRoomsListener &&
        typeof ((_a = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _a === void 0 ? void 0 : _a.updateRef) === 'function') {
        ((_b = firestoreSubscriptions.chatRoomsListener[roomId]) === null || _b === void 0 ? void 0 : _b.updateRef)();
    }
    firestoreSubscriptions.chatRoomsListener[roomId] = Object.assign(Object.assign({}, firestoreSubscriptions.chatRoomsListener[roomId]), { updateRef: undefined });
    const ref = firestore()
        .collection(firebaseCollectionNames_1.CHAT_ROOMS_COLLECTION_ID)
        .doc(roomId)
        .collection(firebaseCollectionNames_1.MESSAGES_COLLECTION_ID);
    const { user: { data }, projects: { selectedWorkspace }, } = getState();
    firestoreSubscriptions.chatRoomsListener[roomId].updateRef = (0, recipientsCondition_1.recipientsCondition)(ref, useRecipients, data === null || data === void 0 ? void 0 : data.userId, selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.userRole, (_d = (_c = selectedWorkspace === null || selectedWorkspace === void 0 ? void 0 : selectedWorkspace.config) === null || _c === void 0 ? void 0 : _c.featureFlags) === null || _d === void 0 ? void 0 : _d.manyRoles)
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .onSnapshot(snapshot => {
        if (snapshot.empty) {
            return;
        }
        const [doc] = snapshot.docs;
        const updatedMessage = doc.data();
        if (updatedMessage.type === enums_1.MessageType.Removed) {
            dispatch((0, reducer_1.deleteMessageFromList)({ chatRoomId: roomId, messageId: doc.id }));
        }
        else {
            dispatch((0, reducer_1.updatedMessageOnList)({ chatRoomId: roomId, message: (0, helpers_1.parseToMessage)(doc) }));
        }
    }, e => {
        // eslint-disable-next-line
        console.log('err', e);
    });
});
exports.subscribeToRoomUpdate = subscribeToRoomUpdate;
