import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppStore, RequestStatus, workspacesActions, WorkspacesReducer } from '@xtrf/shared';
import { PagesContainer } from 'components/shared/pages-container/pages-container';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';

const AuthorizeJobOffers = ({
  workspaces: { workspaceToken, workspaceTokenStatus },
}: {
  workspaces: WorkspacesReducer;
}) => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const workspaceId = query.get('workspaceId');
  const redirect = query.get('redirect');

  if (workspaceTokenStatus === RequestStatus.SUCCESS) {
    sessionStorage.removeItem('redirect');
    sessionStorage.removeItem('workspaceId');

    window.location.replace(`${redirect}?accessToken=${workspaceToken}`);
  }

  if (workspaceTokenStatus === RequestStatus.FAILED) {
    redirect && sessionStorage.setItem('redirect', redirect);
    workspaceId && sessionStorage.setItem('workspaceId', workspaceId);

    history.push('/');
  }

  useEffect(() => {
    if (!workspaceId) {
      history.push('/');

      return;
    }

    dispatch(workspacesActions.getWorkspaceToken(workspaceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workspaceId]);

  return (
    <PagesContainer>
      <LoaderDotted />
    </PagesContainer>
  );
};

const mapStateToProps = (store: AppStore): { workspaces: WorkspacesReducer } => {
  return {
    workspaces: store.workspaces,
  };
};

export default connect(mapStateToProps)(AuthorizeJobOffers);
