const shouldScrollBottom = (currentNode: HTMLDivElement): boolean => {
  const offsetInPixels = 200;

  return (
    currentNode.scrollTop + currentNode.clientHeight + offsetInPixels >= currentNode.scrollHeight
  );
};

const scrollBottom = (ref: React.RefObject<HTMLDivElement | unknown>, scrollAlways = false) => {
  if (!ref?.current) {
    return;
  }

  const currentNode = ref.current as HTMLDivElement;
  if (scrollAlways || shouldScrollBottom(currentNode)) {
    currentNode.scrollTop = currentNode.scrollHeight;
  }
};

export default scrollBottom;
