import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header, Button } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './resend-invitation.module.scss';
import CloseIcon from 'shared/icons/close-icon';
import { AppStore, ResendInvitationData, workspacesActions } from '@xtrf/shared';
import { ResendInvitationStatus } from '@xtrf/shared/lib/store/reducers/workspaces/types';
import { isGlobalChat } from '../../../shared';
import Message from 'components/shared/message/message';

export interface ResendInvitationProps {
  toggleModal: () => void;
  invitationData: ResendInvitationData | null;
  customResendInvitationAction?: () => void;
}

const ResendInvitation: React.FC<ResendInvitationProps> = ({
  toggleModal,
  invitationData,
  customResendInvitationAction,
}) => {
  const { t } = useTranslation(['pages', 'common']);
  const dispatch = useDispatch();
  const resendInvitationStatus = useSelector(
    (store: AppStore) => store.workspaces.resendInvitationStatus
  );
  const workspaceName = useSelector((store: AppStore) => store.projects.selectedWorkspace?.name);

  useEffect(() => {
    if (resendInvitationStatus === ResendInvitationStatus.SENT) {
      setTimeout(() => {
        toggleModal();
        dispatch(workspacesActions.resetResendInvitationStatus());
      }, 2000);
    }
  }, [resendInvitationStatus, dispatch, toggleModal]);

  return (
    <>
      <Message
        hidden={resendInvitationStatus !== ResendInvitationStatus.SENT}
        type="success"
        testId="reset-password-success-message"
        title={t('resendInvitation.success')}
      />

      <Message
        hidden={resendInvitationStatus !== ResendInvitationStatus.FAILED_TO_SEND}
        type="error"
        testId="login-error-message"
        title={t('resendInvitation.error')}
      />

      {isGlobalChat() && (
        <Header className={styles.header}>
          <div className={styles.headerTitle} data-testid="modal-title">
            {workspaceName}
          </div>
          <CloseIcon
            onClick={e => {
              document.body.classList.remove('modal-fade-in');
              toggleModal();
              dispatch(workspacesActions.resetResendInvitationStatus());
            }}
            className={styles.closeIcon}
            data-testid="close-icon"
          />
        </Header>
      )}
      <div>
        <div className={styles.title}>{t('resendInvitation.resendInvitationHeader')}</div>
        <div className={styles.content}>
          {t('resendInvitation.content', {
            id: invitationData?.id,
            name: invitationData?.name,
            email: invitationData?.email,
            invitationDate: invitationData?.invitationDate,
            workspaceName,
          })}
        </div>
        <Button
          onClick={() => {
            if (customResendInvitationAction) {
              customResendInvitationAction();
            } else {
              invitationData?.id && dispatch(workspacesActions.resendInvitation(invitationData.id));
            }
          }}
          data-testid="resend-invitation-button"
          className={styles.button}
          loading={resendInvitationStatus === ResendInvitationStatus.SENDING}
        >
          {t('buttons.resendInvitation')}
        </Button>
        <div className={classNames(styles.or, styles.content)}>{t('resendInvitation.or')}</div>
        <div className={styles.content}>{t('resendInvitation.copyLink')}</div>
        <input
          value={invitationData?.invitationUrl}
          readOnly
          className={styles.input}
          onClick={e => e.stopPropagation()}
          onFocus={e => e.target.select()}
        />
      </div>
    </>
  );
};

export default ResendInvitation;
