import React from 'react';
import styles from './form-label.module.scss';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import { isGlobalChat } from '../../../shared';
type FormLabelProps = {
  label: string;
  dataTestId?: string;
};

const FormLabel: React.FC<FormLabelProps> = ({ label, dataTestId }) => {
  const { t } = useTranslation(['pages', 'common']);
  if (isGlobalChat()) {
    return (
      <Header>
        <div className={styles.globalChatLabel} data-testid={dataTestId}>
          {t(label)}
        </div>
      </Header>
    );
  }

  return (
    <div className={styles.label} data-testid={dataTestId}>
      {t(label)}
    </div>
  );
};

export default FormLabel;
