import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserRole, WorkspaceUser } from '@xtrf/shared';
import { useParams } from 'react-router-dom';
import { Icon, Dropdown, Input } from 'semantic-ui-react';
import classNames from 'classnames';
import { formatDateWithTimeZone } from '@xtrf/shared/lib/helpers';
// UNCOMMENT WHEN START WORKING ON INVITE MEMBERS FEATURES
// import { InviteMemberActionContainer } from 'pages/adminPanel/components/inviteMemberAction/invite-member-action.container';
import { ResendInvitationActionContainer } from 'pages/adminPanel/components/resendInvitationAction/resend-invitation-action.container';
import { PageContainer } from 'pages/adminPanel/components/pageContainer/page-container';
import { RemoveWorkspaceUserActionContainer } from 'pages/adminPanel/components/removeWorkspaceUserAction/remove-workspace-user-action.container';
import { SortColumnHeader } from 'pages/adminPanel/components/sortColumnHeader/sort-column-header';
import { LoaderDotted } from 'components/shared/loader-dotted/loader-dotted';

import {
  ActiveSort,
  JoinedAtSort,
  MembersProps,
  RoleSort,
  UserEmailSort,
  UserNameSort,
} from './members.types';
import styles from './members.module.scss';

export const Members = ({
  workspaceUsers,
  isLoading,
  user,
  sortValue,
  setSortValue,
  searchValue,
  setSearchValue,
}: MembersProps) => {
  const { t } = useTranslation('pages');
  const { workspaceId } = useParams<{ workspaceId: string; role: UserRole }>();

  const renderDropdown = (workspaceUser: WorkspaceUser) => {
    const isDropdownButtonDisabled =
      workspaceUser.email === user?.email && workspaceUser.role === UserRole.Admin;

    return (
      <Dropdown
        direction="left"
        icon={null}
        disabled={isDropdownButtonDisabled}
        trigger={<Icon name="ellipsis vertical" className={classNames(styles.optionsIcon)} />}
      >
        <Dropdown.Menu>
          {!workspaceUser.active && (
            <ResendInvitationActionContainer workspaceUser={workspaceUser} key={1} />
          )}

          <RemoveWorkspaceUserActionContainer
            key={2}
            workspaceId={workspaceId}
            userId={workspaceUser.userId}
            role={workspaceUser.role}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const columns = [
    {
      title: (
        <SortColumnHeader
          title={t('adminPanel.members.tableRows.userName')}
          currentSortValue={sortValue}
          ascValue={UserNameSort.ASC}
          descValue={UserNameSort.DESC}
          onClick={setSortValue}
        />
      ),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: (
        <SortColumnHeader
          title={t('adminPanel.members.tableRows.userEmail')}
          currentSortValue={sortValue}
          ascValue={UserEmailSort.ASC}
          descValue={UserEmailSort.DESC}
          onClick={setSortValue}
        />
      ),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: (
        <SortColumnHeader
          title={t('adminPanel.members.tableRows.role')}
          currentSortValue={sortValue}
          ascValue={RoleSort.ASC}
          descValue={RoleSort.DESC}
          onClick={setSortValue}
        />
      ),
      dataIndex: 'role',
      key: 'role',
      width: 120,
    },
    {
      title: (
        <SortColumnHeader
          title={t('adminPanel.members.tableRows.joinedOn')}
          currentSortValue={sortValue}
          ascValue={JoinedAtSort.ASC}
          descValue={JoinedAtSort.DESC}
          onClick={setSortValue}
        />
      ),
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      width: 120,
      render: (date?: { _seconds: number }) =>
        date?._seconds
          ? formatDateWithTimeZone(new Date(date._seconds * 1000).toISOString(), {
              dateStyle: 'date',
            })
          : '-',
    },
    {
      title: (
        <SortColumnHeader
          title={t('adminPanel.members.tableRows.status')}
          currentSortValue={sortValue}
          ascValue={ActiveSort.ASC}
          descValue={ActiveSort.DESC}
          onClick={setSortValue}
        />
      ),
      dataIndex: 'active',
      key: 'active',
      width: 200,
      render: (active: boolean) => {
        return active
          ? t('adminPanel.members.status.active')
          : t('adminPanel.members.status.invitationSent');
      },
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 40,
      render: (_: undefined, user: WorkspaceUser) => {
        return renderDropdown(user);
      },
    },
  ];

  return (
    <PageContainer
      title={t('adminPanel.members.title')}
      titleExtra={
        <div className={styles.titleExtraWrapper}>
          <Input
            className={styles.searchInput}
            placeholder={t('adminPanel.members.searchInputPlaceholder')}
            onChange={e => setSearchValue(e.target.value)}
            value={searchValue}
          />
          {/* UNCOMMENT WHEN START WORKING ON INVITE MEMBERS FEATURES */}
          {/* <InviteMemberActionContainer /> */}
        </div>
      }
    >
      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          dataSource={workspaceUsers}
          pagination={false}
          rowKey={record => `${record.userId}-${record.role}`}
          loading={{ indicator: <LoaderDotted />, spinning: isLoading }}
        />
      </div>
    </PageContainer>
  );
};
