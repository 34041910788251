import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './feature-button.module.scss';

type FeatureButtonProps = {
  icon: React.ReactNode;
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isActive: boolean;
  testMode: boolean;
};

export const FeatureButton: React.FC<FeatureButtonProps> = ({
  icon,
  text,
  onClick,
  isActive,
  testMode,
}) => {
  return (
    <button
      className={classNames(
        styles.featureButton,
        isActive && styles.active,
        testMode && styles.testMode
      )}
      onClick={onClick}
      data-testid={text}
    >
      {React.cloneElement(icon as ReactElement, {
        className: classNames(styles.icon, isActive && styles.activeIcon),
      })}
      <div>{text}</div>
    </button>
  );
};
